import { default as BaseTextarea } from '@mui/joy/Textarea';
import { forwardRef, useCallback, useMemo } from 'react';
import { clsx } from 'clsx';
import { getInputState } from '../../lib/getInputState';
import { INPUT_VARS_CLASSNAME } from '../Input/styles';
import type { TextareaProps } from './types';
import '../Input/input.vars.css';
import './textarea.css';

const textareaClassnames = 'flex flex-row items-center p-md';

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const { className, slotProps, error, onChange, onChangeValue, ...rest } = props;

  const state = getInputState(props);

  const slotPropsWithRef = useMemo(
    () => ({
      ...slotProps,
      textarea: {
        ...slotProps?.textarea,
        ref,
      },
    }),
    [ref, slotProps],
  );

  const onChangeHandler = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>(
    (e) => {
      if (onChangeValue) onChangeValue(e.target.value);
      if (onChange) onChange(e);
    },
    [onChange, onChangeValue],
  );

  return (
    <BaseTextarea
      {...rest}
      className={clsx(INPUT_VARS_CLASSNAME, textareaClassnames, className)}
      data-size="md" // if sizes are added to textarea, set this prop to the size
      data-state={state}
      data-variant="outlined" // if variants are added to textarea, set this prop to the variant
      error={error}
      onChange={onChangeHandler}
      slotProps={slotPropsWithRef}
      variant="outlined" // this will always be outlined. styles for other variants should ve added via cva
    />
  );
});

Textarea.displayName = 'Textarea';

export default Textarea;
