import React, { useMemo } from 'react';
import type { IconButtonProps, IdSchema } from '@rjsf/utils';
import type { ButtonVariants } from '@unifyapps/ui/components/Button';
import { Button } from '@unifyapps/ui/components/Button';
import SvgPlus from '@unifyapps/icons/outline/Plus';
import { useTranslation } from '@unifyapps/i18n/client';
import { clsx } from 'clsx';
import { getUiOptions } from '../../utils/getUiOptions';
import { getButtonSize } from './helpers/getButtonSize';

function AddButton(props: IconButtonProps & { variant?: ButtonVariants; idSchema: IdSchema }) {
  const {
    registry,
    title,
    uiSchema,
    idSchema: _idSchema,
    variant = 'soft',
    className,
    ...rest
  } = props;
  const { t } = useTranslation();

  const { size } = useMemo(() => getUiOptions({ uiSchema, registry }), [registry, uiSchema]) as {
    size?: 'sm' | 'md' | 'lg';
  };

  const buttonSize = useMemo(() => getButtonSize(size), [size]);

  return (
    <Button
      variant={variant}
      {...rest}
      className={className ? clsx('me-auto w-fit', className) : 'me-auto w-fit'}
      color="brand"
      size={buttonSize}
      startDecoratorComponent={SvgPlus}
    >
      {title ?? t('common:Actions.Add')}
    </Button>
  );
}

export default AddButton;
