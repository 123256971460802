import * as React from 'react';
import type { SVGProps } from 'react';
const SvgItalic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path d="M7.619 2.5v3.214h2.368l-3.665 8.572H3.333V17.5h8.571v-3.214H9.537L13.2 5.714h2.99V2.5z" />
  </svg>
);
export default SvgItalic;
