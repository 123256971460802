import { useCallback, type ChangeEvent, type FocusEvent } from 'react';
import { getUiOptions, type WidgetProps } from '@rjsf/utils';
import type { TextareaProps } from '@unifyapps/ui/components/Textarea';
import Textarea from '@unifyapps/ui/components/Textarea';
import { clsx } from 'clsx';
import { hasFieldError } from '../../utils/error';
import getUaOptions from '../../utils/getUaOptions';

/** The `TextareaWidget` is a widget for rendering input fields as textarea.
 *
 * @param props - The `WidgetProps` for this component
 */
export default function TextareaWidget(props: WidgetProps) {
  const { id, options, onChange, onBlur, onFocus, placeholder, uiSchema } = props;
  const uiOptions = getUiOptions(uiSchema);

  const uaOptions = getUaOptions<{
    minRows?: number;
    maxRows?: number;
  }>(uiSchema);

  let rows: string | number = 5;
  if (typeof options.rows === 'string' || typeof options.rows === 'number') {
    rows = options.rows;
  }

  if (uaOptions.maxRows) {
    rows = uaOptions.maxRows;
  }

  const minRows = uaOptions.minRows ?? 2;

  const _onChange = useCallback(
    ({ target: { value: val } }: ChangeEvent<HTMLTextAreaElement>) =>
      onChange(val === '' ? options.emptyValue : val),
    [options.emptyValue, onChange],
  );

  const _onBlur = useCallback(
    ({ target: { value: val } }: FocusEvent<HTMLTextAreaElement>) => onBlur(id, val),
    [onBlur, id],
  );

  const _onFocus = useCallback(
    ({ target: { value: val } }: FocusEvent<HTMLTextAreaElement>) => onFocus(id, val),
    [id, onFocus],
  );

  return (
    // Sync the Textarea Widget with Input Sizes, both should have same number of sizes
    <Textarea
      className={clsx('text-md md:text-sm', uiOptions.className)}
      disabled={props.disabled}
      error={hasFieldError(props.rawErrors, props.hideError)}
      maxRows={rows}
      minRows={minRows}
      onBlur={_onBlur}
      onChange={_onChange}
      onFocus={_onFocus}
      placeholder={placeholder}
      readOnly={props.readonly}
      // adding empty string to make the controlled component
      value={(props.value ?? '') as TextareaProps['value']}
    />
  );
}
