import { forwardRef } from 'react';
import { slowCn } from '@unifyapps/ui/lib/utils';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import type { WysiwygButtonProps } from './types';

const WysiwygButton = forwardRef<HTMLButtonElement, WysiwygButtonProps>(
  (props, ref): JSX.Element => {
    const { className, Icon, isActive, isEditable, ...rest } = props;

    const iconButtonClassName = isActive
      ? 'text-quaternary bg-brand-primary hover:bg-brand-primary_hover'
      : 'bg-primary text-placeholder hover:bg-primary_hover';

    return (
      <IconButton
        Icon={Icon}
        disabled={!isEditable}
        ref={ref}
        {...rest}
        className={slowCn('size-7', iconButtonClassName, className)}
        iconClassName="size-4"
      />
    );
  },
);

WysiwygButton.displayName = 'WysiwygButton';

export default WysiwygButton;
