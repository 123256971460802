import Bold from '@unifyapps/icons/outline/Bold01';
import Italic from '@unifyapps/icons/outline/Italic01';
import ChevronDown from '@unifyapps/icons/outline/ChevronDown';
import Underline from '@unifyapps/icons/outline/Underline01';
import H1 from '@unifyapps/icons/outline/H1';
import H2 from '@unifyapps/icons/outline/H2';
import H3 from '@unifyapps/icons/outline/H3';
import ListBullet from '@unifyapps/icons/outline/ListBullet';
import ListNumbers from '@unifyapps/icons/outline/ListNumbers';
import Strikethrough from '@unifyapps/icons/outline/Strikethrough01';
import Minus from '@unifyapps/icons/outline/Minus';
import Link from '@unifyapps/icons/outline/Link01';
import Palette from '@unifyapps/icons/outline/Palette';
import Pilcrow from '@unifyapps/icons/outline/Pilcrow01';
import Menu from '@unifyapps/icons/outline/Menu01';
import NoCodeTable from '@unifyapps/icons/outline/no-code/NoCodeTable';
import ArrowRight from '@unifyapps/icons/outline/ArrowRight';
import ArrowLeft from '@unifyapps/icons/outline/ArrowLeft';
import ArrowUp from '@unifyapps/icons/outline/ArrowUp';
import ArrowDown from '@unifyapps/icons/outline/ArrowDown';
import Trash from '@unifyapps/icons/outline/Trash01';

const iconList = {
  Bold,
  Italic,
  ChevronDown,
  Underline,
  Strikethrough,
  Link,
  Palette,
  Menu,
  Pilcrow,
  Heading1: H1,
  Heading2: H2,
  Heading3: H3,
  List: ListBullet,
  ListOrdered: ListNumbers,
  Minus,
  Table: NoCodeTable,
  ArrowRight,
  ArrowLeft,
  ArrowUp,
  ArrowDown,
  Trash,
};

export default iconList;
