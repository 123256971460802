import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import type { SelectOptionType } from '../types';
import { TextDecorator } from '../components/TextDecorator';

export const getEndDecoratorRenderer = (endDecoratorDetails: {
  type: 'text';
  valuePath: string;
  textProps?: {
    property: string;
    valuePathInOption: string;
  }[];
}) => {
  const { type, textProps } = endDecoratorDetails;

  const endDecoratorRenderer = (option: SelectOptionType) => {
    switch (type) {
      case 'text': {
        const typographyProps = _reduce(
          textProps,
          (acc, { property, valuePathInOption }) => {
            acc[property] = _get(option, valuePathInOption) as string;

            return acc;
          },
          {} as Record<string, string>,
        );

        return (
          <TextDecorator
            typographyProps={typographyProps}
            value={_get(option, endDecoratorDetails.valuePath) as string | undefined}
          />
        );
      }
    }
  };
  return endDecoratorRenderer;
};
