import { useMemo } from 'react';
import type {
  AutocompleteSlots,
  SlotProps as AutocompleteSlotProps,
} from '@unifyapps/ui/components/Autocomplete';
import type { WidgetProps } from '@rjsf/utils';
import type { LookupByQueryRequest } from '@unifyapps/network/generated/models/lookupByQueryRequest';
import type { SlotProps } from '@unifyapps/ui/slots/types';
import isEqual from 'react-fast-compare';
import { usePrevious } from '@react-hookz/web';
import type { BaseLookupExtendedProps, LookupWidgetUISchema } from '../types';
import { ListBoxFooter } from '../ListBoxFooter';
import useAccessors from './useAccessors';

export function useSlots(inputSlots: AutocompleteSlots | undefined) {
  const slots: AutocompleteSlots & { listboxfooter: React.ElementType } = useMemo(() => {
    return {
      listboxfooter: ListBoxFooter,
      ...inputSlots,
    };
  }, [inputSlots]);

  return { slots };
}

/**
 * This hook is used to generate slotProps for the `Autocomplete` component
 * We do not want to pass uiSchema directly since it causes the `ListBoxRenderer` to mount and unmount on every render
 */
export function useSlotProps(
  uiSchema: LookupWidgetUISchema<LookupByQueryRequest>,
  footerAction: BaseLookupExtendedProps['footerAction'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- is okay
  inputSlotProps: any,
): { slotProps: WidgetProps['slotProps'] } {
  const { getOptionLabel } = useAccessors(uiSchema);

  const prevUiSchema = usePrevious(uiSchema);
  const shouldUpdate = useMemo(
    // needed here -- @hatim-s
    () => Boolean(prevUiSchema) && !isEqual(prevUiSchema, uiSchema),
    [uiSchema, prevUiSchema],
  );

  const slotProps = useMemo(
    () =>
      ({
        listboxfooter: {
          footerAction,
          getOptionLabel,
          uiSchema,
        },
        ...inputSlotProps,
      }) as AutocompleteSlotProps & {
        listboxfooter: SlotProps<'div', object, object>;
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- do not add uiSchema here
    [footerAction, getOptionLabel, inputSlotProps, shouldUpdate],
  );

  return { slotProps };
}
