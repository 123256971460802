import type { Ref } from 'react';
import { useMemo, forwardRef } from 'react';
import type { IconButtonProps } from '@rjsf/utils';
import Trash from '@unifyapps/icons/outline/Trash01';
import LinkButton from '@unifyapps/ui/_components/LinkButton';
import { getIconFromRegistry } from '@unifyapps/icons/utils/registry';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import getUaOptions from '../../utils/getUaOptions';
import { useFormContext } from '../../context';

const RemoveButton = forwardRef(
  (props: IconButtonProps, ref: Ref<HTMLButtonElement> | undefined) => {
    const { uiSchema } = props;
    const { formContext } = useFormContext();

    const { actionItems } = useMemo(() => getUaOptions(uiSchema), [uiSchema]) as {
      actionItems?: {
        removeButtonIcon?: string;
      };
    };

    const isRow = formContext?.layout === 'row';

    const Icon = getIconFromRegistry(actionItems?.removeButtonIcon) ?? Trash;

    if (isRow) {
      return (
        <IconButton
          Icon={Icon}
          color="neutral"
          disabled={props.disabled}
          onClick={props.onClick}
          ref={ref}
          size="md"
          variant="ghost"
        />
      );
    }

    return (
      <LinkButton
        StartDecorator={Icon}
        color="secondary"
        disabled={props.disabled}
        onClick={props.onClick}
        ref={ref}
        size="sm"
      />
    );
  },
);

RemoveButton.displayName = 'RemoveButton';

export default RemoveButton;
