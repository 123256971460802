import React from 'react';
import type { FieldProps } from '@rjsf/utils';
import { FormContextProvider } from '../../context';
import RJSFSchemaField from './RJSFSchemaField';

function SchemaField(props: FieldProps) {
  // the first schema field is rendered with name as empty string
  // we are doing this because we want to wrap the context to the first schema field that is rendered directly after <Form></Form>
  if (props.name === '') {
    return (
      <FormContextProvider value={props}>
        <RJSFSchemaField {...props} />
      </FormContextProvider>
    );
  }

  return <RJSFSchemaField {...props} />;
}

export default SchemaField;
