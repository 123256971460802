import type { Editor } from '@tiptap/react';
import { useCallback } from 'react';
import { RichTextEditorPlugins } from '../../../editors/RichTextEditor/utils';
import { isTextSelected } from '../../../utils/isTextSelected';

export const useTextMenuStates = (editor: Editor) => {
  const shouldShow = useCallback(() => {
    return isTextSelected({ editor });
  }, [editor]);

  return {
    isBold: editor.isActive(RichTextEditorPlugins.Bold),
    isItalic: editor.isActive(RichTextEditorPlugins.Italic),
    isStrike: editor.isActive(RichTextEditorPlugins.Strike),
    isBulletList: editor.isActive(RichTextEditorPlugins.BulletList),
    isOrderedList: editor.isActive(RichTextEditorPlugins.OrderedList),
    currentFont: editor.getAttributes(RichTextEditorPlugins.TextStyle).fontFamily || undefined,
    currentSize: editor.getAttributes(RichTextEditorPlugins.TextStyle).fontSize || undefined,
    shouldShow,
  };
};
