import { useMemo } from 'react';
import _get from 'lodash/get';
import _toPath from 'lodash/toPath';
import _last from 'lodash/last';
import _compact from 'lodash/compact';
import _startCase from 'lodash/startCase';
import type { ErrorListProps, RJSFSchema, UiSchema } from '@rjsf/utils';
import { createPortal } from 'react-dom';
import { ARRAY_FIELD_ID_SUFFIX, ID_SEPARATOR } from '../../const';
import { ErrorPanel } from './components/ErrorPanel';

const getId = (id: string) => id.replace(ARRAY_FIELD_ID_SUFFIX, '');

export const ROOT_PATH = 'root';

function generatePropertiesPathFromId({
  pathId,
  separator,
  skipLast,
}: {
  pathId: string;
  separator?: string;
  skipLast?: boolean; // this is used to update the path to point to parent without adding `item.properties` or `properties` at the end
}) {
  const path = _compact(pathId.split(separator ?? ID_SEPARATOR));

  return path
    .reduce<string[]>((result, id, currentIndex) => {
      result.push(getId(id));
      if (!(currentIndex === path.length - 1 && skipLast)) {
        result.push(id.endsWith('[0]') ? 'items.properties' : 'properties');
      }
      return result;
    }, [])
    .join(separator ?? ID_SEPARATOR);
}

const NUMBERS_IN_PATH_REGEX = /\.\d+./g;

/**
 *
 * @param schema - The schema object to extract the title from
 * @param uiSchema - The uiSchema object to extract the title from
 * @param property - The json path to the property to extract the title for, for example '.arrayOfObjects.0.propertyName'
 * @returns The title of the property if it exists in the uiSchema, otherwise undefined
 */
function extractTitleFromUiSchema(
  schema: RJSFSchema | undefined,
  uiSchema: UiSchema | undefined,
  property: string | undefined,
): string | undefined {
  if (!property || !uiSchema) {
    return undefined;
  }

  // When the property is the root element, just use an empty array for the path
  const path = property === '.' ? [] : _toPath(property);
  // If the property is at the root (.level1) then toPath creates
  // an empty array element at the first index. Remove it.
  if (path.length > 0 && path[0] === '') {
    path.splice(0, 1);
  }

  // replace all numbers from the path with 'items'
  const pathWithoutNumbers = path.map((it) => (isNaN(Number(it)) ? it : 'items'));

  // append ui:title to the path
  pathWithoutNumbers.push('ui:title');

  const uiSchemaTitle = _get(uiSchema, [...pathWithoutNumbers]) as string | undefined;
  if (uiSchemaTitle) {
    return uiSchemaTitle;
  }

  let propertyAdapted: string = property
    .replace(NUMBERS_IN_PATH_REGEX, `${ARRAY_FIELD_ID_SUFFIX}.`)
    // this is to handle the case of mapped arrays
    .replaceAll('.items.', `${ARRAY_FIELD_ID_SUFFIX}.`);
  if (propertyAdapted.startsWith('.')) {
    propertyAdapted = propertyAdapted.substring(1);
  }
  const propertiesPath = generatePropertiesPathFromId({ pathId: propertyAdapted, skipLast: true });
  const schemaTitle = _get(schema, `properties.${propertiesPath}.title`) as string | undefined;
  return schemaTitle ?? _startCase(_last(path));
}

function ErrorListTemplate(props: ErrorListProps) {
  const { errors } = props;
  const formContext = props.formContext as
    | { errorListContainer: HTMLDivElement | null }
    | undefined;
  const errorListContainer = formContext?.errorListContainer;

  const resolvedErrors = useMemo(() => {
    return errors.map((it) => {
      const titleFromUiSchema = extractTitleFromUiSchema(props.schema, props.uiSchema, it.property);
      return {
        title: titleFromUiSchema,
        description: it.message ?? it.stack,
        onClickTitle: () => {
          const id = `root${it.property}`;
          const widgetEl = document.querySelector(`[data-scroll-id="${id}"]`);

          if (widgetEl) {
            widgetEl.scrollIntoView({ behavior: 'smooth', block: 'start' });

            // Highlight the widgetEl for a short period of time
            const classes = ['group', 'highlight-widget'];
            widgetEl.classList.add(...classes);
            setTimeout(() => {
              widgetEl.classList.remove(...classes);
            }, 1000);
          }
        },
      };
    });
  }, [errors, props.schema, props.uiSchema]);

  if (errorListContainer) {
    // VariableList node is a special case where the error list is rendered in a different location than the form
    // Render the error list in a portal to the errorListContainer, will be removed when the VariableList is refactored
    return createPortal(<ErrorPanel errors={resolvedErrors} />, errorListContainer);
  }

  return <ErrorPanel errors={resolvedErrors} />;
}

export default ErrorListTemplate;
