import { default as BaseToggleButtonGroup } from '@mui/joy/ToggleButtonGroup';
import { ToggleGroupButton, ToggleGroupIconButton } from './components/ToggleGroupButtons';
import type { ToggleButtonGroupProps, ToggleButtonGroupValueType } from './types';
import { getButtonGroupClassName } from './styles';

const SLOT_PROPS = {
  root: {
    className: 'shadow-xs bg-secondary',
  },
};

function ToggleButtonGroup<T extends ToggleButtonGroupValueType>(props: ToggleButtonGroupProps<T>) {
  const { className, disabled, size = 'xs', slotProps, ...rest } = props;

  const buttonGroupClassName = getButtonGroupClassName({
    className,
    size,
  });

  return (
    <BaseToggleButtonGroup
      className={buttonGroupClassName}
      disabled={disabled}
      // @ts-expect-error -- size is of same type as ButtonGroupSizes. We are not overriding internal types currently
      size={size}
      slotProps={SLOT_PROPS}
      {...rest}
    />
  );
}

ToggleButtonGroup.displayName = 'ToggleButtonGroup';

const ToggleButtonGroupNamespace = Object.assign(ToggleButtonGroup, {
  Button: ToggleGroupButton,
  IconButton: ToggleGroupIconButton,
});

export default ToggleButtonGroupNamespace;
