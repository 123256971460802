import React, { useCallback } from 'react';
import type { WidgetProps } from '@rjsf/utils';
import ToggleButtonGroup from '@unifyapps/ui/components/ToggleButtonGroup';
import _map from 'lodash/map';
import type { TypographyVariants } from '@unifyapps/ui/components/Typography';
import { Typography } from '@unifyapps/ui/components/Typography';
import { clsx } from 'clsx';
import { ICONS_REGISTRY } from '@unifyapps/icons/utils/registry';
import { Tooltip } from '@unifyapps/ui/components/Tooltip';
import { isNil } from 'lodash';
import getUaOptions from '../../../utils/getUaOptions';

type ToggleButtonGroupButtonUAOptions = {
  className?: string;
  labelVariant?: TypographyVariants;
  hasIcons?: boolean;
};

type OptionsType = {
  enumOptions?: {
    label: string;
    value: string;
    schema?: {
      icon?: string;
      disabled?: boolean;
      tooltip?: { title?: string };
    };
  }[];
};

type SlotPropsType = {
  slotProps?: { toggleButton: { selectedClassName: string; unSelectedClassName: string } };
};

export type ToggleButtonGroupComponentType = (props: WidgetProps) => React.ReactNode;

export default function ToggleButtonGroupWidget(props: WidgetProps & SlotPropsType) {
  const { onChange, uiSchema, slotProps } = props;

  const value = props.value as string | undefined;
  const options = props.options as OptionsType;

  const uaOptions = getUaOptions<ToggleButtonGroupButtonUAOptions>(uiSchema);
  const hasIcons = uaOptions.hasIcons ?? false;

  const handleChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newValue: string | null) => {
      // remove the truthy check because newValue can be boolean for VisibilityConditionsField
      if (!isNil(newValue)) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  const className = clsx(uaOptions.className, 'flex rounded-md');

  if (hasIcons) {
    return (
      <ToggleButtonGroup className={className} onChange={handleChange}>
        {_map(options.enumOptions, (option) => {
          const Icon = ICONS_REGISTRY[option.schema?.icon ?? '']?.icon;
          if (!Icon) {
            return null;
          }

          return (
            <Tooltip key={option.value} placement="top" title={option.label}>
              <ToggleButtonGroup.IconButton
                Icon={Icon}
                aria-label={props.label}
                className="px-lg flex-1 !rounded-md !border-none"
                key={option.value}
                selected={value === option.value}
                size="xs"
                slotProps={slotProps}
                value={option.value}
              />
            </Tooltip>
          );
        })}
      </ToggleButtonGroup>
    );
  }

  return (
    <ToggleButtonGroup className={className} onChange={handleChange}>
      {_map(options.enumOptions, (option) => (
        <ToggleButtonGroup.Button
          className="px-lg flex-1"
          disabled={option.schema?.disabled}
          key={option.value}
          selected={value === option.value}
          size="xs"
          value={option.value}
        >
          {/* Adding Tooltip on Typography since it will not work button as it is disabled */}
          <Tooltip placement="top" title={option.schema?.tooltip?.title}>
            <Typography
              className="pointer-events-auto"
              variant={uaOptions.labelVariant ?? 'text-sm'}
              weight="medium"
            >
              {option.label}
            </Typography>
          </Tooltip>
        </ToggleButtonGroup.Button>
      ))}
    </ToggleButtonGroup>
  );
}
