import * as React from 'react';
import type { SVGProps } from 'react';
const SvgListNumbers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 6.67H11m10 9H11m-7.39-1.341c.08-.157.446-.73 1.363-.73 1.145 0 1.882 1.303.954 2.344-.742.834-1.982 1.91-2.427 2.344v.313h3M4 4.505 5 4v5"
    />
  </svg>
);
export default SvgListNumbers;
