import React from 'react';
import type { FieldHelpProps } from '@rjsf/utils';
import { helpId } from '@rjsf/utils';
import _noop from 'lodash/noop';
import FormHelperText from '@unifyapps/ui/_components/Form/FormHelperText';
import { Button } from '@unifyapps/ui/components/Button';
import type { TypographyVariants } from '@unifyapps/ui/components/Typography';
import { Typography } from '@unifyapps/ui/components/Typography';
import { useTranslation } from '@unifyapps/i18n/client';
import BlockEditor from '@unifyapps/block-editor/components/BlockEditor';

function FieldHelpTemplate(props: FieldHelpProps) {
  const { t } = useTranslation();
  const { schema, idSchema, uiSchema } = props;
  const id = helpId(idSchema);

  const help = props.help ?? uiSchema?.['ui:help'] ?? (schema.help as string);
  const helpType = uiSchema?.['ui:help_type'] as string | undefined;

  const helpLink = uiSchema?.['ui:help_link'] as string | undefined;

  const helpVariant = (uiSchema?.['ui:helpVariant'] as TypographyVariants | undefined) ?? 'text-xs';

  if (!(help || helpLink)) {
    return null;
  }

  if (typeof help === 'string' && helpType === 'HTML') {
    return (
      <FormHelperText className="gap-x-xs m-0 flex flex-wrap items-center p-0" id={id}>
        <Typography color="text-tertiary" variant="text-xs">
          <BlockEditor
            className="border-none"
            editable={false}
            editorContentClassName="outline-0"
            key={help}
            onChange={_noop}
            value={help}
          />
        </Typography>
      </FormHelperText>
    );
  }

  //as we are routing to some external link, we need to add rel="noopener noreferrer" and using <a>
  return (
    <FormHelperText className="gap-x-xs m-0 flex flex-wrap items-center p-0" id={id}>
      {help ? (
        <Typography color="text-tertiary" variant={helpVariant}>
          {help}
        </Typography>
      ) : null}
      {helpLink ? (
        <a href={helpLink} rel="noopener noreferrer" target="_blank">
          <Button color="brand" size="sm" variant="ghost">
            <Typography color="text-tertiary" variant="text-xs">
              {t('common:learnMore')}
            </Typography>
          </Button>
        </a>
      ) : null}
    </FormHelperText>
  );
}

export default FieldHelpTemplate;
