import './styles.css';
import React, { forwardRef, memo, useImperativeHandle, useMemo, useRef } from 'react';
import type { Extensions } from '@tiptap/react';
import { EditorContent, useEditor } from '@tiptap/react';
import { slowCn } from '@unifyapps/ui/lib/utils';
import { isNil, omitBy } from 'lodash';
import { TextStyle } from '@tiptap/extension-text-style';
import { FontFamily } from '@tiptap/extension-font-family';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Bold } from '@tiptap/extension-bold';
import { BulletList } from '@tiptap/extension-bullet-list';
import { Document } from '@tiptap/extension-document';
import { HardBreak } from '@tiptap/extension-hard-break';
import { History } from '@tiptap/extension-history';
import { Italic } from '@tiptap/extension-italic';
import { ListItem } from '@tiptap/extension-list-item';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Strike } from '@tiptap/extension-strike';
import { Text } from '@tiptap/extension-text';
import { Table } from '@tiptap/extension-table';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import { Box } from '@unifyapps/ui/components/Box';
import { clsx } from 'clsx';
import { SelectionMenu } from '../../components/SelectionMenu';
import { EditorToolbar } from '../../components/EditorToolbar';
import { LinkMenu } from '../../components/LinkMenu';
import { FontSize } from '../../extensions/font-size';
import { Link } from '../../extensions/link';
import type { EditorRef } from '../../types';
import { inputSizeVariants } from './styles';

export type RichEditorProps = {
  id?: string;
  rootClassName?: string;
  className?: string;
  initialContent?: string;
  placeholder?: string;
  editable?: boolean;
  onChangeHTML?: (value: string) => void;
  onBlur?: (event: FocusEvent) => void;
  onFocus?: (event: FocusEvent) => void;
  extensions: Extensions;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  font?: {
    families: { label: string; value: string }[];
    sizes: { label: string; value: string }[];
    defaultFamily: string;
    defaultSize: string;
  };
  hideToolbar?: boolean;
  size?: 'sm' | 'md';
  inputClassName?: string;
  autofocus?: boolean;
};

const RichTextEditor = forwardRef<EditorRef, RichEditorProps>((props, ref) => {
  const {
    id,
    rootClassName,
    initialContent,
    className,
    extensions,
    editable,
    font,
    onChangeHTML,
    hideToolbar,
    size = 'md',
    inputClassName: _inputClassName,
    placeholder = 'Click here to start writing …',
    autofocus = true,
  } = props;

  const parentNodeRef = useRef<HTMLDivElement>(null);

  const editor = useEditor(
    {
      immediatelyRender: false,
      autofocus,
      content: initialContent,
      editorProps: {
        attributes: omitBy(
          {
            id,
            class: slowCn('w-full', className),
            readOnly: !editable,
          },
          isNil,
        ) as Record<string, string>,
      },
      onUpdate: ({ editor: currentEditor }) => {
        onChangeHTML?.(currentEditor.getHTML());
      },
      onBlur: ({ event }) => {
        props.onBlur?.(event);
      },
      onFocus: ({ event }) => {
        props.onFocus?.(event);
      },
      extensions: [
        Document,
        Text,
        Bold,
        Italic,
        History,
        Strike,
        Paragraph,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
        FontFamily,
        FontSize,
        TextStyle,
        Link.configure({
          openOnClick: 'whenNotEditable',
          HTMLAttributes: {
            class: 'ua-link',
          },
        }),
        Placeholder.configure({
          placeholder,
        }),
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        ...extensions,
      ],
      editable,
    },
    [extensions, props.onBlur, props.onFocus, className],
  );

  useImperativeHandle(ref, () => ({
    focus: () => {
      editor?.commands.focus();
    },
    getContent: () => {
      return editor?.getHTML() ?? '';
    },
    setContent: (content: string, emitUpdate?: boolean) => {
      editor?.commands.setContent(content, emitUpdate);
    },
    insertHtml: (html) => {
      editor?.commands.insertContent(html, { updateSelection: true });
    },
    clearContent: () => {
      editor?.commands.clearContent();
    },
    isEmpty: () => {
      return editor?.isEmpty || false;
    },
  }));

  const inputClassName = useMemo(
    () =>
      inputSizeVariants({
        size,
        className: _inputClassName,
      }),
    [size, _inputClassName],
  );

  return editor ? (
    <Box
      className={clsx(
        'border-primary bg-primary flex w-full flex-col rounded-sm border',
        rootClassName,
      )}
      ref={parentNodeRef}
    >
      {hideToolbar ? null : (
        <EditorToolbar
          className="border-primary rounded-t-xl border-b"
          editor={editor}
          font={font}
        />
      )}
      <EditorContent
        className={inputClassName}
        editor={editor}
        onKeyDown={props.onKeyDown}
        style={font?.defaultFamily ? { fontFamily: font.defaultFamily } : undefined}
      />
      <LinkMenu editable={editable} editor={editor} />
      <SelectionMenu editable={editable} editor={editor} />
    </Box>
  ) : null;
});

RichTextEditor.displayName = 'RichTextEditor';

export default memo(RichTextEditor);
