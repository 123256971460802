import { cva } from 'class-variance-authority';

// keeping empty strings here, so styles can be easily added without modifying any other file
export const toggleRootVariantsReset = cva('group/toggle', {
  variants: {
    checked: {
      false: '',
      true: '',
    },
    disabled: {
      false: '',
      true: '',
    },
    focusVisible: {
      false: '',
      true: '',
    },
  },
});

export const toggleTrackVariants = cva('rounded-full relative', {
  variants: {
    checked: {
      false: '',
      true: '',
    },
    disabled: {
      false: '',
      true: '',
    },
    focusVisible: {
      false: '',
      true: '',
    },
    color: {
      brand: '',
      success: '',
      error: '',
    },
  },
  compoundVariants: [
    // background colors
    {
      // o-
      disabled: false,
      checked: false,
      className: 'bg-quaternary group-hover/toggle:bg-quaternary_hover',
    },
    {
      // -o
      disabled: false,
      checked: true,
      color: 'brand',
      className: 'bg-brand-solid group-hover/toggle:bg-brand-solid_hover',
    },
    {
      // -o
      disabled: false,
      checked: true,
      color: 'success',
      className: 'bg-success-solid group-hover/toggle:fixme',
    },
    {
      // -o
      disabled: false,
      checked: true,
      color: 'error',
      className: 'bg-error-solid group-hover/toggle:fixme',
    },
    // disabled styles
    {
      disabled: true,
      className: 'bg-disabled',
    },
    // focus rings
    {
      focusVisible: true,
      checked: false,
      className: 'ring-gray',
    },
    {
      focusVisible: true,
      checked: true,
      color: 'brand',
      className: 'ring-brand',
    },
    {
      focusVisible: true,
      checked: true,
      color: 'success',
      className: 'ring-success',
    },
    {
      focusVisible: true,
      checked: true,
      color: 'error',
      className: 'ring-error',
    },
  ],
});

// keeping empty strings here, so styles can be easily added without modifying any other file
export const toggleThumbVariants = cva('rounded-full shadow-sm', {
  variants: {
    checked: {
      false: '',
      true: '',
    },
    disabled: {
      false: 'bg-fg-white',
      true: 'bg-secondary_subtle',
    },
    focusVisible: {
      false: '',
      true: '',
    },
  },
});

// keeping empty strings here, so styles can be easily added without modifying any other file
export const toggleActionVariantsReset = cva('rounded-full', {
  variants: {
    checked: {
      false: '',
      true: '',
    },
    disabled: {
      false: '',
      true: '',
    },
    focusVisible: {
      false: '',
      true: '',
    },
  },
});
