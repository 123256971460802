import type { ComponentType } from 'react';
import { forwardRef } from 'react';
import './styles.css';
import type { FormProps, ThemeProps } from '@rjsf/core';
import { withTheme } from '@rjsf/core';
import useForkRef from '@unifyapps/ui/hooks/useForkRef';
import { Typography } from '@unifyapps/ui/components/Typography';
import { useFormAutoFocus } from '@unifyapps/form/hooks/useFormAutoFocus';
import validator from '@unifyapps/form/validator';
import templates from '@unifyapps/form/templates';
import baseWidgets from '@unifyapps/form/widgets';
import fields from '@unifyapps/form/fields';
import type { FormType } from '@unifyapps/form/types';
import SelectWidget from '../widgets/SelectWidget';
import { LookupWidget } from '../widgets/LookupWidget';
import { ErrorBoundary } from '../components/ErrorBoundary';
import DateTimeWidget from '../widgets/DateTimeWidget';
import CopyBoxWidget from '../widgets/CopyBoxWidget';
import { useTransformErrors } from './hooks/useTransformErrors';

const widgets = {
  ...baseWidgets,
  SelectWidget,
  DateTimeWidget,
  DateWidget: DateTimeWidget,
  LookupWidget,
  CopyBoxWidget,
};

export type DefaultWidgets = typeof widgets;

const theme: ThemeProps = {
  templates,
  widgets,
  fields,
};

const ThemedForm = withTheme(theme);

export type Props = Omit<
  FormProps,
  'validator' | 'idSeparator' | 'noHtml5Validate' | 'templates'
> & {
  autofocus?: boolean;
  templates?: FormProps['templates'] | Record<string, ComponentType<unknown>>;
};

const fallbackError = (
  <Typography color="text-secondary" variant="text-sm">
    Something went wrong
  </Typography>
);

const Form = forwardRef<FormType, Props>(function Form(props, forwardedRef) {
  const { autofocus = true } = props;
  const formRef = useFormAutoFocus({ autofocus });
  const ref = useForkRef(forwardedRef, formRef);

  const { _transformErrors } = useTransformErrors({
    schema: props.schema,
    transformErrors: props.transformErrors,
    uiSchema: props.uiSchema,
  });

  return (
    <ErrorBoundary fallback={fallbackError}>
      <ThemedForm
        showErrorList={false}
        {...props}
        idSeparator="."
        noHtml5Validate
        ref={ref}
        transformErrors={_transformErrors}
        validator={validator}
      />
    </ErrorBoundary>
  );
});

export { Form };
