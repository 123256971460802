import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';
import React, { useCallback, useState } from 'react';
import type { MenuProps } from '../types';
import { LinkPreviewPanel } from '../../panels/LinkPreviewPanel';
import { LinkEditorPanel } from '../../panels';

export function LinkMenu({ editor, appendTo, extensions }: MenuProps): JSX.Element {
  const [showEdit, setShowEdit] = useState(false);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('link');
    return isActive;
  }, [editor]);

  const { href: link, target } = editor.getAttributes('link');

  const handleEdit = useCallback(() => {
    setShowEdit(true);
  }, []);

  const onSetLink = useCallback(
    (url: string, openInNewTab?: boolean) => {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url, target: openInNewTab ? '_blank' : '' })
        .run();
      setShowEdit(false);
    },
    [editor],
  );

  const onUnsetLink = useCallback(() => {
    editor.chain().focus().extendMarkRange('link').unsetLink().run();
    setShowEdit(false);
    return null;
  }, [editor]);

  const onShowEdit = useCallback(() => {
    setShowEdit(true);
  }, []);

  const onHideEdit = useCallback(() => {
    setShowEdit(false);
  }, []);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="textMenu"
      shouldShow={shouldShow}
      tippyOptions={{
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        appendTo: () => {
          return appendTo;
        },
        onHidden: () => {
          setShowEdit(false);
        },
      }}
      updateDelay={0}
    >
      {showEdit ? (
        <LinkEditorPanel
          extensions={extensions}
          initialOpenInNewTab={target === '_blank'}
          initialUrl={link}
          onSetLink={onSetLink}
        />
      ) : (
        <LinkPreviewPanel onClear={onUnsetLink} onEdit={handleEdit} url={link} />
      )}
    </BaseBubbleMenu>
  );
}

export default LinkMenu;
