import Stack from '@unifyapps/ui/_components/Stack';
import DateInput from './DateInput';
import TimeInput from './TimeInput';

interface DateTimeInputProps {
  date?: Date;
  setDate: (range: Date) => void;
  time: string;
  setTime: (timeRange: string) => void;
  disableNativePicker?: boolean;
}

export default function DateTimeInput(props: DateTimeInputProps) {
  const { date, setDate, time, setTime, disableNativePicker } = props;

  return (
    <Stack
      alignItems="center"
      className="p-xl border-secondary gap-x-lg border-b border-t"
      direction="row"
      justifyContent="space-between"
    >
      <DateInput disableNativePicker={disableNativePicker} onChange={setDate} value={date} />
      <TimeInput disableNativePicker={disableNativePicker} onChange={setTime} value={time} />
    </Stack>
  );
}
