'use client';
import { default as BaseButton } from '@mui/joy/Button';
import { default as BaseIconButton } from '@mui/joy/IconButton';
import { forwardRef } from 'react';
import type { LinkButtonProps } from './types';
import { getIconSize, linkButtonVariants } from './styles';

const BASE_BUTTON_SX = {
  '--Button-gap': '0.25rem',
};

const LinkButton = forwardRef<HTMLButtonElement, LinkButtonProps>((props, ref): JSX.Element => {
  const {
    Icon,
    ariaLabel,
    className,
    color = 'primary',
    startDecorator,
    endDecorator,
    children,
    disabled,
    ...rest
  } = props;
  const linkButtonClassName = linkButtonVariants({
    className,
    color,
  });

  if (Icon) {
    const { size, ...other } = rest;
    const iconSize = getIconSize(size);

    return (
      <BaseIconButton
        aria-label={ariaLabel}
        className={linkButtonClassName}
        disabled={disabled}
        ref={ref}
        size={size}
        sx={BASE_BUTTON_SX}
        // @ts-expect-error context is an internal variant
        variant="context"
        {...other}
      >
        <Icon height={iconSize} width={iconSize} />
      </BaseIconButton>
    );
  }

  const { size, EndDecorator, StartDecorator, ...other } = rest;

  const iconSize = getIconSize(size);
  const _startDecorator = StartDecorator ? (
    <StartDecorator height={iconSize} width={iconSize} />
  ) : (
    startDecorator
  );
  const _endDecorator = EndDecorator ? (
    <EndDecorator height={iconSize} width={iconSize} />
  ) : (
    endDecorator
  );

  return (
    <BaseButton
      className={linkButtonClassName}
      endDecorator={_endDecorator}
      ref={ref}
      size={size}
      startDecorator={_startDecorator}
      sx={BASE_BUTTON_SX}
      // @ts-expect-error context is an internal variant
      variant="context"
      {...other}
    >
      {children}
    </BaseButton>
  );
});

LinkButton.displayName = 'LinkButton';

export default LinkButton;
