import { slowCn } from '@unifyapps/ui/lib/utils';
import { Typography } from '@unifyapps/ui/components/Typography';
import type { SelectOptionType } from '../../types';
import { OptionLayout, OptionLayoutSlots } from './OptionLayout';

const renderIcon = (option: SelectOptionType, size?: 'sm' | 'md') => {
  if (option.Icon) {
    return (
      <option.Icon
        className={slowCn('text-fg-quaternary h-5 w-5', {
          'h-4 w-4': size === 'sm',
        })}
      />
    );
  }

  if (option.iconUrl) {
    return <img alt="" className="h-4 w-4" src={option.iconUrl} />;
  }

  return null;
};

function Option({
  option,
  size,
  endDecoratorRenderer,
}: {
  option: SelectOptionType;
  size?: 'sm' | 'md';
  endDecoratorRenderer?: (option: SelectOptionType) => React.ReactElement;
}) {
  return (
    <OptionLayout>
      <OptionLayout.Slot name={OptionLayoutSlots.Icon}>
        {renderIcon(option, size)}
      </OptionLayout.Slot>
      <OptionLayout.Slot name={OptionLayoutSlots.Label}>
        <Typography className="truncate" variant={size === 'sm' ? 'text-xs' : 'text-sm'}>
          {option.label}
        </Typography>
      </OptionLayout.Slot>
      <OptionLayout.Slot name={OptionLayoutSlots.EndDecorator}>
        {endDecoratorRenderer ? endDecoratorRenderer(option) : null}
      </OptionLayout.Slot>
    </OptionLayout>
  );
}

export default Option;
