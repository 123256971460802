import useEventCallback from '@unifyapps/hooks/useEventCallback';
import type { ErrorSchema, FieldProps } from '@rjsf/utils';
import _isPlainObject from 'lodash/isPlainObject';
import _isEmpty from 'lodash/isEmpty';

export function useObjectField(
  props: Pick<FieldProps, 'errorSchema' | 'formData' | 'onChange'> & {
    orderedProperties: string[] | React.ReactNode | undefined;
  },
) {
  const { errorSchema } = props;
  const onPropertyChange = useEventCallback(
    (
      propertyName: string,
      value: unknown | undefined,
      propertyErrorSchema?: ErrorSchema,
      id?: string,
    ) => {
      const newErrorSchema = errorSchema
        ? { ...errorSchema, [propertyName]: propertyErrorSchema }
        : errorSchema;

      // if current formData is not an object, means it is corrupted data, so we need to reset it to an object
      const newFormData = (_isPlainObject(props.formData) ? { ...props.formData } : {}) as object;

      // we only check for undefined here, as null and false are valid values
      if (value === undefined) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- we need to delete
        delete newFormData[propertyName];
      } else {
        newFormData[propertyName] = value;
      }

      // if formData is empty, we need to set it to undefined, we don't want to return an empty object
      // the field should not be present in the formData itself
      // ping @pradeet for queries
      if (_isEmpty(newFormData)) {
        props.onChange(undefined, newErrorSchema, id);
        return;
      }

      props.onChange(_isEmpty(newFormData) ? undefined : newFormData, newErrorSchema, id);
    },
  );

  return {
    onPropertyChange,
  };
}
