import { useCallback, useState } from 'react';
import _castArray from 'lodash/castArray';
import type {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteValue,
} from '@unifyapps/ui/components/Autocomplete';
import { useTranslation } from '@unifyapps/i18n/client';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import type { LookupOptionType } from '../types';

export default function useCreatableOptions({
  getOptionKey,
  createable,
}: {
  getOptionKey: (it?: Record<string, unknown> | null) => string;
  createable?: boolean;
}) {
  // we need to keep track of values that are not in the options list
  const [valuesToAppend, setValuesToAppend] = useState<string[]>([]);

  const { t } = useTranslation();

  const updateCreateValues = useCallback(
    (
      newValue: AutocompleteValue<LookupOptionType, boolean, false, false>,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<LookupOptionType & { inputValue?: string }>,
    ) => {
      const inputValue = details?.option.inputValue;
      if (inputValue && reason !== 'removeOption') {
        setValuesToAppend((prevValues) => [...prevValues, inputValue]);
      }

      setValuesToAppend((currentValuesToAppend) => {
        const newValueArray = new Set(_castArray(newValue).map((it) => getOptionKey(it)));
        return currentValuesToAppend.filter((val) => {
          return newValueArray.has(val);
        });
      });
    },
    [getOptionKey],
  );

  const getOptionsWithCreatedValues = useEventCallback(
    (options: LookupOptionType[], inputValue: string) => {
      const allOptions = [
        ...options,
        ...valuesToAppend.reduce<{ id: string; name: string }[]>((acc, val) => {
          if (val.includes(inputValue)) {
            acc.push({ id: val, name: val });
          }
          return acc;
        }, []),
      ];
      if (createable && inputValue) {
        const inputValueExists = allOptions.some((option) => option.name === inputValue);
        if (inputValue !== '' && !inputValueExists) {
          allOptions.push({
            id: inputValue,
            name: t('common:AddInputValue', { inputValue }),
            // @ts-expect-error -- required
            inputValue,
          });
        }
      }

      return allOptions;
    },
  );

  return { updateCreateValues, valuesToAppend, getOptionsWithCreatedValues };
}
