import type { Ref } from 'react';
import React, { forwardRef } from 'react';
import type { IconButtonProps } from '@rjsf/utils';
import ChevronUp from '@unifyapps/icons/outline/ChevronUp';
import LinkButton from '@unifyapps/ui/_components/LinkButton';

const MoveUpButton = forwardRef(
  (props: IconButtonProps, ref: Ref<HTMLButtonElement> | undefined) => {
    return (
      <LinkButton
        StartDecorator={ChevronUp}
        color="secondary"
        disabled={props.disabled}
        onClick={props.onClick}
        ref={ref}
        size="sm"
      />
    );
  },
);

MoveUpButton.displayName = 'MoveUpButton';

export default MoveUpButton;
