import { useMemo } from 'react';
import { cva } from 'class-variance-authority';
import type { Registry, UiSchema } from '@rjsf/utils';
import { getUiOptions } from '../utils/getUiOptions';

export const inputSizeVariants = cva('', {
  variants: {
    size: {
      sm: 'gap-xs px-sm',
      md: 'gap-sm px-md',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export const useGetSizeVariantClassName = (uiSchema: UiSchema | undefined, registry: Registry) => {
  const { size = 'md' } = useMemo(
    () =>
      getUiOptions({ uiSchema, registry }) as {
        size?: 'sm' | 'md';
        classes?: {
          listbox: string;
        };
        optionClassName?: string;
      },
    [registry, uiSchema],
  );

  const inputSizeClassName = inputSizeVariants({ size });

  return inputSizeClassName;
};
