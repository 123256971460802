import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBulletList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path d="M3.018 8.667A1.34 1.34 0 0 0 1.667 10a1.34 1.34 0 0 0 1.351 1.333A1.34 1.34 0 0 0 4.37 10a1.34 1.34 0 0 0-1.352-1.333m0-5.334a1.34 1.34 0 0 0-1.351 1.334A1.34 1.34 0 0 0 3.018 6 1.34 1.34 0 0 0 4.37 4.667a1.34 1.34 0 0 0-1.352-1.334m0 10.667c-.747 0-1.351.604-1.351 1.333 0 .73.613 1.334 1.351 1.334.74 0 1.352-.605 1.352-1.334S3.766 14 3.018 14m2.703 2.222h12.613v-1.778H5.72zm0-5.333h12.613V9.11H5.72zm0-7.111v1.778h12.613V3.778z" />
  </svg>
);
export default SvgBulletList;
