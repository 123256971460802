import type { DatePickerProps as BaseDatePickerProps } from '@unifyapps/ui/_components/DatePicker';
import type { PropsWithChildren } from 'react';
import { useCallback, useState, useEffect } from 'react';
import { DatePicker as BaseDatePicker } from '@unifyapps/ui/_components/DatePicker';
import { getInitialTime, dateSelectorClassNames, INITIAL_TIME_SINCE } from './utils';
import DatePickerActions from './DatePickerActions';
import DateTimeInput from './DateTimeInput';

type DateTimeProps = {
  initialValue?: Date;
  disabled?: BaseDatePickerProps['disabled'];
  onSubmit: (date: Date | undefined) => void;
  initialFocus?: boolean;
};

export default function DateTimePicker(props: PropsWithChildren<DateTimeProps>) {
  const { onSubmit, initialValue, disabled, initialFocus = true } = props;

  const [date, setDate] = useState<Date | undefined>(initialValue);
  const [time, setTime] = useState<string>(getInitialTime(initialValue));

  useEffect(() => {
    setDate(initialValue);
    setTime(getInitialTime(initialValue));
  }, [initialValue]);

  const onApply = useCallback(() => {
    if (!date || !time) {
      return;
    }

    const [hours, minutes] = time.split(':');
    const newDate = new Date(date);
    newDate.setHours(parseInt(hours));
    newDate.setMinutes(parseInt(minutes));

    onSubmit(newDate);
  }, [date, onSubmit, time]);

  const onClear = useCallback(() => {
    setDate(undefined);
    setTime(INITIAL_TIME_SINCE);
    onSubmit(undefined);
  }, [onSubmit]);

  const onReset = useCallback(() => {
    setDate(initialValue);
    setTime(getInitialTime(initialValue));
  }, [initialValue]);

  return (
    <>
      <BaseDatePicker
        captionLayout="dropdown-buttons"
        classNames={dateSelectorClassNames}
        defaultMonth={date}
        disabled={disabled}
        fixedWeeks
        fromYear={1970}
        initialFocus={initialFocus}
        mode="single"
        onSelect={setDate}
        required
        selected={date}
        showOutsideDays
        toYear={new Date().getFullYear()}
      />
      <DateTimeInput
        date={date}
        disableNativePicker
        setDate={setDate}
        setTime={setTime}
        time={time}
      />
      <DatePickerActions
        disableApply={!date}
        disableReset={date === initialValue}
        onApply={onApply}
        onClear={onClear}
        onReset={onReset}
      />
    </>
  );
}
