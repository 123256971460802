import React, { useMemo } from 'react';
import { clsx } from 'clsx';
import { getTemplate, getUiOptions } from '@rjsf/utils';
import type { TemplatesType, FieldTemplateProps } from '@rjsf/utils';
import Stack from '@unifyapps/ui/_components/Stack';
import FormControl from '@unifyapps/ui/_components/Form/FormControl';
import getUaOptions from '../../utils/getUaOptions';
import type { FieldHelpLinkTemplateProps } from '../FieldHelpLinkTemplate';

export const renderItem = (props: FieldTemplateProps, item: string) => {
  const {
    id,
    children,
    displayLabel,
    label,
    required,
    errors,
    help,
    description,
    schema,
    uiSchema,
    registry,
  } = props;
  const { type } = schema;
  const uiOptions = getUiOptions(uiSchema);

  const TitleFieldTemplate = getTemplate('TitleFieldTemplate', registry, uiOptions);
  const FieldHelpLinkTemplate = getTemplate(
    'FieldHelpLinkTemplate' as keyof TemplatesType,
    registry,
    uiOptions,
  ) as React.ComponentType<FieldHelpLinkTemplateProps>;

  switch (item) {
    case 'title':
      return displayLabel && type !== 'array' && type !== 'object' ? (
        <TitleFieldTemplate
          id={id}
          registry={registry}
          required={required}
          schema={schema}
          title={label}
          uiSchema={uiSchema}
        />
      ) : null;
    case 'helpLink':
      return <FieldHelpLinkTemplate uiSchema={uiSchema} />;
    case 'description':
      return displayLabel && description && type !== 'array' && type !== 'object'
        ? description
        : null;
    case 'children':
      return children;
    case 'errors':
      return errors;
    case 'help':
      return help;
    default:
      return null;
  }
};

function getReductionAmount(width: string) {
  const parsedWidth = parseInt(width, 10);
  const elementCountInRow = 100 / parsedWidth;

  // we want to reduce the width of each field type by an amount
  // proportional to its width in the row, therefore, we divide the
  // total gap by the number of fields (of the same width) in the row

  // ex. for 33% width, we want to create 2 * 16px gap between 3 fields,
  // and each field should be reduced by 32 / 3 = 10.67px

  return ((elementCountInRow - 1) * 16) / elementCountInRow;
}

function FieldTemplate(props: FieldTemplateProps) {
  const {
    id,
    children,
    classNames,
    style,
    disabled,
    hidden: _hidden,
    label,
    onDropPropertyClick,
    onKeyChange,
    readonly,
    required,
    rawErrors = [],
    schema,
    uiSchema,
    registry,
  } = props;
  const { type } = schema;

  const uiOptions = getUiOptions(uiSchema);
  const uaOptions = getUaOptions(uiSchema);
  const hidden = _hidden || uiOptions.hidden;

  const globalUiOptions = registry.globalUiOptions as
    | {
        childrenClassName?: string;
        titleClassName?: string;
        fieldLayout?: string[] | string[][];
        titleInputGap?: string;
      }
    | undefined;

  const WrapIfAdditionalTemplate = getTemplate('WrapIfAdditionalTemplate', registry, uiOptions);

  // if parent section is wrapped(flex-row and wrap) and child do not have any width then it will fit content which changes existing behavior
  // so passing 100% to keep existing behavior
  // subtracting 16px gap applied by parent, in proportion to width of a particular field
  // this is to substract width from each field to make it fit in the row, as expected
  const styleProps = useMemo(
    () => ({
      width:
        uaOptions.width && uaOptions.width !== '100%'
          ? `calc(${uaOptions.width} - ${getReductionAmount(uaOptions.width)}px)`
          : '100%',
    }),
    [uaOptions.width],
  );

  if (hidden) {
    return <Stack className="hidden">{children}</Stack>;
  }

  const childrenClassName = uiOptions.childrenClassName ?? globalUiOptions?.childrenClassName;
  const titleClassName = uiOptions.titleClassName ?? globalUiOptions?.titleClassName;

  const fieldLayout = uiOptions.fieldLayout ?? globalUiOptions?.fieldLayout;
  const titleInputGap =
    (uiOptions.titleInputGap as string | undefined) ?? globalUiOptions?.titleInputGap ?? '';

  const title = renderItem(props, 'title');
  const description = renderItem(props, 'description');

  return (
    <WrapIfAdditionalTemplate
      classNames={classNames}
      disabled={disabled}
      id={id}
      label={label}
      onDropPropertyClick={onDropPropertyClick}
      onKeyChange={onKeyChange}
      readonly={readonly}
      registry={registry}
      required={required}
      schema={schema}
      style={style}
      uiSchema={uiSchema}
    >
      <FormControl
        className={clsx('gap-y-sm flex', uiOptions.classNames, titleInputGap)}
        data-scroll-id={id}
        error={Boolean(rawErrors.length)}
        id={id}
        required={required}
        sx={styleProps}
      >
        {fieldLayout && Array.isArray(fieldLayout) ? (
          fieldLayout.map((item: string | string[]) => {
            if (Array.isArray(item)) {
              return (
                <Stack
                  className="flex flex-row items-center justify-between gap-x-3"
                  key={item.join('-')}
                >
                  {item.map((it: string) => (
                    <Stack
                      className={clsx(it === 'children' ? childrenClassName : titleClassName)}
                      key={it}
                    >
                      {renderItem(props, it)}
                    </Stack>
                  ))}
                </Stack>
              );
            }
            return <Stack key={item}>{renderItem(props, item)}</Stack>;
          })
        ) : (
          <>
            {type === 'array' || type === 'object' || !title || !description ? null : (
              <Stack className="gap-y-sm flex">
                {title}
                {description}
              </Stack>
            )}
            {renderItem(props, 'children')}
            {renderItem(props, 'errors')}
            {type === 'array' || type === 'object' ? null : (
              <>
                {renderItem(props, 'help')}
                {renderItem(props, 'helpLink')}
              </>
            )}
          </>
        )}
      </FormControl>
    </WrapIfAdditionalTemplate>
  );
}

export default FieldTemplate;
