import { memo } from 'react';
import { clsx } from 'clsx';
import iconList from './iconList';

export type IconProps = {
  name: string;
  className?: string;
  strokeWidth?: number;
};

/**@deprecated
 * This is a dummy component. To be discontinued.
 */
export const Icon = memo(({ name, className, strokeWidth }: IconProps) => {
  const IconComponent = iconList[name] as
    | React.ComponentType<React.SVGProps<SVGSVGElement>>
    | undefined;

  if (!IconComponent) return <div>{name}</div>;
  return <IconComponent className={clsx('h-4 w-4', className)} strokeWidth={strokeWidth || 2.5} />;
});

Icon.displayName = 'Icon';
