import { cn } from '../../../lib/utils';

export function DropdownCategoryTitle({ children }: { children: React.ReactNode }) {
  return (
    <div className="text-utility-gray-400 mb-1 px-1.5 text-[.65rem] font-semibold uppercase">
      {children}
    </div>
  );
}

export function DropdownButton({
  children,
  isActive,
  onClick,
  disabled,
  className,
}: {
  children: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}) {
  const buttonClass = cn(
    'flex items-center gap-2 p-1.5 text-sm font-medium text-utility-gray-400 text-left bg-transparent w-full rounded-xs',
    !isActive && !disabled,
    'hover:bg-utility-gray-50 hover:text-utility-gray-700',
    isActive && !disabled && 'bg-utility-gray-50 text-utility-gray-700',
    disabled && 'text-utility-gray-300 cursor-not-allowed',
    className,
  );

  return (
    <button className={buttonClass} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
}
