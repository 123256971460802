/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment -- required */
import type { SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDebouncedCallback } from '@react-hookz/web';
import _castArray from 'lodash/castArray';
import _compact from 'lodash/compact';
import _isNil from 'lodash/isNil';
import type { AutocompleteSlots, AutocompleteValue } from '@unifyapps/ui/components/Autocomplete';
import { Autocomplete as BaseAutocomplete } from '@unifyapps/ui/components/Autocomplete';
import { useLookup } from '@unifyapps/network/generated/lookup-rest-api/lookup-rest-api';
import type { LookupByKeysRequest } from '@unifyapps/network/generated/models/lookupByKeysRequest';
import type { MappedHits } from '@unifyapps/network/generated/models/mappedHits';
import { LookupByKeysRequestType } from '@unifyapps/network/generated/models/lookupByKeysRequestType';
import { keepPreviousData } from '@unifyapps/network/react-query';
import { Chip } from '@unifyapps/ui/components/Chip';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { useLookupInfiniteQuery } from '../../hooks/useLookupInfiniteQuery';
import IconWrap from '../IconWrap';
import { listBoxRenderer } from '../AutocompleteListBox';
import type {
  AutocompleteContainerProps,
  BaseProps,
  LookupAutocompleteProps,
  AutocompleteProps,
} from './types';
import { iconSizeClassName } from './styles';

function isOptionEqualToValue(
  _option: { id: string } | undefined,
  _value: { id: string } | undefined,
) {
  return _option?.id === _value?.id;
}

function getOptionKey(option: { id: string } | undefined) {
  return option?.id ?? '';
}

function LookupAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  lookupRequest,
  slots,
  slotProps,
  pageSize = 40,
  value,
  onOpen,
  multiple,
  refetchOnOpen,
  id,
  optionsToAppend,
  valuesToAppend,
  getOptionIcon,
  initialValueIndex,
  getOptionLabel,
  ...restProps
}: Omit<BaseProps<T, Multiple, DisableClearable, FreeSolo>, 'value'> &
  LookupAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const {
    isLoading,
    data,
    fetchNextPage: _fetchNextPage,
    error,
    refetch,
  } = useLookupInfiniteQuery<T>(lookupRequest, pageSize);

  const lookupOptions = useMemo(
    () => [
      ...(data?.pages.flatMap((page) => page.response.objects) ?? []),
      ...(optionsToAppend ?? []),
    ],
    [data?.pages, optionsToAppend],
  );

  const { onChange } = restProps;

  // Need this ref to check wether we have initialised the value or not after options are fetched.
  // If we have set initial value and user clears it, we dont need to set it again.
  const valueInitialisedRef = useRef(false);
  useEffect(() => {
    if (!valueInitialisedRef.current && !value && !isLoading && initialValueIndex !== undefined) {
      valueInitialisedRef.current = true;
      onChange?.(
        {} as SyntheticEvent,
        lookupOptions[initialValueIndex] as AutocompleteValue<
          T,
          Multiple,
          DisableClearable,
          FreeSolo
        >,
        'selectOption',
      );
    }
  }, [initialValueIndex, isLoading, lookupOptions, onChange, value]);

  const fetchNextPage = useDebouncedCallback(_fetchNextPage, [_fetchNextPage], 300);

  const { data: resolvedData } = useLookup<{
    response: MappedHits | undefined;
  }>(
    {
      type: LookupByKeysRequestType.ByKeys,
      keys: _castArray(value),
      fields: lookupRequest.fields,
      lookupType: lookupRequest.lookupType,
      options: lookupRequest.options,
    } as LookupByKeysRequest,
    {
      query: {
        enabled: Array.isArray(value) ? Boolean(value.length) : Boolean(value),
        placeholderData: keepPreviousData,
      },
    },
  );

  const lookupResolvedValue = useMemo(() => {
    const _resolvedValue = _compact([
      ..._castArray(value).map((val) => resolvedData?.response?.objects?.[val]),
      ...(valuesToAppend ? valuesToAppend.map((val) => ({ id: val, name: val })) : []),
    ]);

    if (multiple) {
      return value?.length ? _resolvedValue : [];
    }

    return value && _resolvedValue[0] ? _resolvedValue[0] : null;
  }, [multiple, resolvedData?.response?.objects, value, valuesToAppend]) as AutocompleteValue<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
  >;

  const updatedSlots = useMemo(
    () => ({
      listbox: listBoxRenderer({
        fetchNextPage,
        slots,
        error,
        slotProps,
      }),
      ...slots,
    }),
    [fetchNextPage, slots, error, slotProps],
  ) as AutocompleteSlots;

  const _onOpen = useCallback(
    (event: SyntheticEvent) => {
      onOpen?.(event);
      if (refetchOnOpen) {
        void refetch();
      }
    },
    [refetch, onOpen, refetchOnOpen],
  );

  const optionIconClassName = iconSizeClassName({ size: restProps.size });

  const startDecoratorNode = useMemo(() => {
    if (restProps.startDecoratorNode) {
      return restProps.startDecoratorNode;
    }

    if (!multiple) {
      const item = lookupResolvedValue as unknown as Record<string, unknown>;
      const icon = getOptionIcon?.(item);

      if (typeof icon === 'string') {
        return (
          <IconWrap
            alt={getOptionLabel(item as T)}
            iconClassName={optionIconClassName}
            src={icon}
          />
        );
      }

      if (typeof icon === 'function') {
        return <IconWrap Icon={icon} iconClassName={optionIconClassName} />;
      }
    }
  }, [
    restProps.startDecoratorNode,
    multiple,
    lookupResolvedValue,
    getOptionIcon,
    getOptionLabel,
    optionIconClassName,
  ]);

  const renderTags = useEventCallback((tags: T[]) => {
    return tags.map((option, index) => {
      const icon = getOptionIcon?.(option as Record<string, unknown>);
      let IconNode: React.ReactNode = null;
      if (typeof icon === 'string') {
        IconNode = <IconWrap alt={getOptionLabel(option)} iconClassName="size-[14px]" src={icon} />;
      }
      if (typeof icon === 'function') {
        IconNode = <IconWrap Icon={icon} iconClassName="size-[14px]" />;
      }

      return (
        <Chip
          className="-m-[1px] max-w-full"
          key={index.toString()}
          label={getOptionLabel(option)}
          size="sm"
          startDecoratorNode={IconNode}
          variant="outlined"
        />
      );
    });
  });

  return (
    <BaseAutocomplete
      {...restProps}
      getOptionKey={getOptionKey as any}
      getOptionLabel={getOptionLabel}
      id={id}
      isOptionEqualToValue={restProps.isOptionEqualToValue ?? (isOptionEqualToValue as any)}
      loading={isLoading || restProps.loading}
      multiple={multiple}
      onOpen={_onOpen}
      options={lookupOptions}
      renderTags={restProps.renderTags ?? renderTags}
      slots={updatedSlots}
      startDecoratorNode={startDecoratorNode}
      value={lookupResolvedValue}
    />
  );
}

function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  props: BaseProps<T, Multiple, DisableClearable, FreeSolo> &
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
) {
  const updatedSlots = useMemo(
    () =>
      props.fetchNextPage
        ? {
            listbox: listBoxRenderer({
              fetchNextPage: props.fetchNextPage,
              slots: props.slots,
              slotProps: props.slotProps,
            }),
            ...props.slots,
          }
        : props.slots,
    [props.fetchNextPage, props.slots, props.slotProps],
  ) as AutocompleteSlots;

  return (
    <BaseAutocomplete
      {...props}
      slots={updatedSlots}
      value={
        (props.multiple
          ? props.resolvedValue
          : (props.resolvedValue?.[0] ?? null)) as AutocompleteValue<
          T,
          Multiple,
          DisableClearable,
          FreeSolo
        >
      }
    />
  );
}

function AutocompleteContainer<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(props: AutocompleteContainerProps<T, Multiple, DisableClearable, FreeSolo>) {
  if ('lookupRequest' in props && !_isNil(props.lookupRequest)) {
    return <LookupAutocomplete {...props} />;
  }

  if ('options' in props && !_isNil(props.options)) {
    return <Autocomplete {...props} />;
  }

  console.error(
    'AutocompleteContainer: Invalid props provided. Please provide either `lookupRequest` or `options` prop.',
  );
  return null;
}

export default AutocompleteContainer;
