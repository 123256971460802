import type { Editor } from '@tiptap/react';
import { useEditor } from '@tiptap/react';
import type { BlockEditorProps } from '../components/BlockEditor/types';
import { ExtensionKit } from '../extensions/extension-kit';

declare global {
  interface Window {
    editor: Editor | null;
  }
}

export const useBlockEditor = (props: BlockEditorProps) => {
  const { editable, onBlur, onFocus, value, id, autofocus = true } = props;
  const editor = useEditor(
    {
      immediatelyRender: false,
      content: value,
      autofocus,
      editable: editable ?? true,
      onBlur: ({ event }) => {
        onBlur?.(event);
      },
      onFocus: ({ event }) => {
        onFocus?.(event);
      },
      extensions: [...ExtensionKit(props)],
      editorProps: {
        attributes: {
          id: id ?? '',
          autocomplete: 'off',
          autocorrect: 'off',
          autocapitalize: 'off',
          class: 'min-h-full',
        },
      },
    },
    [],
  );

  // window.editor = editor;

  return { editor };
};
