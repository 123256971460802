import type { Editor } from '@tiptap/react';
import { BubbleMenu } from '@tiptap/react';
import { memo } from 'react';
import { Toolbar } from '../../ui/Toolbar';
import { Icon } from '../../ui/Icon';
import { ColorPicker } from '../../panels';
import { useTextmenuStates } from './hooks/useTextmenuStates';
import { useTextmenuCommands } from './hooks/useTextmenuCommands';
import { FontFamilyPicker } from './components/FontFamilyPicker';
import { FontSizePicker } from './components/FontSizePicker';
import { useTextmenuContentTypes } from './hooks/useTextmenuContentTypes';
import { ContentTypePicker } from './components/ContentTypePicker';
import { EditLinkPopover } from './components/EditLinkPopover';

// We memorize the button so each button is not rerendered
// on every editor state change
const MemoButton = memo(Toolbar.Button);
const MemoColorPicker = memo(ColorPicker);
const MemoFontFamilyPicker = memo(FontFamilyPicker);
const MemoFontSizePicker = memo(FontSizePicker);
const MemoContentTypePicker = memo(ContentTypePicker);

export type TextMenuProps = {
  editor: Editor;
};

export function TextMenu({ editor }: TextMenuProps) {
  const commands = useTextmenuCommands(editor);
  const states = useTextmenuStates(editor);
  const blockOptions = useTextmenuContentTypes(editor);

  return (
    <BubbleMenu
      editor={editor}
      pluginKey="textMenu"
      shouldShow={states.shouldShow}
      tippyOptions={{ popperOptions: { placement: 'top-start' }, appendTo: () => document.body }}
      updateDelay={100}
    >
      <Toolbar.Wrapper>
        <MemoContentTypePicker options={blockOptions} />
        <MemoFontFamilyPicker onChange={commands.onSetFont} value={states.currentFont || ''} />
        <MemoFontSizePicker onChange={commands.onSetFontSize} value={states.currentSize || ''} />
        <Toolbar.Divider />
        <MemoButton
          active={states.isBold}
          onClick={commands.onBold}
          tooltip="Bold"
          tooltipShortcut={['Mod', 'B']}
        >
          <Icon name="Bold" />
        </MemoButton>
        <MemoButton
          active={states.isItalic}
          onClick={commands.onItalic}
          tooltip="Italic"
          tooltipShortcut={['Mod', 'I']}
        >
          <Icon name="Italic" />
        </MemoButton>
        <MemoButton
          active={states.isUnderline}
          onClick={commands.onUnderline}
          tooltip="Underline"
          tooltipShortcut={['Mod', 'U']}
        >
          <Icon name="Underline" />
        </MemoButton>
        <MemoButton
          active={states.isStrike}
          onClick={commands.onStrike}
          tooltip="Strikehrough"
          tooltipShortcut={['Mod', 'Shift', 'S']}
        >
          <Icon name="Strikethrough" />
        </MemoButton>
        {/* <MemoButton
          active={states.isCode}
          onClick={commands.onCode}
          tooltip="Code"
          tooltipShortcut={['Mod', 'E']}
        >
          <Icon name="Code" />
        </MemoButton> */}
        {/* <MemoButton onClick={commands.onCodeBlock} tooltip="Code block"> */}
        {/* <Icon name="Code2" /> */}
        {/* </MemoButton> */}
        {/* <EditLinkPopover onSetLink={commands.onLink} /> */}
        {/* <Popover.Root>
          <Popover.Trigger asChild>
            <MemoButton active={Boolean(states.currentHighlight)} tooltip="Highlight text">
              <Icon name="Highlighter" />
            </MemoButton>
          </Popover.Trigger>
          <Popover.Content asChild side="top" sideOffset={8}>
            <Surface className="p-1">
              <MemoColorPicker
                color={states.currentHighlight}
                onChange={commands.onChangeHighlight}
                onClear={commands.onClearHighlight}
              />
            </Surface>
          </Popover.Content>
        </Popover.Root> */}
        {/* <Popover.Root>
          <Popover.Trigger asChild>
            <MemoButton active={Boolean(states.currentColor)} tooltip="Text color">
              <Icon name="Palette" />
            </MemoButton>
          </Popover.Trigger>
          <Popover.Content asChild side="top" sideOffset={8}>
            <Surface className="p-1">
              <MemoColorPicker
                color={states.currentColor}
                onChange={commands.onChangeColor}
                onClear={commands.onClearColor}
              />
            </Surface>
          </Popover.Content>
        </Popover.Root> */}
        {/* <Popover.Root>
          <Popover.Trigger asChild>
            <MemoButton tooltip="More options">
              <Icon name="Menu" />
            </MemoButton>
          </Popover.Trigger>
          <Popover.Content asChild side="top">
            <Toolbar.Wrapper>
              <MemoButton
                active={states.isSubscript}
                onClick={commands.onSubscript}
                tooltip="Subscript"
                tooltipShortcut={['Mod', '.']}
              >
                <Icon name="Subscript" />
              </MemoButton>
              <MemoButton
                active={states.isSuperscript}
                onClick={commands.onSuperscript}
                tooltip="Superscript"
                tooltipShortcut={['Mod', ',']}
              >
                <Icon name="Superscript" />
              </MemoButton>
              <Toolbar.Divider />
              <MemoButton
                active={states.isAlignLeft}
                onClick={commands.onAlignLeft}
                tooltip="Align left"
                tooltipShortcut={['Shift', 'Mod', 'L']}
              >
                <Icon name="AlignLeft" />
              </MemoButton>
              <MemoButton
                active={states.isAlignCenter}
                onClick={commands.onAlignCenter}
                tooltip="Align center"
                tooltipShortcut={['Shift', 'Mod', 'E']}
              >
                <Icon name="AlignCenter" />
              </MemoButton>
              <MemoButton
                active={states.isAlignRight}
                onClick={commands.onAlignRight}
                tooltip="Align right"
                tooltipShortcut={['Shift', 'Mod', 'R']}
              >
                <Icon name="AlignRight" />
              </MemoButton>
              <MemoButton
                active={states.isAlignJustify}
                onClick={commands.onAlignJustify}
                tooltip="Justify"
                tooltipShortcut={['Shift', 'Mod', 'J']}
              >
                <Icon name="AlignJustify" />
              </MemoButton>
            </Toolbar.Wrapper>
          </Popover.Content>
        </Popover.Root> */}
      </Toolbar.Wrapper>
    </BubbleMenu>
  );
}
