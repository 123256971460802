import { cva } from 'class-variance-authority';
import type { ButtonGroupSizes } from '../ButtonGroup';

export const getRootClassName = cva('flex', {
  variants: {
    position: {
      left: 'justify-start',
      center: 'justify-center',
      right: 'justify-end',
    },
  },
});

export const getButtonGroupClassName = cva('shadow-xs gap-xs bg-secondary_alt', {
  variants: {
    size: {
      xs: 'p-xxs',
      sm: 'p-xs',
      md: 'p-xs',
    } satisfies Record<ButtonGroupSizes, string>,
  },
});
