import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';
import React, { useCallback } from 'react';
import { MenuList } from '@unifyapps/ui/components/Menu';
import type { MenuListProps } from '@unifyapps/ui/components/Menu/MenuList';
import { Toolbar } from '../../../../components/ui/Toolbar';
import { Icon } from '../../../../components/ui/Icon';
import type { MenuProps, ShouldShowProps } from '../../../../components/menus/types';
import { isRowGripSelected } from './utils';

const items: MenuListProps['items'] = [
  {
    title: 'Add row before',
    startDecoratorNode: <Icon name="ArrowUp" />,
    id: 'add-row-before',
    type: 'label',
  },
  {
    title: 'Add row after',
    startDecoratorNode: <Icon name="ArrowDown" />,
    id: 'add-row-after',
    type: 'label',
  },
  {
    title: 'Delete row',
    startDecoratorNode: <Icon name="Trash" />,
    id: 'delete-row',
    type: 'label',
  },
];

export const TableRowMenu = React.memo(({ editor, appendTo }: MenuProps): JSX.Element => {
  const shouldShow = useCallback(
    ({ view, state, from }: ShouldShowProps) => {
      if (!state || !from) {
        return false;
      }

      return isRowGripSelected({ editor, view, state, from });
    },
    [editor],
  );

  const onAddRowBefore = useCallback(() => {
    editor.chain().focus().addRowBefore().run();
  }, [editor]);

  const onAddRowAfter = useCallback(() => {
    editor.chain().focus().addRowAfter().run();
  }, [editor]);

  const onDeleteRow = useCallback(() => {
    editor.chain().focus().deleteRow().run();
  }, [editor]);

  const onAction = useCallback(
    (id) => {
      switch (id) {
        case 'add-row-before':
          onAddRowBefore();
          break;
        case 'add-row-after':
          onAddRowAfter();
          break;
        case 'delete-row':
          onDeleteRow();
          break;
      }
    },
    [onAddRowAfter, onAddRowBefore, onDeleteRow],
  );

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="tableRowMenu"
      shouldShow={shouldShow}
      tippyOptions={{
        appendTo: () => {
          return appendTo;
        },
        placement: 'left',
        offset: [0, 15],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
      }}
      updateDelay={0}
    >
      <Toolbar.Wrapper className="p-0" isVertical>
        <MenuList items={items} onAction={onAction} />
      </Toolbar.Wrapper>
    </BaseBubbleMenu>
  );
});

TableRowMenu.displayName = 'TableRowMenu';

export default TableRowMenu;
