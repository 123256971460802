import type { ButtonSizes } from '@unifyapps/ui/components/Button';

export const getButtonSize = (size: 'sm' | 'md' | 'lg' | undefined): ButtonSizes => {
  //in form we have sm variant and for sm variant form xs button needs to be picked
  switch (size) {
    case 'sm':
      return 'xs';
    case 'md':
      return 'sm';
    case 'lg':
      return 'md';
    default:
      return 'sm';
  }
};
