import type { InputProps, InputSizes } from '@unifyapps/ui/components/Input';
import { Input } from '@unifyapps/ui/components/Input';
import type { MutableRefObject } from 'react';
import { forwardRef, memo } from 'react';
import { clsx } from 'clsx';
import { preventNativePicker } from './utils';

interface TimeInputProps {
  value?: string;
  onChange: (value: string) => void;
  error?: boolean;
  disabled?: boolean;
  disableNativePicker?: boolean;
  placeholder?: string;
  size?: InputSizes;
  className?: string;
}

const TimeInput = forwardRef(
  (
    {
      value,
      onChange,
      error,
      disabled,
      disableNativePicker,
      size,
      className,
      ...rest
    }: Omit<InputProps, 'onChange'> & TimeInputProps,
    ref: MutableRefObject<HTMLInputElement>,
  ) => {
    return (
      <Input
        className={clsx('flex-1', className)}
        disabled={disabled}
        error={error}
        onChangeValue={onChange}
        onKeyDown={disableNativePicker ? preventNativePicker : undefined}
        ref={ref}
        size={size}
        type="time"
        value={value}
        {...rest}
      />
    );
  },
);

TimeInput.displayName = 'TimeInput';

export default memo(TimeInput);
