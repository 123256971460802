import CheckboxesWidget from './CheckboxesWidget';
import CheckboxWidget from './CheckboxWidget';
import RadioWidget from './RadioWidget';
import SelectWidget from './SelectWidget';
import TextareaWidget from './TextareaWidget';
import MultiSelectWidget from './MultiSelectWidget';
import TextWidget from './TextWidget';
import ToggleButtonGroupWidget from './ToggleButtonGroupWidget';
import ToggleWidget from './ToggleWidget';
import RichTextWidget from './RichTextWidget';
import PasswordWidget from './PasswordWidget';
import EmailWidget from './EmailWidget';
import FileWidget from './FileWidget';
import DividerWidget from './DividerWidget';

const widgets = {
  TextWidget,
  CheckboxesWidget,
  FileWidget,
  CheckboxWidget,
  RadioWidget,
  SelectWidget,
  TextareaWidget,
  MultiSelectWidget,
  ToggleButtonGroupWidget,
  ToggleWidget,
  RichTextWidget,
  PasswordWidget,
  DividerWidget,
  EmailWidget,
};

export type DefaultWidgets = typeof widgets;

export default widgets;
