import { useCallback, useMemo } from 'react';
import type { WidgetProps } from '@rjsf/utils';
import { isValid } from 'date-fns/isValid';
import { DateWidgetType, formatDate, getDatePickerValue, parseDate } from './utils';

// these types are required to be exported for date input component to work
export const DISPLAY_DATE_FORMAT = 'yyyy-MM-dd';
const DISPLAY_TIME_FORMAT = 'HH:mm';

function getDateDisplayString(date: Date | null, widget: DateWidgetType) {
  if (!isValid(date) || !date) return '';

  const formattedDate = formatDate(date, DISPLAY_DATE_FORMAT);
  const formattedTime = formatDate(date, DISPLAY_TIME_FORMAT);
  return widget === DateWidgetType.Date ? formattedDate : `${formattedDate}T${formattedTime}`;
}

export function useDateInputValue({
  value,
  dateFormat: initialDateFormat,
  widgetType,
  options,
}: {
  value: string | number | undefined;
  widgetType: DateWidgetType;
  dateFormat: string | undefined;
  options?: WidgetProps['options'];
}) {
  const dateFormat = useMemo(() => {
    if (initialDateFormat !== 'epoch') {
      return initialDateFormat;
    }
    if (widgetType === DateWidgetType.Date) {
      return DISPLAY_DATE_FORMAT;
    }
    return `${DISPLAY_DATE_FORMAT} ${DISPLAY_TIME_FORMAT}`;
  }, [initialDateFormat, widgetType]);

  const inputValue = useMemo(
    () => getDateDisplayString(parseDate(value ?? '', dateFormat), widgetType),
    [dateFormat, widgetType, value],
  );

  const datePickerValue = useMemo(() => getDatePickerValue(value, dateFormat), [dateFormat, value]);

  const getParsedReturnValue = useCallback(
    (val?: string) => {
      if (initialDateFormat === 'epoch') {
        return val ? new Date(val).getTime() : (options?.emptyValue as number);
      }
      return val ? formatDate(new Date(val), initialDateFormat) : (options?.emptyValue as string);
    },
    [initialDateFormat, options?.emptyValue],
  );

  return {
    inputValue,
    datePickerValue,
    getParsedReturnValue,
  };
}
