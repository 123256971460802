import { Typography } from '@unifyapps/ui/components/Typography';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import type { SelectOptionType } from '../../types';

type TextDecoratorProps = {
  value: string | undefined;
  typographyProps?: Record<string, string>;
};

function TextDecorator({ value, typographyProps }: TextDecoratorProps) {
  if (!value) {
    return null;
  }

  return (
    <Typography color="text-quaternary" variant="text-xs" weight="regular" {...typographyProps}>
      {value}
    </Typography>
  );
}

export default TextDecorator;
