export const outlineVariantStyles = ({
  isSelected,
  readonly,
  disabled,
}: {
  isSelected: boolean;
  readonly?: boolean;
  disabled?: boolean;
}) => {
  return {
    'p-lg rounded-xl border': true,
    'border-secondary': !isSelected,
    'border-brand-solid ring-brand-inset': isSelected && !readonly,
    'border-disabled_subtle bg-disabled_subtle text-fg-disabled_subtle': disabled || readonly,
  };
};
