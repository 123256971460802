import * as React from 'react';
import type { SVGProps } from 'react';
const SvgH2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path strokeLinecap="square" d="M7 5v6.5M7 18v-6.5M17 5v13m0-6.5H7" />
  </svg>
);
export default SvgH2;
