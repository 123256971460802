import React, { useCallback, useState } from 'react';
import type { UIOptionsType } from '@rjsf/utils';
import { canExpand } from '@rjsf/utils';
import { clsx } from 'clsx';
import Stack from '@unifyapps/ui/_components/Stack';
import { cond } from '@unifyapps/hooks/useCond';
import { getUiOptions } from '../../utils/getUiOptions';
import getUaOptions from '../../utils/getUaOptions';
import { getBackgroundClass, getContentNode, getIsCollapsible, isRootNode } from './utils';
import {
  ObjectBackgroundToggleContextProvider,
  useObjectBackgroundToggleValue,
} from './context/ObjectBackgroundContext';
import VerticalLayout from './components/VerticalLayout';
import ObjectFieldHeader from './components/ObjectFieldHeader';
import HorizontalSectionLayout from './components/HorizontalSectionLayout';
import type { UAObjectFieldTemplateProps } from './types';

function ObjectFieldTemplate(props: UAObjectFieldTemplateProps) {
  const {
    description,
    title,
    properties,
    required,
    disabled,
    readonly,
    uiSchema,
    idSchema,
    schema,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- needed
    formData,
    onAddClick,
    registry,
  } = props;

  const isRoot = isRootNode(idSchema);
  const uiOptions = getUiOptions({
    uiSchema,
    registry,
  }) as UIOptionsType & {
    size?: 'sm' | 'md';
    objectFieldClassNames?: {
      // To give gap between title and its properties
      titlePropertiesGap?: 'string';
    };
  };
  const size = uiOptions.size as 'sm' | 'md' | undefined;
  const titlePropertiesGap = uiOptions.objectFieldClassNames?.titlePropertiesGap ?? '';
  const uaOptions = getUaOptions<{ boldTitle?: boolean }>(uiSchema);
  const hasTitleOrDescription = Boolean(title || description);

  const isCollapsible = getIsCollapsible({ isRoot, uiSchema, registry });

  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement>) => {
      event?.stopPropagation();
      isCollapsible && setCollapsed((prev) => !prev);
    },
    [isCollapsible],
  );

  // Button templates are not overridden in the uiSchema
  const {
    ButtonTemplates: { AddButton },
  } = registry.templates;

  const _onAddClick = useCallback(() => {
    onAddClick(schema);
  }, [onAddClick, schema]);

  const toggleValue = useObjectBackgroundToggleValue();
  const disableBackgroundToggle =
    isRoot || !hasTitleOrDescription || Boolean(uaOptions.disableBackground);

  const renderLayout = () => {
    if (Array.isArray(uiOptions.sections)) {
      return (
        <HorizontalSectionLayout
          properties={properties}
          sections={uiOptions.sections}
          uiSchema={uiSchema}
        />
      );
    }

    if (Array.isArray(uiOptions.layout)) {
      return (
        <VerticalLayout
          className={clsx(
            collapsed ? 'hidden' : '',
            isRoot ? uiOptions.rootClassName : '',
            getBackgroundClass({ size, disableBackgroundToggle, toggleValue }),
            uiOptions.fieldBodyClassName,
          )}
          horizontalClassName={uiOptions.horizontalClassName as string}
          layout={uiOptions.layout}
          properties={properties}
          uiSchema={uiSchema}
        />
      );
    }

    if (collapsed) return null;
    return (
      <ObjectBackgroundToggleContextProvider
        // NOTE: if there is no title or description the object used just for organising data and should not be show as a different group eg: TriggerCondition
        value={disableBackgroundToggle ? toggleValue : !toggleValue}
      >
        <Stack
          className={clsx(
            'gap-xl object-properties',
            isRoot ? uiOptions.rootClassName : uiOptions.objectPropertiesClassName,
            getBackgroundClass({ size, disableBackgroundToggle, toggleValue }),
            clsx({ 'flex-row flex-wrap': uaOptions.wrap }),
            uiOptions.fieldBodyClassName,
          )}
        >
          {properties.map((element) => {
            const contentNode = getContentNode(element);
            return cond(
              [
                element.hidden,
                <Stack className="hidden" key={`hidden-${element.name}`}>
                  {contentNode}
                </Stack>,
              ],
              // need to render field without container to override their width
              [uaOptions.wrap, contentNode],
              <Stack key={element.name}>{contentNode}</Stack>,
            );
          })}
          {canExpand(schema, uiSchema, formData) && !readonly ? (
            <Stack className="flex justify-end">
              <AddButton
                disabled={disabled}
                onClick={_onAddClick}
                registry={registry}
                uiSchema={uiSchema}
              />
            </Stack>
          ) : null}
        </Stack>
      </ObjectBackgroundToggleContextProvider>
    );
  };

  return (
    <Stack
      className={clsx(
        'object-field-template',
        disableBackgroundToggle ? 'gap-y-xl' : 'gap-y-sm',
        titlePropertiesGap,
      )}
    >
      {hasTitleOrDescription ? (
        <ObjectFieldHeader
          collapsed={collapsed}
          description={description}
          idSchema={idSchema}
          isCollapsible={isCollapsible}
          registry={registry}
          required={required}
          schema={schema}
          title={title}
          toggleCollapsed={toggleCollapsed}
          uiSchema={uiSchema}
        />
      ) : null}
      {renderLayout()}
    </Stack>
  );
}

export default ObjectFieldTemplate;
