import { type ChangeEvent, type FocusEvent, useCallback, useMemo, useState } from 'react';
import type { WidgetProps } from '@rjsf/utils';
import { Input } from '@unifyapps/ui/components/Input';
import Calendar from '@unifyapps/icons/outline/Calendar';
import { Box } from '@unifyapps/ui/components/Box';
import { Popover } from '@unifyapps/ui/components/Popover';
import { ClickAwayListener } from '@unifyapps/ui/components/ClickAwayListener';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import getUaOptions from '@unifyapps/form/utils/getUaOptions';
import { getUiOptions } from '@unifyapps/form/utils/getUiOptions';
import type { DatePickerProps as BaseDatePickerProps } from '@unifyapps/ui/_components/DatePicker';
import type { Matcher } from 'react-day-picker';
import { DatePicker, DateTimePicker } from '../../components/DatePicker';
import type { DateTimeWidgetUaOptions } from './utils';
import { calculateDateRange, DateWidgetType } from './utils';
import { DISPLAY_DATE_FORMAT, useDateInputValue } from './useDateInputValue';
import { getStartDecoratorIconClassName, getStartDecoratorNodeRootClassName } from './styles';

type DateTypeSchema = {
  format?: DateWidgetType;
  dateFormat?: string;
};

const getDateFormat = (format: DateWidgetType, dateFormat?: string) => {
  if (dateFormat) return dateFormat;
  else if (format === DateWidgetType.Date) return DISPLAY_DATE_FORMAT;
};

const getFormatAndDateFormat = (schema: DateTypeSchema, uaOptions: DateTimeWidgetUaOptions) => {
  let { format, dateFormat } = schema;

  format = format ?? uaOptions.format ?? DateWidgetType.Date;
  dateFormat = dateFormat ?? uaOptions.dateFormat;

  return { format, dateFormat };
};

// limit year to 4 digits
const DATE_TIME_SLOT_PROPS = {
  input: {
    max: '9999-12-31T23:59',
  },
};

const getStartDecoratorNode = ({ size }: { size: 'sm' | 'md' }) => {
  const startDecoratorNodeClassName = getStartDecoratorNodeRootClassName({ size });
  const startDecoratorIconClassName = getStartDecoratorIconClassName({ size });

  return (
    <Box className={startDecoratorNodeClassName}>
      <Calendar className={startDecoratorIconClassName} />
    </Box>
  );
};

function DateTimePickerInput({
  initialValue,
  onSubmit: onSubmitProp,
  disabled,
  readonly,
  showTimePicker,
  format,
  inputValue,
  onBlur,
  onChange,
  onFocus,
  size = 'md',
  rawErrors,
  disabledDates,
}: {
  initialValue?: Date;
  showTimePicker?: boolean;
  onSubmit: (date: Date | undefined) => void;
  disabled?: boolean;
  readonly?: boolean;
  format: DateWidgetType;
  inputValue?: string;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus: (e: FocusEvent<HTMLInputElement>) => void;
  size?: 'sm' | 'md';
  rawErrors?: string[];
  disabledDates?: BaseDatePickerProps['disabled'];
}) {
  const [open, setOpen] = useState(false);

  const onSubmit = useCallback(
    (date: Date | undefined) => {
      onSubmitProp(date);
      setOpen(false);
    },
    [onSubmitProp],
  );

  const onOpenChange = useEventCallback((isOpen: boolean) => {
    setOpen(isOpen);
  });

  const onOpen = useEventCallback(() => {
    onOpenChange(true);
  });

  const onClose = useEventCallback(() => {
    onOpenChange(false);
  });

  return (
    <Popover onOpenChange={onOpenChange} open={open}>
      <ClickAwayListener onClickAway={onClose}>
        <Box className="h-full w-full">
          <Popover.Button disabled={disabled || readonly} stopPropagation>
            <Input
              disabled={disabled}
              error={Boolean(rawErrors?.length)}
              onBlur={onBlur}
              onChange={onChange}
              onClick={onOpen}
              onFocus={onFocus}
              readOnly={readonly}
              size={size}
              slotProps={DATE_TIME_SLOT_PROPS}
              startDecoratorNode={getStartDecoratorNode({ size })}
              type={format === DateWidgetType.Date ? 'date' : 'datetime-local'}
              value={inputValue}
            />
          </Popover.Button>
          {/* // to keep above drawer */}
          <Popover.Content disableClickAway popupClassName="z-listbox">
            {showTimePicker ? (
              <DateTimePicker
                disabled={disabledDates}
                initialFocus={false}
                initialValue={initialValue}
                onSubmit={onSubmit}
              />
            ) : (
              <DatePicker
                disabled={disabledDates}
                initialFocus={false}
                onSubmit={onSubmit}
                value={initialValue}
              />
            )}
          </Popover.Content>
        </Box>
      </ClickAwayListener>
    </Popover>
  );
}

/** The `TextareaWidget` is a widget for rendering input fields as textarea.
 *
 * @param props - The `WidgetProps` for this component
 */
export default function DateTimeWidget(props: WidgetProps) {
  const { id, options, onChange, onBlur, onFocus, schema, disabled, readonly, uiSchema, registry } =
    props;
  const value = props.value as string | undefined;

  const uaOptions = useMemo(() => getUaOptions<DateTimeWidgetUaOptions>(uiSchema), [uiSchema]);

  const uiOptions = useMemo(() => getUiOptions({ uiSchema, registry }), [registry, uiSchema]) as {
    size?: 'sm' | 'md';
  };

  const { format, dateFormat } = useMemo(
    () => getFormatAndDateFormat(schema as DateTypeSchema, uaOptions),
    [schema, uaOptions],
  );

  const disabledDates = useMemo(() => {
    const { before, after } = calculateDateRange(uaOptions.dateTimeAddOns?.dateSelectionLimits);
    const dates: Matcher[] = [];
    if (after) dates.push({ after });
    if (before) dates.push({ before });
    return dates;
  }, [uaOptions.dateTimeAddOns]);

  const { inputValue, datePickerValue, getParsedReturnValue } = useDateInputValue({
    value,
    dateFormat: getDateFormat(format, dateFormat),
    widgetType: format,
    options,
  });

  const _onChange = useCallback(
    ({ target: { value: val } }: ChangeEvent<HTMLInputElement>) => {
      onChange(getParsedReturnValue(val));
    },
    [onChange, getParsedReturnValue],
  );

  const _onBlur = useCallback(
    ({ target: { value: val } }: FocusEvent<HTMLInputElement>) => {
      onBlur(id, getParsedReturnValue(val));
    },
    [getParsedReturnValue, id, onBlur],
  );

  const _onFocus = useCallback(
    ({ target: { value: val } }: FocusEvent<HTMLInputElement>) => {
      onFocus(id, getParsedReturnValue(val));
    },
    [getParsedReturnValue, id, onFocus],
  );

  const onSubmit = useCallback(
    (date?: Date) => {
      onChange(getParsedReturnValue(date?.toISOString()));
    },
    [getParsedReturnValue, onChange],
  );

  return (
    <DateTimePickerInput
      disabled={disabled}
      disabledDates={disabledDates}
      format={format}
      initialValue={datePickerValue}
      inputValue={inputValue}
      onBlur={_onBlur}
      onChange={_onChange}
      onFocus={_onFocus}
      onSubmit={onSubmit}
      rawErrors={props.rawErrors}
      readonly={readonly}
      showTimePicker={format === DateWidgetType.DateTime}
      size={uiOptions.size}
    />
  );
}
