import type { FormContextType, RJSFSchema, StrictRJSFSchema, WidgetProps } from '@rjsf/utils';
import { getTemplate } from '@rjsf/utils';
import type { LookupWidgetTemplateComponentType } from './templates/LookupWidgetTemplate';
import DefaultLookupWidgetTemplate from './templates/LookupWidgetTemplate';

/** The `LookupWidget` component uses the `BaseSelectTemplate`
 *
 * @param props - The `WidgetProps` for this component
 */

export default function LookupWidget<
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = object,
>(props: WidgetProps<T, S, F>) {
  const { options, registry } = props;
  const BaseLookupWidgetTemplate =
    // @ts-expect-error -- BaseSelectTemplate not in template type
    ((getTemplate('LookupWidgetTemplate', registry, options) as
      | LookupWidgetTemplateComponentType
      | undefined) ?? DefaultLookupWidgetTemplate) as (props: WidgetProps<T, S, F>) => JSX.Element;

  return <BaseLookupWidgetTemplate {...props} />;
}
