import type { EmojiItem } from '@tiptap-pro/extension-emoji';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Button } from '../../../components/ui/Button';
import { Panel } from '../../../components/ui/Panel';
import type { EmojiListProps } from '../types';

const EmojiList = forwardRef((props: EmojiListProps, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => setSelectedIndex(0), [props.items]);

  const selectItem = useCallback(
    (index: number) => {
      const item = props.items[index];

      if (item) {
        props.command({ name: item.name });
      }
    },
    [props],
  );

  useImperativeHandle(
    ref,
    () => {
      const scrollIntoView = (index: number) => {
        const item = props.items[index];

        if (item) {
          const node = document.querySelector(`[data-emoji-name="${item.name}"]`);

          if (node) {
            node.scrollIntoView({ block: 'nearest' });
          }
        }
      };

      const upHandler = () => {
        const newIndex = (selectedIndex + props.items.length - 1) % props.items.length;
        setSelectedIndex(newIndex);
        scrollIntoView(newIndex);
      };

      const downHandler = () => {
        const newIndex = (selectedIndex + 1) % props.items.length;
        setSelectedIndex(newIndex);
        scrollIntoView(newIndex);
      };

      const enterHandler = () => {
        selectItem(selectedIndex);
      };

      return {
        onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
          if (event.key === 'ArrowUp') {
            upHandler();
            return true;
          }

          if (event.key === 'ArrowDown') {
            downHandler();
            return true;
          }

          if (event.key === 'Enter') {
            enterHandler();
            return true;
          }

          return false;
        },
      };
    },
    [props, selectedIndex, selectItem],
  );

  const createClickHandler = useCallback((index: number) => () => selectItem(index), [selectItem]);

  if (!props.items.length) {
    return null;
  }

  return (
    <Panel className="max-h-[18rem] max-w-[18rem] overflow-y-auto">
      {props.items.map((item: EmojiItem, index: number) => (
        <Button
          active={index === selectedIndex}
          buttonSize="small"
          className="w-full justify-start"
          data-emoji-name={item.name}
          key={item.name}
          onClick={createClickHandler(index)}
          variant="ghost"
        >
          {item.fallbackImage ? (
            <img alt="emoji" className="h-5 w-5" src={item.fallbackImage} />
          ) : (
            item.emoji
          )}{' '}
          <span className="truncate text-ellipsis">:{item.name}:</span>
        </Button>
      ))}
    </Panel>
  );
});

EmojiList.displayName = 'EmojiList';

export default EmojiList;
