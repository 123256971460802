import SchemaField from './SchemaField';
import ObjectField from './ObjectField';
import MultiSelectField from './MultiSelectField';

const fields = {
  SchemaField,
  MultiSelectField,
  ObjectField,
};

export type DefaultFields = typeof fields;

export default fields;
