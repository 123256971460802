/* eslint-disable @typescript-eslint/no-unsafe-assignment -- needed */
import type { FocusEvent, HTMLAttributes, SyntheticEvent } from 'react';
import { useCallback, useMemo } from 'react';
import { clsx } from 'clsx';
import type {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderOptionState,
  AutocompleteValue,
  FilterOptionsState,
} from '@unifyapps/ui/components/Autocomplete';
import { AutocompleteOption, createFilterOptions } from '@unifyapps/ui/components/Autocomplete';
import type {
  FormContextType,
  Registry,
  RJSFSchema,
  StrictRJSFSchema,
  WidgetProps,
} from '@rjsf/utils';
import type { LookupByQueryRequest } from '@unifyapps/network/generated/models/lookupByQueryRequest';
import { hasFieldError } from '@unifyapps/form/utils/error';
import { getUiOptions } from '@unifyapps/form/utils/getUiOptions';
import { useGetSizeVariantClassName } from '@unifyapps/form/hooks/useGetSizeVariantClassName';
import getUaOptions from '@unifyapps/form/utils/getUaOptions';
import { ListBoxHeader } from '../../../components/AutocompleteListBox';
import { Autocomplete } from '../../../components/Autocomplete';
import { iconSizeClassName } from '../../../components/Autocomplete/styles';
import useAccessors from '../hooks/useAccessors';
import type { BaseLookupExtendedProps, LookupOptionType, LookupWidgetUISchema } from '../types';
import useCreatableOptions from '../hooks/useCreatableOptions';

function BaseLookupWidgetTemplate<
  T,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = object,
>(props: WidgetProps<T, S, F> & BaseLookupExtendedProps) {
  const {
    onChange,
    id,
    className,
    onBlur,
    onFocus,
    placeholder,
    resolvedValue,
    lookupWidgetOptions,
    renderOptionLabel,
    startDecoratorNode,
    disabled,
    readonly,
    slots,
    slotProps,
    onInputChange,
    input,
    filterOptions: propsFilterOptions,
    disableClearable,
    renderTags,
    lookupRequest,
    pageSize,
    registry,
    fetchNextPage,
    variant,
    loading,
  } = props;
  // when value is undefined joyui will assume the component to be uncontrolled and when it is null joyui will assume the component to be controlled
  //https://github.com/mui/material-ui/blob/8aef9ad3fcf89d8061b8beeb04aba18be0510cce/packages/mui-utils/src/useControlled/useControlled.js#L7
  const value = (props.value || null) as string | string[] | undefined;
  const uiSchema = props.uiSchema as LookupWidgetUISchema<LookupByQueryRequest>;
  const multiple = Boolean(uiSchema['ui:options'].multiple);
  const { getOptionLabel, getOptionKey, getOptionIcon, getOptionDescription } =
    useAccessors(uiSchema);
  const uiOptions = useMemo(() => getUiOptions({ uiSchema, registry }), [registry, uiSchema]);
  const uaOptions = useMemo(
    () =>
      getUaOptions<{ disabled?: boolean; enableSelectAll?: boolean }>(
        uiSchema,
        registry.globalUiOptions,
      ),
    [registry, uiSchema],
  );

  const updatedSlots = useMemo(() => {
    return {
      ...slots,
      ...(uaOptions.enableSelectAll && multiple ? { listboxheader: ListBoxHeader } : {}),
    };
  }, [multiple, slots, uaOptions.enableSelectAll]);

  const createable = uiOptions.createable as boolean | undefined;
  const initialValueIndex = (uiSchema['ua:defaultValue'] as Record<string, number> | undefined)
    ?.index;
  const { updateCreateValues, valuesToAppend, getOptionsWithCreatedValues } = useCreatableOptions({
    getOptionKey,
    createable,
  });

  const filterOptions = useMemo(
    () =>
      propsFilterOptions ??
      createFilterOptions<LookupOptionType>({
        matchFrom: 'start',
        stringify: getOptionLabel,
        ignoreCase: true,
      }),
    [getOptionLabel, propsFilterOptions],
  );

  const { size, classes, optionClassName } = useMemo(
    () =>
      getUiOptions({ uiSchema, registry }) as {
        size?: 'sm' | 'md';
        classes?: {
          listbox: string;
        };
        optionClassName?: string;
      },
    [registry, uiSchema],
  );

  const optionIconSize = iconSizeClassName({ size });
  const sizeVariantClassName = useGetSizeVariantClassName(uiSchema, props.registry as Registry);

  const isOptionEqualToValue = useCallback(
    (option: LookupOptionType, val?: LookupOptionType) =>
      getOptionKey(option) === getOptionKey(val),
    [getOptionKey],
  );

  const _onChange = useCallback(
    (
      _: SyntheticEvent,
      newValue: AutocompleteValue<LookupOptionType, boolean, false, false>,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<LookupOptionType & { inputValue?: string }>,
    ) => {
      updateCreateValues(newValue, reason, details);
      onChange(
        Array.isArray(newValue) ? newValue.map((it) => getOptionKey(it)) : getOptionKey(newValue),
      );
    },
    [getOptionKey, onChange, updateCreateValues],
  );

  const filterOptionsWithCreatedValues = useCallback(
    (options: LookupOptionType[], state: FilterOptionsState<LookupOptionType>) => {
      const filteredOptions = [...filterOptions(options, state)];
      return getOptionsWithCreatedValues(filteredOptions, state.inputValue);
    },
    [filterOptions, getOptionsWithCreatedValues],
  );

  const _onBlur = useCallback(
    (blurEvent: FocusEvent<HTMLInputElement>) => onBlur(id, blurEvent.target.value),
    [id, onBlur],
  );
  const _onFocus = useCallback(
    (focusEvent: FocusEvent<HTMLInputElement>) => onFocus(id, focusEvent.target.value),
    [id, onFocus],
  );

  const renderOption = useCallback(
    (
      optionProps: Omit<HTMLAttributes<HTMLLIElement>, 'color'>,
      option: LookupOptionType | undefined,
      state: AutocompleteRenderOptionState,
    ) => {
      if (!option) {
        return;
      }

      const iconUrl = getOptionIcon(option);

      return (
        <AutocompleteOption
          getOptionDescription={getOptionDescription}
          getOptionLabel={getOptionLabel}
          key={getOptionKey(option)}
          multiple={multiple}
          option={option}
          optionProps={optionProps}
          renderOptionLabel={renderOptionLabel}
          size={size}
          startDecoratorComponent={
            iconUrl ? (
              // eslint-disable-next-line react-perf/jsx-no-jsx-as-prop -- is okay
              <img alt={getOptionLabel(option)} className={optionIconSize} src={iconUrl} />
            ) : null
          }
          state={state}
        />
      );
    },
    [
      getOptionIcon,
      getOptionLabel,
      getOptionDescription,
      getOptionKey,
      multiple,
      renderOptionLabel,
      size,
      optionIconSize,
    ],
  );

  const showPlaceholder = !value || (Array.isArray(value) && value.length === 0);

  return (
    <Autocomplete
      className={clsx(
        className,
        sizeVariantClassName,
        // this class is used to highlight when input is focused from ErrorPanel
        'group-[.highlight-widget]:ring-brand',
      )}
      classes={classes}
      disableClearable={disableClearable}
      disabled={disabled || uaOptions.disabled}
      error={hasFieldError(props.rawErrors, props.hideError)}
      fetchNextPage={fetchNextPage}
      filterOptions={filterOptionsWithCreatedValues}
      freeSolo={false}
      getOptionIcon={getOptionIcon}
      getOptionKey={getOptionKey}
      getOptionLabel={getOptionLabel}
      id={id}
      initialValueIndex={initialValueIndex}
      input={input}
      isOptionEqualToValue={isOptionEqualToValue}
      loading={loading}
      lookupRequest={lookupRequest}
      multiple={multiple}
      onBlur={_onBlur}
      onChange={_onChange}
      onFocus={_onFocus}
      onInputChange={onInputChange}
      optionClassName={optionClassName}
      options={lookupWidgetOptions}
      pageSize={pageSize}
      placeholder={showPlaceholder ? placeholder : ''}
      readOnly={readonly || Boolean(uiOptions.readonly)}
      refetchOnOpen
      renderOption={renderOption}
      renderOptionLabel={renderOptionLabel}
      renderTags={renderTags}
      resolvedValue={resolvedValue}
      size={size}
      slotProps={slotProps}
      slots={updatedSlots}
      startDecoratorNode={startDecoratorNode}
      value={value}
      valuesToAppend={valuesToAppend}
      variant={variant}
    />
  );
}

export default BaseLookupWidgetTemplate;
