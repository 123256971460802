import { cva } from 'class-variance-authority';
import type { ButtonGroupSizes } from './types';

export const buttonGroupButtonSizeVariants = cva('!border-none', {
  variants: {
    size: {
      xs: 'px-sm py-xxs gap-xs max-h-6 text-xxxs',
      sm: 'px-lg py-xs gap-sm max-h-8 text-sm',
      md: 'px-xl py-md gap-sm max-h-9 text-sm',
    } satisfies Record<ButtonGroupSizes, string>,
    selected: {
      true: 'bg-primary text-primary !rounded-md shadow-sm',
      false: 'bg-inherit text-primary !rounded-md',
    },
    disabled: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      selected: false,
      disabled: false,
      class: ['hover:bg-primary'],
    },
    {
      selected: true,
      disabled: false,
      class: ['hover:bg-primary'],
    },
    {
      selected: false,
      disabled: true,
      class: ['hover:bg-secondary'],
    },
    {
      selected: true,
      disabled: true,
      class: ['hover:bg-primary'],
    },
  ],
});

export const buttonGroupIconButtonSizeVariants = cva('', {
  variants: {
    size: {
      xs: 'px-sm py-xxs min-h-6 rounded-xs',
      sm: 'px-md py-xs min-h-7 rounded-md',
      md: 'px-lg py-md min-h-9 rounded-md',
    } satisfies Record<ButtonGroupSizes, string>,
    selected: {
      true: 'bg-primary border-none text-secondary shadow-sm hover:bg-primary',
      false: 'bg-inherit text-tertiary border-none hover:bg-primary',
    },
  },
});
