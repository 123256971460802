import {
  descriptionId,
  getTemplate,
  labelValue,
  schemaRequiresTrueValue,
  type WidgetProps,
} from '@rjsf/utils';
import type { CheckboxProps } from '@unifyapps/ui/components/Checkbox';
import {
  Typography,
  type TypographyColors,
  type TypographyVariants,
  type TypographyWeight,
} from '@unifyapps/ui/components/Typography';
import type { FocusEvent } from 'react';
import { useCallback, useMemo } from 'react';
import getUaOptions from '../../utils/getUaOptions';
import { getUiOptions } from '../../utils/getUiOptions';

/** The `CheckBoxWidget` is a widget for rendering boolean properties.
 *  It is typically used to represent a boolean.
 *
 * @param props - The `WidgetProps` for this component
 */

const labelRenderer = ({
  labelSlotProps,
  hideLabel,
  label,
}: {
  labelSlotProps?: {
    variant: TypographyVariants;
    color: TypographyColors;
    weight: TypographyWeight;
  };
  hideLabel?: boolean;
  label: string;
}) => {
  return (
    <Typography
      color={labelSlotProps?.color ?? 'text-secondary'}
      variant={labelSlotProps?.variant ?? 'text-sm'}
      weight={labelSlotProps?.weight ?? 'medium'}
    >
      {labelValue(label, hideLabel, false)}
    </Typography>
  );
};

function useCheckboxWidget(props: WidgetProps) {
  const {
    schema,
    id,
    disabled,
    readonly,
    label = '',
    hideLabel,
    onChange,
    onBlur,
    onFocus,
    registry,
    options,
    uiSchema,
  } = props;

  const value = props.value as string;

  const DescriptionFieldTemplate = getTemplate('DescriptionFieldTemplate', registry, options);
  // Because an unchecked checkbox will cause html5 validation to fail, only add
  // the "required" attribute if the field value must be "true", due to the
  // "const" or "enum" keywords
  const required = schemaRequiresTrueValue(schema);

  const uaOptions = getUaOptions(uiSchema) as { hideLabel?: boolean; className?: string };

  const uiOptions = getUiOptions({ uiSchema, registry }) as {
    slots?: {
      label?: {
        variant: TypographyVariants;
        color: TypographyColors;
        weight: TypographyWeight;
      };
    };
  };

  const { slots } = uiOptions;

  const _onChange = useCallback<NonNullable<CheckboxProps['onChange']>>(
    (event) => onChange(event.target.checked),
    [onChange],
  );
  const _onBlur = useCallback(
    ({ target: { value: val } }: FocusEvent<HTMLButtonElement>) => onBlur(id, val),
    [id, onBlur],
  );
  const _onFocus = useCallback(
    ({ target: { value: val } }: FocusEvent<HTMLButtonElement>) => onFocus(id, val),
    [id, onFocus],
  );
  const description = options.description ?? schema.description;

  const _label = useMemo(() => {
    return uaOptions.hideLabel
      ? null
      : labelRenderer({
          labelSlotProps: slots?.label,
          hideLabel,
          label,
        });
  }, [hideLabel, label, slots?.label, uaOptions.hideLabel]);

  const _description = useMemo(() => {
    return !hideLabel && description ? (
      <DescriptionFieldTemplate
        description={description}
        id={descriptionId(id)}
        registry={registry}
        schema={schema}
        uiSchema={uiSchema}
      />
    ) : null;
  }, [DescriptionFieldTemplate, description, hideLabel, id, registry, schema, uiSchema]);

  return {
    _description,
    _label,
    _onChange,
    _onBlur,
    _onFocus,
    disabled,
    required,
    readonly,
    value,
    uaOptions,
  };
}

export default useCheckboxWidget;
