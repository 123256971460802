// ref: https://github.com/rjsf-team/react-jsonschema-form/blob/a7b25e8a1803149eccc4fd175ab6412d17cdf77c/packages/core/src/components/widgets/FileWidget.tsx
import type { ChangeEvent } from 'react';
import { Fragment, useCallback, useMemo } from 'react';
import type { WidgetProps } from '@rjsf/utils';
import { dataURItoBlob } from '@rjsf/utils';
import SvgImage from '@unifyapps/icons/colored/file/Image';
import SvgPdf from '@unifyapps/icons/colored/file/Pdf';
import File04 from '@unifyapps/icons/outline/File04';
import { Typography } from '@unifyapps/ui/components/Typography';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import SvgTrash01 from '@unifyapps/icons/outline/Trash01';
import isEmpty from 'lodash/isEmpty';
import Stack from '@unifyapps/ui/_components/Stack';
import { clsx } from 'clsx';
import { FeaturedIcon } from '@unifyapps/ui/components/FeaturedIcon';
import { Link } from '@unifyapps/ui/components/Link';
import UploadCloud02 from '@unifyapps/icons/outline/UploadCloud02';
import { useTranslation } from '@unifyapps/i18n/client';
import { filesize } from 'filesize';

type FileInfoType = {
  dataURL?: string | null;
  name: string;
  size: number;
  type: string;
};

interface SelectFileProps extends WidgetProps {
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const getIconFromFileType = (fileType: string) => {
  if (fileType.includes('image')) {
    return SvgImage;
  }

  if (fileType.includes('pdf')) {
    return SvgPdf;
  }

  return File04;
};

function addNameToDataURL(dataURL: string | null, name: string) {
  if (dataURL === null) {
    return null;
  }
  return dataURL.replace(';base64', `;name=${encodeURIComponent(name)};base64`);
}

function processFile(file: File): Promise<FileInfoType> {
  const { name, size, type } = file;
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onerror = reject;
    reader.onload = (event) => {
      if (typeof event.target?.result === 'string') {
        resolve({
          dataURL: addNameToDataURL(event.target.result, name),
          name,
          size,
          type,
        });
      } else {
        resolve({
          dataURL: null,
          name,
          size,
          type,
        });
      }
    };
    reader.readAsDataURL(file);
  });
}

function processFiles(files: FileList) {
  return Promise.all(Array.from(files).map(processFile));
}

function FilesInfo({
  filesInfo,
  onRemove,
}: {
  filesInfo: FileInfoType[];
  preview?: boolean;
  onRemove: (index: number) => void;
}) {
  if (filesInfo.length === 0) {
    return null;
  }

  return (
    <Stack
      alignItems="center"
      className="gap-x-lg p-lg border-secondary relative rounded-xl border"
      direction="row"
    >
      {filesInfo.map((fileInfo, key) => {
        const { name, size, type, dataURL } = fileInfo;
        const Icon = getIconFromFileType(type);
        return (
          <Fragment key={key}>
            <Icon className="h-10 w-10" />
            <Stack className="flex-1">
              <a download={name} href={dataURL ?? ''} rel="noopener" target="_blank">
                <Typography color="text-secondary" variant="text-sm" weight="medium">
                  {name}
                </Typography>
              </a>
              <Typography color="text-tertiary" variant="text-sm">
                {size ? filesize(size) : null}
              </Typography>
              <IconButton
                Icon={SvgTrash01}
                className="absolute end-2 top-2"
                color="neutral"
                onClick={() => onRemove(key)}
                size="md"
              />
            </Stack>
          </Fragment>
        );
      })}
    </Stack>
  );
}

function SelectFile(props: SelectFileProps) {
  const { disabled, multiple, handleChange, options, readonly, required } = props;
  const value = props.value as string | string[];
  const { t } = useTranslation(['blocks', 'common']);

  const handleClick = useCallback(() => {
    document.getElementById('file-input')?.click();
  }, []);

  return (
    <>
      <input
        accept={options.accept ? String(options.accept) : undefined}
        disabled={disabled || readonly}
        hidden
        id="file-input"
        multiple={multiple}
        onChange={handleChange}
        required={value ? false : required}
        type="file"
      />
      <Stack
        alignItems="center"
        className={clsx(
          'px-xl py-lg rounded-xl border',
          disabled
            ? 'bg-disabled_subtle border-disabled_subtle cursor-not-allowed'
            : 'border-secondary cursor-pointer',
        )}
        onClick={handleClick}
      >
        <Stack alignItems="center" className="max-w-[320px]" direction="column">
          <FeaturedIcon Icon={UploadCloud02} size="md" />
          <Typography className="mt-lg" color="text-tertiary" variant="text-sm" weight="semi-bold">
            <Stack className="gap-x-1" direction="row">
              <Link color="brand" disabled={disabled}>
                {t('blocks:fileUpload.ClickToUpload')}
              </Link>
            </Stack>
          </Typography>
        </Stack>
      </Stack>
    </>
  );
}

function extractFileInfo(dataURLs: string[]): FileInfoType[] {
  return dataURLs.reduce<FileInfoType[]>((acc, dataURL) => {
    if (!dataURL) {
      return acc;
    }
    try {
      const { blob, name } = dataURItoBlob(dataURL);
      return [
        ...acc,
        {
          dataURL,
          name,
          size: blob.size,
          type: blob.type,
        },
      ];
    } catch (e) {
      // Invalid dataURI, so just ignore it.
      return acc;
    }
  }, []);
}

/**
 *  The `FileWidget` is a widget for rendering file fields.
 *  It is typically used with a string property with data-url format.
 */
function FileWidget(props: WidgetProps) {
  const { multiple, onChange, options } = props;
  const value = props.value as string | string[];

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }
      // Due to variances in themes, dealing with multiple files for the array case now happens one file at a time.
      // This is because we don't pass `multiple` into the `BaseInputTemplate` anymore. Instead, we deal with the single
      // file in each event and concatenate them together ourselves
      processFiles(event.target.files)
        .then((filesInfoEvent) => {
          const newValue = filesInfoEvent.map((fileInfo) => fileInfo.dataURL) as string[];
          if (multiple) {
            onChange(value.concat(newValue[0]));
          } else {
            onChange(newValue[0]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [multiple, value, onChange],
  );

  const filesInfo = useMemo(() => extractFileInfo(Array.isArray(value) ? value : [value]), [value]);
  const rmFile = useCallback(
    (index: number) => {
      if (multiple) {
        const newValue = (value as string[]).filter((_: string, i: number) => i !== index);
        onChange(newValue);
      } else {
        onChange(undefined);
      }
    },
    [multiple, value, onChange],
  );
  return (
    <>
      {isEmpty(filesInfo) ? (
        <SelectFile {...props} handleChange={handleChange} />
      ) : (
        <FilesInfo filesInfo={filesInfo} onRemove={rmFile} preview={options.filePreview} />
      )}
    </>
  );
}

export default FileWidget;
