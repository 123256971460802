import { cva } from 'class-variance-authority';

export const iconSizeClassName = cva('', {
  variants: {
    size: {
      sm: 'size-[18px]',
      md: 'size-[20px]',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});
