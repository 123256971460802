// lisp like cond function
import _last from 'lodash/last';
import createMemo from 'react-use/lib/factory/createMemo';

type Test = boolean | undefined | (() => boolean);
type Condition<T> = [Test, T] | T;
type Conditions<T> = Condition<T>[];

const passes = <T>(condition: Condition<T>): T | boolean => {
  if (Array.isArray(condition)) {
    const [test] = condition;
    return ((test instanceof Function && test.apply(null)) ||
      (Boolean(test) && !(test instanceof Function))) as boolean;
  }

  return condition;
};

const value = <T>(condition: Condition<T>): T => {
  if (Array.isArray(condition)) {
    const [, val] = condition;
    return val;
  }

  return condition;
};

export const cond = <T>(...conditions: Conditions<T>): T =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unsafe-call -- last condition is always defined
  value(conditions.find((condition) => passes(condition))) ?? (value(_last(conditions))! as T);

const useCond = createMemo(cond);

export default useCond;
