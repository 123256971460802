import { clsx } from 'clsx';
import Stack from '@unifyapps/ui/_components/Stack';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import Triangle from '@unifyapps/icons/outline/Triangle';
import {
  descriptionId,
  getTemplate,
  getUiOptions,
  type ObjectFieldTemplateProps,
  titleId,
} from '@rjsf/utils';
import { Box } from '@unifyapps/ui/components/Box';
import { Divider } from '@unifyapps/ui/components/Divider';
import ChevronUp from '@unifyapps/icons/outline/ChevronUp';
import { cva } from 'class-variance-authority';
import { getIconFromRegistry } from '@unifyapps/icons/utils/registry';
import getUaOptions from '../../../utils/getUaOptions';

type UaOptions = {
  boldTitle?: boolean;
  fieldHeaderClassname?: string;
  icon?: {
    name?: 'Triangle' | 'Chevron';
    position?: 'left' | 'right';
    alignment?: 'start' | 'space-between';
  };
};

/**
 * Note: temporary change for UX testing in QA
 * After testing:
 * 1. Chevron should be the default with position: right and alignment: space-between
 * 2. uaOptions for icon should be removed
 */
const titleContainerVariants = cva('', {
  variants: {
    alignment: {
      'space-between': 'justify-between',
      start: 'justify-start',
    },
  },
});

const iconVariants = cva('', {
  variants: {
    iconName: {
      Triangle: '[&_path]:fill-current [&_svg]:!size-2',
      Chevron: '',
    },
  },
});

const ICON_NAME_TO_ICON = {
  Triangle,
  Chevron: ChevronUp,
};

export type ObjectFieldHeaderProps = Pick<
  ObjectFieldTemplateProps,
  'registry' | 'uiSchema' | 'schema' | 'idSchema' | 'title' | 'description' | 'required'
> & {
  isCollapsible: boolean;
  collapsed: boolean;
  toggleCollapsed: () => void;
};

export default function ObjectFieldHeader(props: ObjectFieldHeaderProps) {
  const {
    isCollapsible,
    toggleCollapsed,
    collapsed,
    registry,
    uiSchema,
    idSchema,
    schema,
    title,
    required,
    description,
  } = props;

  const uiOptions = getUiOptions(uiSchema, registry.globalUiOptions);
  const uaOptions = getUaOptions<UaOptions>(uiSchema);
  const { icon: { name: iconName = 'Triangle', position = 'left', alignment = 'start' } = {} } =
    uaOptions;
  const TitleFieldTemplate = getTemplate('TitleFieldTemplate', registry, uiOptions);
  const DescriptionFieldTemplate = getTemplate('DescriptionFieldTemplate', registry, uiOptions);
  const HeaderIcon = uiOptions.icon ? getIconFromRegistry(uiOptions.icon as string) : null;

  const TitleAndDescription = (
    <>
      <Stack
        alignContent="center"
        className={clsx('gap-x-xxs', titleContainerVariants({ alignment }), {
          'flex-row': position === 'left',
          'flex-row-reverse': position === 'right',
        })}
        direction="row"
      >
        {isCollapsible ? (
          <IconButton
            Icon={ICON_NAME_TO_ICON[iconName]}
            className={clsx(
              '!p-xs !text-fg-secondary !size-5 duration-100 hover:!bg-transparent [&_svg]:transition',
              {
                '[&_svg]:rotate-90': collapsed,
                '[&_svg]:rotate-180': !collapsed,
              },
              iconVariants({ iconName }),
            )}
            color="neutral"
            data-id={`${idSchema.$id}-toggle-button`}
            onClick={toggleCollapsed}
            size="sm"
            variant="soft"
          />
        ) : null}
        {title ? (
          <TitleFieldTemplate
            id={titleId(idSchema)}
            registry={registry}
            required={required}
            schema={schema}
            title={title}
            uiSchema={{ ...uiSchema, bold: uaOptions.boldTitle }}
          />
        ) : null}
      </Stack>
      {description ? (
        <Box className="pb-xs">
          <DescriptionFieldTemplate
            description={description}
            id={descriptionId(idSchema)}
            registry={registry}
            schema={schema}
            uiSchema={uiSchema}
          />
        </Box>
      ) : null}
    </>
  );

  return (
    <Stack
      className={clsx(
        'gap-y-xxs object-field-header',
        { 'cursor-pointer': isCollapsible },
        uaOptions.fieldHeaderClassname,
      )}
      direction="column"
      onClick={() => isCollapsible && toggleCollapsed()}
    >
      {HeaderIcon ? (
        <Stack alignItems="center" className="gap-x-md" direction="row">
          <Stack
            alignItems="center"
            className="bg-brand-secondary h-8 w-8 rounded-sm"
            justifyContent="center"
          >
            <HeaderIcon className="text-fg-brand-primary h-5 w-5" />
          </Stack>
          <Stack direction="column" justifyContent="center">
            {TitleAndDescription}
          </Stack>
        </Stack>
      ) : (
        TitleAndDescription
      )}
      {uaOptions.showDivider ? <Divider className={uaOptions.dividerClassname} /> : null}
    </Stack>
  );
}
