import React from 'react';
import type { ArrayFieldDescriptionProps } from '@rjsf/utils';
import { descriptionId } from '@rjsf/utils';
import { Typography } from '@unifyapps/ui/components/Typography';

function ArrayFieldDescriptionTemplate(props: ArrayFieldDescriptionProps) {
  const { description, idSchema } = props;
  const id = descriptionId(idSchema);

  if (!description) {
    return null;
  }

  return (
    <Typography color="text-tertiary" id={id} variant="text-xs">
      {description}
    </Typography>
  );
}

export default ArrayFieldDescriptionTemplate;
