import { optionsList } from '@rjsf/utils';
import type { WidgetProps } from '@rjsf/utils';
import type { JSONSchema7 } from 'json-schema';
import FormControl from '@unifyapps/ui/_components/Form/FormControl';
import SelectWidget from '../SelectWidget';
import TitleFieldTemplate from '../../templates/TitleFieldTemplate';

// custom widget since rjsf does not render template for multi select
function MultiSelectWidget(props: WidgetProps) {
  const {
    required,
    rawErrors,
    formData: items = [],
    schema,
    registry,
    options,
    placeholder,
    label,
    uiSchema,
  } = props;

  const itemsSchema = registry.schemaUtils.retrieveSchema(schema.items as JSONSchema7, items);
  const enumOptions = optionsList(itemsSchema);

  return (
    <FormControl className="gap-y-sm flex" error={Boolean(rawErrors?.length)} required={required}>
      <TitleFieldTemplate
        id="title"
        registry={registry}
        required={required}
        schema={schema}
        title={label}
        uiSchema={uiSchema}
      />
      <SelectWidget
        {...props}
        options={{ ...options, enumOptions }}
        placeholder={options.placeholder ?? placeholder}
      />
    </FormControl>
  );
}

export default MultiSelectWidget;
