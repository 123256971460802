import React, { useCallback, type FocusEvent, useMemo, memo } from 'react';
import type { UiSchema, WidgetProps } from '@rjsf/utils';
import type { SelectProps } from '@unifyapps/ui/components/Select';
import { Select } from '@unifyapps/ui/components/Select';
import property from 'lodash/property';
import { getIconFromRegistry } from '@unifyapps/icons/utils/registry';
import { FeaturedIcon } from '@unifyapps/ui/components/FeaturedIcon';
import { Box } from '@unifyapps/ui/components/Box';
import type {
  AutocompleteRenderGroupParams,
  AutocompleteSlots,
} from '@unifyapps/ui/components/Autocomplete';
import { clsx } from 'clsx';
import type { PopoverContentProps } from '@unifyapps/ui/components/Popover/PopoverContent/types';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { useGetSizeVariantClassName } from '../../../hooks/useGetSizeVariantClassName';
import { getUiOptions } from '../../../utils/getUiOptions';
import getUaOptions from '../../../utils/getUaOptions';
import { Option } from '../components/Option';
import type { SelectOptionType, SelectWidgetUISchema } from '../types';
import { getEndDecoratorRenderer } from '../utils/getEndDecoratorRenderer';

export type SelectTemplateProps = WidgetProps & {
  slots?: AutocompleteSlots;
  renderOptionLabel?: (props: { option?: SelectOptionType }) => React.ReactNode;
  disableClearable?: boolean;
  loading?: boolean;
  renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode;
  groupBy?: (option: SelectOptionType | undefined) => string;
  listBoxPlacement?: PopoverContentProps['placement'];
  startDecoratorNode?: React.ReactNode;
  variant?: 'outlined' | 'ghost' | 'solid';
  error?: boolean;
  filterOptions?: SelectProps<
    SelectOptionType | undefined,
    boolean,
    boolean,
    unknown
  >['filterOptions'];
};

const EMPTY_ARRAY = Object.freeze([]) as unknown as unknown[];

const isOptionEqualToValue = (option?: SelectOptionType, value?: SelectOptionType) =>
  option?.value === value?.value;

const valueAccessor = (option: SelectOptionType | undefined) => option?.value;

function computeIsValueEmpty(value: unknown, multiple?: boolean) {
  // '', [], undefined should return true
  let isEmpty = Array.isArray(value) ? value.length === 0 : value === undefined || value === '';

  // string is considered empty if multiple is true, array is considered empty if multiple is false
  // this is needed, otherwise the form breaks when incorrect values are passed using dynamic values from DefaultValueWidget
  isEmpty =
    isEmpty || (multiple && typeof value === 'string') || (!multiple && Array.isArray(value));

  return isEmpty;
}

/** The `SelectWidget` is a widget for rendering dropdowns.
 *  It is typically used with string properties constrained with enum options.
 *
 * @param props - The `WidgetProps` for this component
 */

type SelectTemplateViewProps = Pick<
  SelectTemplateProps,
  | 'id'
  | 'disabled'
  | 'placeholder'
  | 'readonly'
  | 'value'
  | 'slots'
  | 'renderOptionLabel'
  | 'disableClearable'
  | 'groupBy'
  | 'listBoxPlacement'
  | 'startDecoratorNode'
  | 'multiple'
  | 'renderGroup'
  | 'onChange'
  | 'filterOptions'
> & {
  className: string;
  classes: { listbox: string } | undefined;
  size?: 'md' | 'sm';
  optionClassName?: string;
  options: SelectOptionType[];
  renderOptionLabel:
    | ((props: { option?: SelectOptionType | undefined }) => React.ReactNode)
    | (({ option }: { option?: SelectOptionType }) => React.JSX.Element | null);
  onBlur: (blurEvent: FocusEvent<HTMLInputElement>) => void;
  onFocus: (focusEvent: FocusEvent<HTMLInputElement>) => void;
  disableSearch?: boolean;
  variant?: 'outlined' | 'ghost' | 'solid';
  error?: boolean;
  loading?: boolean;
};

function SelectTemplateView({
  className,
  classes,
  disableClearable,
  disabled,
  groupBy,
  id,
  error,
  listBoxPlacement,
  slots,
  value,
  readonly,
  renderOptionLabel,
  multiple,
  onBlur,
  onFocus,
  optionClassName,
  options,
  placeholder,
  renderGroup,
  size,
  startDecoratorNode,
  onChange,
  disableSearch,
  variant = 'outlined',
  filterOptions,
  loading,
}: SelectTemplateViewProps) {
  return (
    <Select<SelectOptionType | undefined, boolean, boolean, unknown>
      // {...textFieldProps.SelectProps}
      className={className}
      classes={classes}
      disableClearable={disableClearable}
      disableSearch={disableSearch}
      disabled={disabled}
      error={error}
      filterOptions={filterOptions}
      getOptionLabel={property('label')}
      groupBy={groupBy}
      id={id}
      isOptionEqualToValue={isOptionEqualToValue}
      listBoxPlacement={listBoxPlacement}
      loading={loading}
      multiple={multiple}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      optionClassName={optionClassName}
      options={options}
      placeholder={placeholder}
      readOnly={readonly}
      renderGroup={renderGroup}
      renderOptionLabel={renderOptionLabel}
      size={size}
      slots={slots}
      startDecoratorNode={startDecoratorNode}
      value={value as unknown}
      valueAccessor={valueAccessor}
      variant={variant}
    />
  );
}

const MemoizedSelectTemplateView = memo(SelectTemplateView);

export type SelectTemplateComponentType = (props: SelectTemplateProps) => React.ReactNode;

export function SelectTemplate(props: SelectTemplateProps) {
  const {
    id,
    error,
    // name, // remove this from textFieldProps
    options: _options,
    disabled,
    placeholder,
    readonly,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- value is a valid prop
    value: _value,
    multiple: _multiple,
    onChange,
    onBlur,
    className: _className,
    onFocus,
    slots,
    registry,
    renderOptionLabel: renderOptionLabelProp,
    disableClearable: _disableClearable = true,
    renderGroup,
    groupBy,
    listBoxPlacement,
    startDecoratorNode: _startDecoratorNode,
    variant,
    filterOptions,
    loading,
    // ...textFieldProps
  } = props;

  const uiSchema = props.uiSchema as SelectWidgetUISchema | undefined;

  const uiOptions = useMemo(
    () => getUiOptions({ uiSchema: uiSchema as UiSchema, registry }),
    [registry, uiSchema],
  ) as {
    className?: string;
    size?: 'sm' | 'md';
    classes?: {
      listbox: string;
    };
    'ua:multiple'?: boolean;
    optionClassName?: string;
    'ua:enumIcons'?: string[];
    'ua:enumIconUrls'?: string[];
    'ua:enumGroup'?: string[];
    'ua:classes'?: {
      listbox: string;
    };
    'ua:optionClassName'?: string;
    'ua:enumSlots'?: {
      secondaryLabel?: string;
    }[];
    'ua:hideSelectedIconInInput'?: boolean;
    'ua:searchable'?: boolean;
    'ua:endDecoratorDetails'?: {
      type: 'text';
      valuePath: string;
      textProps?: {
        property: string;
        valuePathInOption: string;
      }[];
    };
  };

  const uaOptions = useMemo(() => getUaOptions(uiSchema, registry), [registry, uiSchema]);

  const enumIcons = uiOptions['ua:enumIcons'];
  const enumIconUrls = uiOptions['ua:enumIconUrls'];
  const enumGroup = uiOptions['ua:enumGroup'];
  const enumSlots = uiOptions['ua:enumSlots'];
  const searchable = uiOptions['ua:searchable'];
  const optionClassName = uiOptions['ua:optionClassName'];
  const endDecoratorDetails = uiOptions['ua:endDecoratorDetails'];
  const hideSelectedIconInInput = uiOptions['ua:hideSelectedIconInInput'];
  const multiple = _multiple ?? uiOptions['ua:multiple'];

  const endDecoratorRenderer = useMemo(
    () => (endDecoratorDetails ? getEndDecoratorRenderer(endDecoratorDetails) : undefined),
    [endDecoratorDetails],
  );

  const { enumOptions, enumDisabled } = _options;

  const isEmpty = computeIsValueEmpty(_value, multiple);

  const _onBlur = useEventCallback((blurEvent) =>
    onBlur(id, (blurEvent as FocusEvent<HTMLInputElement>).target.value),
  );
  const _onFocus = useEventCallback((focusEvent) =>
    onFocus(id, (focusEvent as FocusEvent<HTMLInputElement>).target.value),
  );

  const selectOptions = useMemo(() => {
    return enumOptions?.map((opt, index) => {
      return {
        value: opt.value as unknown,
        label: opt.label,
        ...(enumIcons?.[index] && getIconFromRegistry(enumIcons[index])
          ? { Icon: getIconFromRegistry(enumIcons[index]) }
          : {}),
        ...(enumIconUrls?.[index] ? { iconUrl: enumIconUrls[index] } : {}),
        isDisabled: Array.isArray(enumDisabled) && enumDisabled.includes(opt.value as string),
        groupId: enumGroup?.[index],
        ...enumSlots?.[index],
      };
    });
  }, [enumDisabled, enumGroup, enumIconUrls, enumIcons, enumOptions, enumSlots]);

  const selectedValue = useMemo(
    () => selectOptions?.find((option) => option.value === _value),
    [selectOptions, _value],
  );

  const _renderOptionLabel = useCallback(
    ({ option }: { option?: SelectOptionType }) =>
      option ? (
        <Option endDecoratorRenderer={endDecoratorRenderer} option={option} size={uiOptions.size} />
      ) : null,
    [uiOptions.size, endDecoratorRenderer],
  );

  const renderOptionLabel = renderOptionLabelProp ?? _renderOptionLabel;

  const disableClearable = Boolean(_disableClearable) && uaOptions.disableClearable !== false;
  const options = useMemo(
    () => selectOptions?.filter((option): option is SelectOptionType => Boolean(option)) || [],
    [selectOptions],
  );

  const value: unknown = isEmpty ? undefined : (_value as unknown);

  const startDecoratorNode = useMemo(() => {
    if (selectedValue?.Icon && !hideSelectedIconInInput) {
      return (
        <Box className="me-md">
          <FeaturedIcon Icon={selectedValue.Icon} color="white" size="xxs" />
        </Box>
      );
    }

    if (selectedValue?.iconUrl && !hideSelectedIconInInput) {
      return (
        <Box className="me-md">
          <FeaturedIcon alt="" color="white" iconUrl={selectedValue.iconUrl} size="xxs" />
        </Box>
      );
    }

    return _startDecoratorNode;
  }, [_startDecoratorNode, hideSelectedIconInInput, selectedValue?.Icon, selectedValue?.iconUrl]);

  const classes = uiOptions['ua:classes'];
  const sizeVariantClassName = useGetSizeVariantClassName(uiSchema, registry);
  const className = clsx(_className, uiOptions.className, sizeVariantClassName);

  const emptyValue = multiple ? EMPTY_ARRAY : undefined;

  // Let the root component handle the empty value of searchable, only pass what you get in config from here
  const _disableSearch = typeof searchable === 'boolean' ? !searchable : undefined;

  return (
    <MemoizedSelectTemplateView
      className={className}
      classes={classes}
      disableClearable={disableClearable}
      disableSearch={_disableSearch}
      disabled={disabled || uaOptions.disabled}
      error={error}
      filterOptions={filterOptions}
      groupBy={groupBy}
      id={id}
      listBoxPlacement={listBoxPlacement}
      loading={loading}
      multiple={multiple}
      onBlur={_onBlur}
      onChange={onChange}
      onFocus={_onFocus}
      optionClassName={optionClassName}
      options={options}
      placeholder={placeholder}
      readonly={readonly}
      renderGroup={renderGroup}
      renderOptionLabel={renderOptionLabel}
      size={uiOptions.size}
      slots={slots}
      startDecoratorNode={startDecoratorNode}
      value={isEmpty ? emptyValue : value}
      variant={variant}
    />
  );
}
