import _compact from 'lodash/compact';
import { Extension } from '@tiptap/core';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Document } from '@tiptap/extension-document';
import { Text } from '@tiptap/extension-text';
import type { PlaceholderOptions } from '@tiptap/extension-placeholder';
import { Placeholder } from '@tiptap/extension-placeholder';
import { HardBreak } from '@tiptap/extension-hard-break';

export { HardBreak };

export interface BasicKitOptions {
  placeholder?: Partial<PlaceholderOptions>;
  hardBreak?: boolean;
}
export default Extension.create<BasicKitOptions>({
  name: 'basic',

  addExtensions() {
    return _compact([
      Document,
      Paragraph,
      Text,
      this.options.hardBreak !== false ? HardBreak : undefined,
      Placeholder.configure({
        ...this.options.placeholder,
      }),
    ]);
  },
});
