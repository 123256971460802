import type { Ref } from 'react';
import React, { forwardRef } from 'react';
import type { IconButtonProps } from '@rjsf/utils';
import ChevronDown from '@unifyapps/icons/outline/ChevronDown';
import LinkButton from '@unifyapps/ui/_components/LinkButton';

const MoveDownButton = forwardRef(
  (props: IconButtonProps, ref: Ref<HTMLButtonElement> | undefined) => {
    return (
      <LinkButton
        StartDecorator={ChevronDown}
        color="secondary"
        disabled={props.disabled}
        onClick={props.onClick}
        ref={ref}
        size="sm"
      />
    );
  },
);

MoveDownButton.displayName = 'MoveDownButton';

export default MoveDownButton;
