import type { WidgetProps } from '@rjsf/utils';
import { ariaDescribedByIds } from '@rjsf/utils';
import { Checkbox } from '@unifyapps/ui/components/Checkbox';
import { clsx } from 'clsx';
import Stack from '@unifyapps/ui/_components/Stack';
import useCheckboxWidget from './useCheckboxWidget';

export default function CheckboxWidget(props: WidgetProps) {
  const { id } = props;
  const {
    _description,
    _label,
    _onBlur,
    _onChange,
    _onFocus,
    disabled,
    readonly,
    required,
    value,
    uaOptions,
  } = useCheckboxWidget(props);

  return (
    <Stack className="gap-y-xxs flex flex-col">
      <Checkbox
        aria-describedby={ariaDescribedByIds(id)}
        checked={typeof value === 'undefined' ? false : Boolean(value)}
        className={clsx('items-center', uaOptions.className)}
        disabled={disabled}
        id={id}
        label={_label}
        name={id}
        onBlur={_onBlur}
        onChange={_onChange}
        onFocus={_onFocus}
        readOnly={readonly}
        required={required}
        size="sm"
      />
      {_description}
    </Stack>
  );
}
