import type { Ref } from 'react';
import React, { forwardRef } from 'react';
import type { SubmitButtonProps } from '@rjsf/utils';
import { getSubmitButtonOptions } from '@rjsf/utils';
import { Button } from '@unifyapps/ui/components/Button';
import type { UISchemaSubmitButtonOptions } from '@rjsf/utils/src/types';

const SubmitButton = forwardRef(
  (props: SubmitButtonProps, ref: Ref<HTMLButtonElement> | undefined) => {
    const {
      uiSchema,
      // registry: { translateString },
    } = props;
    const { render } = getSubmitButtonOptions(uiSchema) as UISchemaSubmitButtonOptions & {
      render: boolean;
    };

    if (!render) {
      return null;
    }

    return (
      <Button className="mt-[8px]" color="brand" ref={ref} size="md" type="submit" variant="solid">
        {/* {translateString('Submit')} */}
        {/* Check how to translate new entries */}
        Submit
      </Button>
    );
  },
);

SubmitButton.displayName = 'SubmitButton';

export default SubmitButton;
