import { clsx } from 'clsx';
import { forwardRef } from 'react';
import BlockEditor from '@unifyapps/block-editor/components/BlockEditor';
import type { EditorRef } from '@unifyapps/editor/types';

const slashCommands = ['heading1', 'heading2', 'heading3', 'bulletList', 'numberedList', 'table'];

type RichBlocksTextWidgetProps = {
  disabled?: boolean;
  editorClassName?: string;
  placeholder?: string;
  onBlur: (event: FocusEvent) => void;
  onFocus: (event: FocusEvent) => void;
  id: string;
  onChangeHTML: (value: string) => void;
  readonly?: boolean;
  initialContent: string;
};

export const RichBlocksTextWidget = forwardRef<EditorRef, RichBlocksTextWidgetProps>(
  (props, ref) => {
    const {
      placeholder,
      disabled,
      editorClassName,
      initialContent,
      readonly,
      onChangeHTML,
      id,
      onBlur,
      onFocus,
    } = props;

    return (
      <BlockEditor
        className="min-h-24"
        editable={!disabled && !readonly}
        editorContentClassName={clsx(
          'text-md px-7 py-6 outline-0 md:text-sm',
          editorClassName,
          disabled ? 'bg-disabled_subtle' : '',
        )}
        id={id}
        onBlur={onBlur}
        onChange={onChangeHTML}
        onFocus={onFocus}
        placeholder={placeholder}
        ref={ref}
        showCommands={slashCommands}
        value={initialContent}
      />
    );
  },
);

RichBlocksTextWidget.displayName = 'RichBlocksTextWidget';
