import type { FormContextType, RJSFSchema, StrictRJSFSchema, WidgetProps } from '@rjsf/utils';
import { getTemplate } from '@rjsf/utils';
import type { ToggleButtonGroupComponentType } from './templates/ToggleButtonGroupTemplate';
import DefaultToggleButtonGroupTemplate from './templates/ToggleButtonGroupTemplate';

/** The `TogleButtonGroupWidget` component uses the `BaseToggleButtonGroupTemplate`
 *
 * @param props - The `WidgetProps` for this component
 */

export default function ToggleButtonGroupWidget<
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = object,
>(props: WidgetProps<T, S, F>) {
  const { options, registry } = props;
  const BaseToggleButtonGroupTemplate =
    // @ts-expect-error -- BaseToggleButtonGroupTemplate not in template type
    ((getTemplate('ToggleButtonGroupTemplate', registry, options) as
      | ToggleButtonGroupComponentType
      | undefined) ?? DefaultToggleButtonGroupTemplate) as (
      props: WidgetProps<T, S, F>,
    ) => JSX.Element;

  return <BaseToggleButtonGroupTemplate {...props} />;
}
