import React from 'react';
import { Box } from '@unifyapps/ui/components/Box';
import type { UiSchema } from '@rjsf/utils';
import { getTemplate, getUiOptions } from '@rjsf/utils';
import type { JSONSchema7 } from 'json-schema';
import type { Registry } from '@rjsf/utils/lib/types';
import { clsx } from 'clsx';

export default function TitleAndDescription(props: {
  schema: JSONSchema7;
  uiSchema?: UiSchema;
  registry: Registry;
  required?: boolean;
  titleId: string; // generated using titleId(idSchema)
  descriptionId: string; // generated using descriptionId(idSchema)
  name?: string;
  title?: string;
  className?: string;
  hideLabel?: boolean;
}) {
  const {
    titleId,
    descriptionId,
    registry,
    required,
    uiSchema,
    schema,
    name,
    title,
    className,
    hideLabel,
  } = props;
  const uiOptions = getUiOptions(uiSchema, registry.globalUiOptions);

  const resolvedTitle = uiOptions.title ?? schema.title ?? title ?? name;
  const description = uiOptions.description ?? schema.description;

  const TitleFieldTemplate = getTemplate('TitleFieldTemplate', registry, uiOptions);
  const DescriptionFieldTemplate = getTemplate('DescriptionFieldTemplate', registry, uiOptions);
  const _hideLabel = hideLabel || (uiOptions.hideLabel as boolean);

  const showLabel = uiOptions.label !== false && resolvedTitle && !_hideLabel;

  const showDescription = uiOptions.label !== false && description;

  if (!showLabel && !showDescription) return null;

  return (
    <Box className={clsx('gap-y-sm flex flex-col', className)}>
      {showLabel ? (
        <TitleFieldTemplate
          id={titleId}
          registry={registry}
          required={required}
          schema={schema}
          title={resolvedTitle}
          uiSchema={uiSchema}
        />
      ) : null}
      {showDescription ? (
        <DescriptionFieldTemplate
          description={description}
          id={descriptionId}
          registry={registry}
          schema={schema}
          uiSchema={uiSchema}
        />
      ) : null}
    </Box>
  );
}
