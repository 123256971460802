import { useState, useCallback, useMemo } from 'react';
import type { Extensions } from '@tiptap/core';
import { Button } from '../../ui/Button';
import { Icon } from '../../ui/Icon';
import { Surface } from '../../ui/Surface';
import { Toggle } from '../../ui/Toggle';
import { Editor } from '@unifyapps/editor/editors/Editor';
import BasicExtensions from '@unifyapps/editor/extensions/basic';

const URL_VALIDATION_PATTERN = /^(?:\S+):(?:\/\/)?\S+$/;

const validateUrl = (url: string) => {
  // Handles the case when we want to use a variable in url
  if (url.startsWith('{{')) {
    return true;
  }

  /* Handles the case when
   * 1. We want to use a complete valid url
   * 2. A url with variables inside it (implicitly handled as the regex checks presence of https://)
   */
  return URL_VALIDATION_PATTERN.test(url);
};

export type LinkEditorPanelProps = {
  initialUrl?: string;
  initialOpenInNewTab?: boolean;
  onSetLink: (url: string, openInNewTab?: boolean) => void;
  extensions?: Extensions;
};

export const useLinkEditorState = ({
  initialUrl,
  initialOpenInNewTab,
  onSetLink,
}: LinkEditorPanelProps) => {
  const [url, setUrl] = useState(initialUrl || '');
  const [openInNewTab, setOpenInNewTab] = useState(initialOpenInNewTab || false);

  const onChange = useCallback((updatedValue: string) => {
    setUrl(updatedValue);
  }, []);

  const isValidUrl = useMemo(() => validateUrl(url), [url]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (isValidUrl) {
        onSetLink(url, openInNewTab);
      }
    },
    [url, isValidUrl, openInNewTab, onSetLink],
  );

  return {
    url,
    setUrl,
    openInNewTab,
    setOpenInNewTab,
    onChange,
    handleSubmit,
    isValidUrl,
  };
};

export function LinkEditorPanel({
  onSetLink,
  initialOpenInNewTab,
  initialUrl,
  extensions: _extensions,
}: LinkEditorPanelProps) {
  const state = useLinkEditorState({ onSetLink, initialOpenInNewTab, initialUrl });

  const extensions = useMemo(() => {
    return [
      BasicExtensions.configure({ placeholder: { placeholder: 'Enter URL' } }),
      ...(_extensions ? _extensions.filter((ext) => ext.name === 'variable-mention') : []),
    ];
  }, [_extensions]);

  return (
    <Surface className="p-2">
      <form className="flex items-center gap-2" onSubmit={state.handleSubmit}>
        <label className="flex cursor-text items-center gap-2 rounded-lg bg-neutral-100 p-2 dark:bg-neutral-900">
          <Icon className="flex-none text-black dark:text-white" name="Link" />
          <Editor
            className="min-w-[12rem] flex-1 bg-transparent !p-0 text-sm text-black outline-none dark:text-white"
            editable
            extensions={extensions}
            initialContent={state.url}
            onChange={state.onChange}
          />
        </label>
        <Button buttonSize="small" disabled={!state.isValidUrl} type="submit" variant="primary">
          Set Link
        </Button>
      </form>
      <div className="mt-3">
        <label className="flex cursor-pointer select-none items-center justify-start gap-2 text-sm font-semibold text-neutral-500 dark:text-neutral-400">
          Open in new tab
          <Toggle active={state.openInNewTab} onChange={state.setOpenInNewTab} />
        </label>
      </div>
    </Surface>
  );
}
