import { useMemo, useState } from 'react';
import type { FormContextType, RJSFSchema, StrictRJSFSchema, WidgetProps } from '@rjsf/utils';
import { getTemplate } from '@rjsf/utils';
import SvgEyeOff from '@unifyapps/icons/outline/EyeOff';
import SvgEye from '@unifyapps/icons/outline/Eye';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import { Tooltip } from '@unifyapps/ui/components/Tooltip';
import useEventCallback from '@unifyapps/hooks/useEventCallback';

/** The `PasswordWidget` component uses the `BaseInputTemplate` changing the type to `password`.
 *
 * @param props - The `WidgetProps` for this component
 */
export default function PasswordWidget<
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = object,
>(props: WidgetProps<T, S, F>) {
  const { options, registry } = props;
  const BaseInputTemplate = getTemplate<'BaseInputTemplate', T, S, F>(
    'BaseInputTemplate',
    registry,
    options,
  );
  const [showingPassword, setShowingPassword] = useState(false);

  const onTogglePassword = useEventCallback((ev: React.MouseEvent) => {
    ev.stopPropagation();
    setShowingPassword(!showingPassword);
  });

  const endDecoratorNode = useMemo(() => {
    return (
      <Tooltip title={showingPassword ? 'Hide password' : 'Show password'}>
        <IconButton
          Icon={showingPassword ? SvgEyeOff : SvgEye}
          className="-me-2"
          onClick={onTogglePassword}
          size="sm"
        />
      </Tooltip>
    );
  }, [onTogglePassword, showingPassword]);

  return (
    <BaseInputTemplate
      endDecoratorNode={endDecoratorNode}
      type={showingPassword ? 'text' : 'password'}
      {...props}
    />
  );
}
