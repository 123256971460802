import type { RefObject } from 'react';
import {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import type { WidgetProps } from '@rjsf/utils';
import type { RichEditorProps } from '@unifyapps/editor/editors/RichTextEditor';
import { RichTextEditor } from '@unifyapps/editor/editors/RichTextEditor';
import { getUiOptions } from '@rjsf/utils';
import { clsx } from 'clsx';
import type { EditorRef } from '@unifyapps/editor/types';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { isEqual } from 'lodash';
import getUaOptions from '../../utils/getUaOptions';
import { RichBlocksTextWidget } from './components/RichBlocksTextWidget';

const EMPTY_ARRAY = [];

export const RichTextWidget = forwardRef(function RichTextWidget(
  props: WidgetProps & { extensions?: RichEditorProps['extensions'] },
  ref: RefObject<EditorRef | null>,
) {
  const {
    id,
    uiSchema,
    disabled: _disabled,
    onChange,
    onFocus,
    readonly,
    onBlur,
    extensions,
  } = props;
  const value = props.value as string | undefined;
  const [content, setContent] = useState(value || ''); // Controlled state
  const editorRef = useRef<EditorRef>(null);
  useImperativeHandle(ref, () => editorRef.current);
  const uiOptions = useMemo(() => getUiOptions(uiSchema), [uiSchema]) as {
    placeholder?: string;
    disabled?: boolean;
    editorClassName?: string;
  };

  const uaOptions = useMemo(
    () =>
      getUaOptions<{
        addOns?: {
          toolbar?: {
            variant: 'standard' | 'slash';
          };
        };
      }>(uiSchema),
    [uiSchema],
  );

  const toolbarVariant = uaOptions.addOns?.toolbar?.variant ?? 'standard';

  const placeholder = uiOptions.placeholder;
  const disabled = _disabled || uiOptions.disabled;

  const _onBlur = useCallback(
    (event: FocusEvent) => {
      return onBlur(id, event.target && 'value' in event.target ? event.target.value : null);
    },
    [onBlur, id],
  );

  const _onFocus = useCallback(
    (event: FocusEvent) => {
      return onFocus(id, event.target && 'value' in event.target ? event.target.value : null);
    },
    [id, onFocus],
  );

  const handleContentChange = useEventCallback((newContent: string) => {
    setContent(newContent);
    // if we remove all content, tiptap still returns <p></p> as newContent which fails our required validation check
    // so, we need to send undefined when the content is empty
    // https://github.com/ueberdosis/tiptap/issues/154
    onChange(editorRef.current?.isEmpty() ? undefined : newContent);
  });

  useEffect(() => {
    // the value that is coming from the form is different from the content in the editor
    if (editorRef.current && !isEqual(content, value)) {
      const editor = editorRef.current;
      if (value === '') {
        editor.clearContent(); // Clear content if reset to empty string
      } else {
        editor.setContent(value || '', true); // Set new content
      }
    }
  }, [content, value]);

  if (toolbarVariant === 'slash') {
    return (
      <RichBlocksTextWidget
        disabled={disabled}
        editorClassName={uiOptions.editorClassName}
        id={id}
        initialContent={content}
        onBlur={_onBlur}
        onChangeHTML={handleContentChange}
        onFocus={_onFocus}
        placeholder={placeholder}
        readonly={readonly}
        ref={editorRef}
      />
    );
  }

  return (
    <RichTextEditor
      className={clsx('outline-none', uiOptions.editorClassName)}
      editable={!disabled && !readonly}
      extensions={extensions ?? EMPTY_ARRAY}
      id={id}
      initialContent={content}
      onBlur={_onBlur}
      onChangeHTML={handleContentChange}
      onFocus={_onFocus}
      placeholder={placeholder}
      ref={editorRef}
    />
  );
});
