/**
 * Generated by orval 🍺
 * Do not edit manually.
 * api API
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { AddConnectionInputAfterConnectBody } from '../models/addConnectionInputAfterConnectBody';
import type { AddConnectionInputAfterConnectParams } from '../models/addConnectionInputAfterConnectParams';
import type { AuthorizeConnectionParams } from '../models/authorizeConnectionParams';
import type { Connection } from '../models/connection';
import type { ConnectionInput } from '../models/connectionInput';
import type { ConnectorObject } from '../models/connectorObject';
import type { ConnectorObjectsRefreshRequest } from '../models/connectorObjectsRefreshRequest';
import type { CreateConnectionWithInputParams } from '../models/createConnectionWithInputParams';
import type { FetchConnectionsParams } from '../models/fetchConnectionsParams';
import type { FetchConnectorObjectRequest } from '../models/fetchConnectorObjectRequest';
import type { FindLinkedAssetsCount200 } from '../models/findLinkedAssetsCount200';
import type { GetOAuthUrl200 } from '../models/getOAuthUrl200';
import type { GlobalConnectionSettings } from '../models/globalConnectionSettings';
import type { HitsConnection } from '../models/hitsConnection';
import type { TestConnectionParams } from '../models/testConnectionParams';
import type { UpdateConnectionParams } from '../models/updateConnectionParams';
import type { UpdateConnectionWithInputParams } from '../models/updateConnectionWithInputParams';
import { executeFetch } from '../../utils/fetch/fetch';
import type { ErrorType } from '../../utils/fetch/fetch';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const fetchConnections = (
  params?: FetchConnectionsParams,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<HitsConnection>(
    { url: `/api/connection`, method: 'GET', params, signal },
    options,
  );
};

export const getFetchConnectionsQueryKey = (params?: FetchConnectionsParams) => {
  return [`/api/connection`, ...(params ? [params] : [])] as const;
};

export const getFetchConnectionsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchConnections>>,
  TError = ErrorType<unknown>,
>(
  params?: FetchConnectionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchConnections>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFetchConnectionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchConnections>>> = ({ signal }) =>
    fetchConnections(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchConnections>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchConnectionsQueryResult = NonNullable<Awaited<ReturnType<typeof fetchConnections>>>;
export type FetchConnectionsQueryError = ErrorType<unknown>;

export const useFetchConnections = <
  TData = Awaited<ReturnType<typeof fetchConnections>>,
  TError = ErrorType<unknown>,
>(
  params?: FetchConnectionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchConnections>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchConnectionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchFetchConnections = async <
  TData = Awaited<ReturnType<typeof fetchConnections>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params?: FetchConnectionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof fetchConnections>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getFetchConnectionsQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getConnectorObject = (
  fetchConnectorObjectRequest: FetchConnectorObjectRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<ConnectorObject>(
    {
      url: `/api/connection`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: fetchConnectorObjectRequest,
    },
    options,
  );
};

export const getGetConnectorObjectQueryKey = (
  fetchConnectorObjectRequest: FetchConnectorObjectRequest,
) => {
  return [`/api/connection`, fetchConnectorObjectRequest] as const;
};

export const getGetConnectorObjectQueryOptions = <
  TData = Awaited<ReturnType<typeof getConnectorObject>>,
  TError = ErrorType<unknown>,
>(
  fetchConnectorObjectRequest: FetchConnectorObjectRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getConnectorObject>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetConnectorObjectQueryKey(fetchConnectorObjectRequest);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectorObject>>> = () =>
    getConnectorObject(fetchConnectorObjectRequest, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getConnectorObject>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetConnectorObjectQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConnectorObject>>
>;
export type GetConnectorObjectQueryError = ErrorType<unknown>;

export const useGetConnectorObject = <
  TData = Awaited<ReturnType<typeof getConnectorObject>>,
  TError = ErrorType<unknown>,
>(
  fetchConnectorObjectRequest: FetchConnectorObjectRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getConnectorObject>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetConnectorObjectQueryOptions(fetchConnectorObjectRequest, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetConnectorObject = async <
  TData = Awaited<ReturnType<typeof getConnectorObject>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  fetchConnectorObjectRequest: FetchConnectorObjectRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getConnectorObject>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetConnectorObjectQueryOptions(fetchConnectorObjectRequest, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const authorizeConnection = (
  params?: AuthorizeConnectionParams,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<void>(
    { url: `/api/connection/authorize`, method: 'GET', params, signal },
    options,
  );
};

export const getAuthorizeConnectionQueryKey = (params?: AuthorizeConnectionParams) => {
  return [`/api/connection/authorize`, ...(params ? [params] : [])] as const;
};

export const getAuthorizeConnectionQueryOptions = <
  TData = Awaited<ReturnType<typeof authorizeConnection>>,
  TError = ErrorType<unknown>,
>(
  params?: AuthorizeConnectionParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof authorizeConnection>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuthorizeConnectionQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof authorizeConnection>>> = ({ signal }) =>
    authorizeConnection(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof authorizeConnection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AuthorizeConnectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof authorizeConnection>>
>;
export type AuthorizeConnectionQueryError = ErrorType<unknown>;

export const useAuthorizeConnection = <
  TData = Awaited<ReturnType<typeof authorizeConnection>>,
  TError = ErrorType<unknown>,
>(
  params?: AuthorizeConnectionParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof authorizeConnection>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthorizeConnectionQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchAuthorizeConnection = async <
  TData = Awaited<ReturnType<typeof authorizeConnection>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params?: AuthorizeConnectionParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof authorizeConnection>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getAuthorizeConnectionQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const deleteConnection = (id: string, options?: SecondParameter<typeof executeFetch>) => {
  return executeFetch<boolean>(
    { url: `/api/connection/delete/${encodeURIComponent(String(id))}`, method: 'POST' },
    options,
  );
};

export const getDeleteConnectionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteConnection>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteConnection>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteConnection>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteConnection(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteConnectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteConnection>>
>;

export type DeleteConnectionMutationError = ErrorType<unknown>;

export const useDeleteConnection = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteConnection>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteConnection>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteConnectionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const disableConnection = (id: string, options?: SecondParameter<typeof executeFetch>) => {
  return executeFetch<void>(
    { url: `/api/connection/disable/${encodeURIComponent(String(id))}`, method: 'POST' },
    options,
  );
};

export const getDisableConnectionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof disableConnection>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof disableConnection>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof disableConnection>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return disableConnection(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DisableConnectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof disableConnection>>
>;

export type DisableConnectionMutationError = ErrorType<unknown>;

export const useDisableConnection = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof disableConnection>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof disableConnection>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDisableConnectionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const findLinkedAssetsCount = (
  connectionId: string,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<FindLinkedAssetsCount200>(
    {
      url: `/api/connection/findLinkedAssetsCount/${encodeURIComponent(String(connectionId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getFindLinkedAssetsCountQueryKey = (connectionId: string) => {
  return [`/api/connection/findLinkedAssetsCount/${connectionId}`] as const;
};

export const getFindLinkedAssetsCountQueryOptions = <
  TData = Awaited<ReturnType<typeof findLinkedAssetsCount>>,
  TError = ErrorType<unknown>,
>(
  connectionId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findLinkedAssetsCount>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFindLinkedAssetsCountQueryKey(connectionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof findLinkedAssetsCount>>> = ({ signal }) =>
    findLinkedAssetsCount(connectionId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!connectionId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof findLinkedAssetsCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FindLinkedAssetsCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof findLinkedAssetsCount>>
>;
export type FindLinkedAssetsCountQueryError = ErrorType<unknown>;

export const useFindLinkedAssetsCount = <
  TData = Awaited<ReturnType<typeof findLinkedAssetsCount>>,
  TError = ErrorType<unknown>,
>(
  connectionId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findLinkedAssetsCount>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFindLinkedAssetsCountQueryOptions(connectionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchFindLinkedAssetsCount = async <
  TData = Awaited<ReturnType<typeof findLinkedAssetsCount>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  connectionId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof findLinkedAssetsCount>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getFindLinkedAssetsCountQueryOptions(connectionId, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const fetchGlobalConnectionSettings = (
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<GlobalConnectionSettings>(
    { url: `/api/connection/global-settings`, method: 'GET', signal },
    options,
  );
};

export const getFetchGlobalConnectionSettingsQueryKey = () => {
  return [`/api/connection/global-settings`] as const;
};

export const getFetchGlobalConnectionSettingsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchGlobalConnectionSettings>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof fetchGlobalConnectionSettings>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFetchGlobalConnectionSettingsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchGlobalConnectionSettings>>> = ({
    signal,
  }) => fetchGlobalConnectionSettings(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchGlobalConnectionSettings>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchGlobalConnectionSettingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchGlobalConnectionSettings>>
>;
export type FetchGlobalConnectionSettingsQueryError = ErrorType<unknown>;

export const useFetchGlobalConnectionSettings = <
  TData = Awaited<ReturnType<typeof fetchGlobalConnectionSettings>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof fetchGlobalConnectionSettings>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchGlobalConnectionSettingsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchFetchGlobalConnectionSettings = async <
  TData = Awaited<ReturnType<typeof fetchGlobalConnectionSettings>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof fetchGlobalConnectionSettings>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getFetchGlobalConnectionSettingsQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const updateGlobalConnectionSettings = (
  globalConnectionSettings: GlobalConnectionSettings,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<GlobalConnectionSettings>(
    {
      url: `/api/connection/global-settings`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: globalConnectionSettings,
    },
    options,
  );
};

export const getUpdateGlobalConnectionSettingsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGlobalConnectionSettings>>,
    TError,
    { data: GlobalConnectionSettings },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateGlobalConnectionSettings>>,
  TError,
  { data: GlobalConnectionSettings },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateGlobalConnectionSettings>>,
    { data: GlobalConnectionSettings }
  > = (props) => {
    const { data } = props ?? {};

    return updateGlobalConnectionSettings(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGlobalConnectionSettingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateGlobalConnectionSettings>>
>;
export type UpdateGlobalConnectionSettingsMutationBody = GlobalConnectionSettings;
export type UpdateGlobalConnectionSettingsMutationError = ErrorType<unknown>;

export const useUpdateGlobalConnectionSettings = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateGlobalConnectionSettings>>,
    TError,
    { data: GlobalConnectionSettings },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateGlobalConnectionSettings>>,
  TError,
  { data: GlobalConnectionSettings },
  TContext
> => {
  const mutationOptions = getUpdateGlobalConnectionSettingsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createConnectionWithInput = (
  connectionInput: ConnectionInput,
  params?: CreateConnectionWithInputParams,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<Connection>(
    {
      url: `/api/connection/input`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: connectionInput,
      params,
    },
    options,
  );
};

export const getCreateConnectionWithInputMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createConnectionWithInput>>,
    TError,
    { data: ConnectionInput; params?: CreateConnectionWithInputParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createConnectionWithInput>>,
  TError,
  { data: ConnectionInput; params?: CreateConnectionWithInputParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createConnectionWithInput>>,
    { data: ConnectionInput; params?: CreateConnectionWithInputParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return createConnectionWithInput(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateConnectionWithInputMutationResult = NonNullable<
  Awaited<ReturnType<typeof createConnectionWithInput>>
>;
export type CreateConnectionWithInputMutationBody = ConnectionInput;
export type CreateConnectionWithInputMutationError = ErrorType<unknown>;

export const useCreateConnectionWithInput = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createConnectionWithInput>>,
    TError,
    { data: ConnectionInput; params?: CreateConnectionWithInputParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createConnectionWithInput>>,
  TError,
  { data: ConnectionInput; params?: CreateConnectionWithInputParams },
  TContext
> => {
  const mutationOptions = getCreateConnectionWithInputMutationOptions(options);

  return useMutation(mutationOptions);
};
export const testConnection = (
  connectionInput: ConnectionInput,
  params?: TestConnectionParams,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<void>(
    {
      url: `/api/connection/input/test`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: connectionInput,
      params,
    },
    options,
  );
};

export const getTestConnectionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof testConnection>>,
    TError,
    { data: ConnectionInput; params?: TestConnectionParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof testConnection>>,
  TError,
  { data: ConnectionInput; params?: TestConnectionParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof testConnection>>,
    { data: ConnectionInput; params?: TestConnectionParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return testConnection(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TestConnectionMutationResult = NonNullable<Awaited<ReturnType<typeof testConnection>>>;
export type TestConnectionMutationBody = ConnectionInput;
export type TestConnectionMutationError = ErrorType<unknown>;

export const useTestConnection = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof testConnection>>,
    TError,
    { data: ConnectionInput; params?: TestConnectionParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof testConnection>>,
  TError,
  { data: ConnectionInput; params?: TestConnectionParams },
  TContext
> => {
  const mutationOptions = getTestConnectionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getOAuthUrl = (
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<GetOAuthUrl200>(
    { url: `/api/connection/oauth-url`, method: 'GET', signal },
    options,
  );
};

export const getGetOAuthUrlQueryKey = () => {
  return [`/api/connection/oauth-url`] as const;
};

export const getGetOAuthUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof getOAuthUrl>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOAuthUrl>>, TError, TData>>;
  request?: SecondParameter<typeof executeFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOAuthUrlQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOAuthUrl>>> = ({ signal }) =>
    getOAuthUrl(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOAuthUrl>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOAuthUrlQueryResult = NonNullable<Awaited<ReturnType<typeof getOAuthUrl>>>;
export type GetOAuthUrlQueryError = ErrorType<unknown>;

export const useGetOAuthUrl = <
  TData = Awaited<ReturnType<typeof getOAuthUrl>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOAuthUrl>>, TError, TData>>;
  request?: SecondParameter<typeof executeFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOAuthUrlQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetOAuthUrl = async <
  TData = Awaited<ReturnType<typeof getOAuthUrl>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOAuthUrl>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetOAuthUrlQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const refreshConnectorObjects = (
  connectorObjectsRefreshRequest: ConnectorObjectsRefreshRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<void>(
    {
      url: `/api/connection/refreshConnectorObjects`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: connectorObjectsRefreshRequest,
    },
    options,
  );
};

export const getRefreshConnectorObjectsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof refreshConnectorObjects>>,
    TError,
    { data: ConnectorObjectsRefreshRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof refreshConnectorObjects>>,
  TError,
  { data: ConnectorObjectsRefreshRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof refreshConnectorObjects>>,
    { data: ConnectorObjectsRefreshRequest }
  > = (props) => {
    const { data } = props ?? {};

    return refreshConnectorObjects(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RefreshConnectorObjectsMutationResult = NonNullable<
  Awaited<ReturnType<typeof refreshConnectorObjects>>
>;
export type RefreshConnectorObjectsMutationBody = ConnectorObjectsRefreshRequest;
export type RefreshConnectorObjectsMutationError = ErrorType<unknown>;

export const useRefreshConnectorObjects = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof refreshConnectorObjects>>,
    TError,
    { data: ConnectorObjectsRefreshRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof refreshConnectorObjects>>,
  TError,
  { data: ConnectorObjectsRefreshRequest },
  TContext
> => {
  const mutationOptions = getRefreshConnectorObjectsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateConnection = (
  id: string,
  connection: Connection,
  params?: UpdateConnectionParams,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<Connection>(
    {
      url: `/api/connection/update/${encodeURIComponent(String(id))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: connection,
      params,
    },
    options,
  );
};

export const getUpdateConnectionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateConnection>>,
    TError,
    { id: string; data: Connection; params?: UpdateConnectionParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateConnection>>,
  TError,
  { id: string; data: Connection; params?: UpdateConnectionParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateConnection>>,
    { id: string; data: Connection; params?: UpdateConnectionParams }
  > = (props) => {
    const { id, data, params } = props ?? {};

    return updateConnection(id, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateConnectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateConnection>>
>;
export type UpdateConnectionMutationBody = Connection;
export type UpdateConnectionMutationError = ErrorType<unknown>;

export const useUpdateConnection = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateConnection>>,
    TError,
    { id: string; data: Connection; params?: UpdateConnectionParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateConnection>>,
  TError,
  { id: string; data: Connection; params?: UpdateConnectionParams },
  TContext
> => {
  const mutationOptions = getUpdateConnectionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateConnectionWithInput = (
  id: string,
  connectionInput: ConnectionInput,
  params?: UpdateConnectionWithInputParams,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<Connection>(
    {
      url: `/api/connection/update/${encodeURIComponent(String(id))}/input`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: connectionInput,
      params,
    },
    options,
  );
};

export const getUpdateConnectionWithInputMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateConnectionWithInput>>,
    TError,
    { id: string; data: ConnectionInput; params?: UpdateConnectionWithInputParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateConnectionWithInput>>,
  TError,
  { id: string; data: ConnectionInput; params?: UpdateConnectionWithInputParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateConnectionWithInput>>,
    { id: string; data: ConnectionInput; params?: UpdateConnectionWithInputParams }
  > = (props) => {
    const { id, data, params } = props ?? {};

    return updateConnectionWithInput(id, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateConnectionWithInputMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateConnectionWithInput>>
>;
export type UpdateConnectionWithInputMutationBody = ConnectionInput;
export type UpdateConnectionWithInputMutationError = ErrorType<unknown>;

export const useUpdateConnectionWithInput = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateConnectionWithInput>>,
    TError,
    { id: string; data: ConnectionInput; params?: UpdateConnectionWithInputParams },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateConnectionWithInput>>,
  TError,
  { id: string; data: ConnectionInput; params?: UpdateConnectionWithInputParams },
  TContext
> => {
  const mutationOptions = getUpdateConnectionWithInputMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createConnectorObject = (
  connectionId: string,
  source: string,
  connectorObject: ConnectorObject,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<ConnectorObject>(
    {
      url: `/api/connection/${encodeURIComponent(String(connectionId))}/${encodeURIComponent(String(source))}/object`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: connectorObject,
    },
    options,
  );
};

export const getCreateConnectorObjectMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createConnectorObject>>,
    TError,
    { connectionId: string; source: string; data: ConnectorObject },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createConnectorObject>>,
  TError,
  { connectionId: string; source: string; data: ConnectorObject },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createConnectorObject>>,
    { connectionId: string; source: string; data: ConnectorObject }
  > = (props) => {
    const { connectionId, source, data } = props ?? {};

    return createConnectorObject(connectionId, source, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateConnectorObjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof createConnectorObject>>
>;
export type CreateConnectorObjectMutationBody = ConnectorObject;
export type CreateConnectorObjectMutationError = ErrorType<unknown>;

export const useCreateConnectorObject = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createConnectorObject>>,
    TError,
    { connectionId: string; source: string; data: ConnectorObject },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createConnectorObject>>,
  TError,
  { connectionId: string; source: string; data: ConnectorObject },
  TContext
> => {
  const mutationOptions = getCreateConnectorObjectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteConnectorObject = (
  connectionId: string,
  source: string,
  objectId: string,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<boolean>(
    {
      url: `/api/connection/${encodeURIComponent(String(connectionId))}/${encodeURIComponent(String(source))}/object/delete/${encodeURIComponent(String(objectId))}`,
      method: 'POST',
    },
    options,
  );
};

export const getDeleteConnectorObjectMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteConnectorObject>>,
    TError,
    { connectionId: string; source: string; objectId: string },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteConnectorObject>>,
  TError,
  { connectionId: string; source: string; objectId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteConnectorObject>>,
    { connectionId: string; source: string; objectId: string }
  > = (props) => {
    const { connectionId, source, objectId } = props ?? {};

    return deleteConnectorObject(connectionId, source, objectId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteConnectorObjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteConnectorObject>>
>;

export type DeleteConnectorObjectMutationError = ErrorType<unknown>;

export const useDeleteConnectorObject = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteConnectorObject>>,
    TError,
    { connectionId: string; source: string; objectId: string },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteConnectorObject>>,
  TError,
  { connectionId: string; source: string; objectId: string },
  TContext
> => {
  const mutationOptions = getDeleteConnectorObjectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateConnectorObject = (
  connectionId: string,
  source: string,
  objectId: string,
  connectorObject: ConnectorObject,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<ConnectorObject>(
    {
      url: `/api/connection/${encodeURIComponent(String(connectionId))}/${encodeURIComponent(String(source))}/object/update/${encodeURIComponent(String(objectId))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: connectorObject,
    },
    options,
  );
};

export const getUpdateConnectorObjectMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateConnectorObject>>,
    TError,
    { connectionId: string; source: string; objectId: string; data: ConnectorObject },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateConnectorObject>>,
  TError,
  { connectionId: string; source: string; objectId: string; data: ConnectorObject },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateConnectorObject>>,
    { connectionId: string; source: string; objectId: string; data: ConnectorObject }
  > = (props) => {
    const { connectionId, source, objectId, data } = props ?? {};

    return updateConnectorObject(connectionId, source, objectId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateConnectorObjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateConnectorObject>>
>;
export type UpdateConnectorObjectMutationBody = ConnectorObject;
export type UpdateConnectorObjectMutationError = ErrorType<unknown>;

export const useUpdateConnectorObject = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateConnectorObject>>,
    TError,
    { connectionId: string; source: string; objectId: string; data: ConnectorObject },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateConnectorObject>>,
  TError,
  { connectionId: string; source: string; objectId: string; data: ConnectorObject },
  TContext
> => {
  const mutationOptions = getUpdateConnectorObjectMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getConnectorObjectById = (
  connectionId: string,
  source: string,
  objectId: string,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<ConnectorObject>(
    {
      url: `/api/connection/${encodeURIComponent(String(connectionId))}/${encodeURIComponent(String(source))}/object/${encodeURIComponent(String(objectId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetConnectorObjectByIdQueryKey = (
  connectionId: string,
  source: string,
  objectId: string,
) => {
  return [`/api/connection/${connectionId}/${source}/object/${objectId}`] as const;
};

export const getGetConnectorObjectByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getConnectorObjectById>>,
  TError = ErrorType<unknown>,
>(
  connectionId: string,
  source: string,
  objectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getConnectorObjectById>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetConnectorObjectByIdQueryKey(connectionId, source, objectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectorObjectById>>> = ({ signal }) =>
    getConnectorObjectById(connectionId, source, objectId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(connectionId && source && objectId),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getConnectorObjectById>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetConnectorObjectByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConnectorObjectById>>
>;
export type GetConnectorObjectByIdQueryError = ErrorType<unknown>;

export const useGetConnectorObjectById = <
  TData = Awaited<ReturnType<typeof getConnectorObjectById>>,
  TError = ErrorType<unknown>,
>(
  connectionId: string,
  source: string,
  objectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getConnectorObjectById>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetConnectorObjectByIdQueryOptions(
    connectionId,
    source,
    objectId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetConnectorObjectById = async <
  TData = Awaited<ReturnType<typeof getConnectorObjectById>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  connectionId: string,
  source: string,
  objectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getConnectorObjectById>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetConnectorObjectByIdQueryOptions(
    connectionId,
    source,
    objectId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const fetchConnectionById = (
  id: string,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<Connection>(
    { url: `/api/connection/${encodeURIComponent(String(id))}`, method: 'GET', signal },
    options,
  );
};

export const getFetchConnectionByIdQueryKey = (id: string) => {
  return [`/api/connection/${id}`] as const;
};

export const getFetchConnectionByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchConnectionById>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof fetchConnectionById>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFetchConnectionByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchConnectionById>>> = ({ signal }) =>
    fetchConnectionById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchConnectionById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchConnectionByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchConnectionById>>
>;
export type FetchConnectionByIdQueryError = ErrorType<unknown>;

export const useFetchConnectionById = <
  TData = Awaited<ReturnType<typeof fetchConnectionById>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof fetchConnectionById>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchConnectionByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchFetchConnectionById = async <
  TData = Awaited<ReturnType<typeof fetchConnectionById>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof fetchConnectionById>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getFetchConnectionByIdQueryOptions(id, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const addConnectionInputAfterConnect = (
  id: string,
  addConnectionInputAfterConnectBody: AddConnectionInputAfterConnectBody,
  params?: AddConnectionInputAfterConnectParams,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<Connection>(
    {
      url: `/api/connection/${encodeURIComponent(String(id))}/input/after-connect`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: addConnectionInputAfterConnectBody,
      params,
    },
    options,
  );
};

export const getAddConnectionInputAfterConnectMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addConnectionInputAfterConnect>>,
    TError,
    {
      id: string;
      data: AddConnectionInputAfterConnectBody;
      params?: AddConnectionInputAfterConnectParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addConnectionInputAfterConnect>>,
  TError,
  {
    id: string;
    data: AddConnectionInputAfterConnectBody;
    params?: AddConnectionInputAfterConnectParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addConnectionInputAfterConnect>>,
    {
      id: string;
      data: AddConnectionInputAfterConnectBody;
      params?: AddConnectionInputAfterConnectParams;
    }
  > = (props) => {
    const { id, data, params } = props ?? {};

    return addConnectionInputAfterConnect(id, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddConnectionInputAfterConnectMutationResult = NonNullable<
  Awaited<ReturnType<typeof addConnectionInputAfterConnect>>
>;
export type AddConnectionInputAfterConnectMutationBody = AddConnectionInputAfterConnectBody;
export type AddConnectionInputAfterConnectMutationError = ErrorType<unknown>;

export const useAddConnectionInputAfterConnect = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addConnectionInputAfterConnect>>,
    TError,
    {
      id: string;
      data: AddConnectionInputAfterConnectBody;
      params?: AddConnectionInputAfterConnectParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addConnectionInputAfterConnect>>,
  TError,
  {
    id: string;
    data: AddConnectionInputAfterConnectBody;
    params?: AddConnectionInputAfterConnectParams;
  },
  TContext
> => {
  const mutationOptions = getAddConnectionInputAfterConnectMutationOptions(options);

  return useMutation(mutationOptions);
};
