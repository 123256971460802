import { forwardRef, useMemo } from 'react';
import { default as BaseToggle, type SwitchProps as BaseSwitchProps } from '@mui/joy/Switch';
import noop from 'lodash/noop';
import type { ToggleProps } from './types';
import {
  toggleActionVariantsReset,
  toggleRootVariantsReset,
  toggleThumbVariants,
  toggleTrackVariants,
} from './styles';

const Toggle = forwardRef<HTMLInputElement, ToggleProps>((props, ref) => {
  const {
    disabled: disabledProp,
    readOnly: readOnlyProp,
    onChange,
    className,
    size = 'sm',
    color = 'brand',
    ...restProps
  } = props;

  const toggleSlotProps = useMemo<BaseSwitchProps['slotProps']>(
    () => ({
      root: ({ checked, disabled, focusVisible, readOnly }) => ({
        className: toggleRootVariantsReset({
          className,
          checked: Boolean(checked),
          disabled: Boolean(disabled || readOnly),
          focusVisible,
        }),
      }),
      track: ({ checked, disabled, focusVisible, readOnly }) => ({
        className: toggleTrackVariants({
          checked: Boolean(checked),
          disabled: Boolean(disabled || readOnly),
          focusVisible,
          color,
        }),
      }),
      thumb: ({ checked, disabled, focusVisible, readOnly }) => ({
        className: toggleThumbVariants({ checked, disabled: disabled || readOnly, focusVisible }),
      }),
      action: ({ checked, disabled, focusVisible, readOnly }) => ({
        className: toggleActionVariantsReset({
          checked,
          disabled: disabled || readOnly,
          focusVisible,
        }),
      }),
      input: {
        ref,
      },
    }),
    [className, color, ref],
  );

  return (
    <BaseToggle
      disabled={disabledProp}
      onChange={readOnlyProp ? noop : onChange}
      readOnly={readOnlyProp}
      // @ts-expect-error xs does not exist on Joy UI's SwitchProps. and we have not overridden the type
      size={size}
      slotProps={toggleSlotProps}
      {...restProps}
    />
  );
});

Toggle.displayName = 'Toggle';

export default Toggle;
