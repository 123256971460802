import type { FormContextType, RJSFSchema, StrictRJSFSchema, WidgetProps } from '@rjsf/utils';
import { getTemplate } from '@rjsf/utils';
import type { SelectTemplateComponentType, SelectTemplateProps } from './templates/SelectTemplate';
import { SelectTemplate as DefaultSelectTemplate } from './templates/SelectTemplate';

export type { SelectTemplateProps as SelectWidgetProps };

/** The `SelectWidget` component uses the `BaseSelectTemplate`
 *
 * @param props - The `WidgetProps` for this component
 */

export default function SelectWidget<
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = object,
>(props: WidgetProps<T, S, F>) {
  const { options, registry } = props;
  const BaseSelectTemplate =
    // @ts-expect-error -- BaseSelectTemplate not in template type
    ((getTemplate('SelectTemplate', registry, options) as
      | SelectTemplateComponentType
      | undefined) ?? DefaultSelectTemplate) as (props: WidgetProps<T, S, F>) => JSX.Element;

  return <BaseSelectTemplate {...props} />;
}
