import { getSchemaType, type WidgetProps } from '@rjsf/utils';
import React, { useCallback, useMemo } from 'react';
import type { LookupByQueryRequest } from '@unifyapps/network/generated/models/lookupByQueryRequest';
import _isEmpty from 'lodash/isEmpty';
import { LookupByQueryRequestType } from '@unifyapps/network/generated/models/lookupByQueryRequestType';
import _identity from 'lodash/identity';
import useDebounceValue from '@unifyapps/hooks/useDebounceValue';
import getUaOptions from '@unifyapps/form/utils/getUaOptions';
import { LeftJustify, VerticalAlign } from '../../components/Alignments';
import type { BaseLookupExtendedProps, LookupWidgetUISchema } from '../types';
import BaseLookupWidget from '../BaseLookupWidget';
import { useSlotProps, useSlots } from '../hooks/useSlots';

export type Props = WidgetProps &
  Pick<BaseLookupExtendedProps, 'slots' | 'footerAction' | 'variant'>;

export type LookupWidgetTemplateComponentType = (props: Props) => React.ReactNode;

function LookupWidgetTemplate(props: Props) {
  const { schema, footerAction, onChange, variant, className, registry } = props;
  const uiSchema = props.uiSchema as LookupWidgetUISchema<LookupByQueryRequest>;

  const uaOptions = getUaOptions<{
    payload?: LookupByQueryRequest;
    disableClearable?: boolean;
    refreshable?: boolean;
  }>(uiSchema, registry.globalUiOptions);

  const lookupRequest = uaOptions.payload;
  const disableClearable = Boolean(uaOptions.disableClearable);
  // In case of external lookups used in applications, when refreshable is false: Backend doesn't keep things in memory, hence no search from server hence search on client
  const isClientSideSearch =
    !uaOptions.refreshable && lookupRequest?.lookupType === 'CONNECTOR_OBJECT_NAME';

  const pageSize = lookupRequest?.page?.limit;

  const {
    value: query,
    setValue: setSearchQuery,
    debouncedValue: debouncedQuery,
  } = useDebounceValue({ initialValue: '', delay: 200 });

  const adaptedLookupRequest = useMemo(
    () => ({
      ...lookupRequest,
      type: LookupByQueryRequestType.ByQuery,
      query: debouncedQuery,
    }),
    [debouncedQuery, lookupRequest],
  );

  const isArrayType = getSchemaType(schema) === 'array';

  if (!lookupRequest) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error('LookupWidget: ua:payload cannot be empty');
    } else {
      console.error('LookupWidget: ua:payload cannot be empty');
    }
  }

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, updatedQuery: string | undefined, type) => {
      if (type === 'input') {
        //if the input is empty, we reset the value
        if (_isEmpty(updatedQuery)) {
          onChange(undefined);
        }
        setSearchQuery(updatedQuery ?? '');
      }

      if (type === 'reset' && _isEmpty(updatedQuery)) {
        //if the user cross the input, we reset the query
        setSearchQuery(updatedQuery ?? '');
      }
    },
    [onChange, setSearchQuery],
  );

  const { slots } = useSlots(props.slots);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- is okay
  const { slotProps } = useSlotProps(uiSchema, footerAction, props.slotProps);

  const baseLookupWidget = (
    <BaseLookupWidget
      {...props}
      // In case search is on client side, let autocomplete's default search take over
      className={className}
      disableClearable={disableClearable}
      filterOptions={isClientSideSearch ? undefined : _identity}
      input={query}
      lookupRequest={adaptedLookupRequest}
      onChange={onChange}
      onInputChange={onInputChange}
      pageSize={pageSize ?? 40}
      placeholder={props.placeholder ?? (uiSchema['ui:placeholder'] as string)}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- is okay
      slotProps={slotProps}
      slots={slots}
      variant={variant}
    />
  );
  if (!isArrayType) return baseLookupWidget;

  // In case of array type --> rjsf does not provide a way to show title
  const Wrapper = uaOptions.alignment === 'left-justify' ? LeftJustify : VerticalAlign;
  return <Wrapper {...props}>{baseLookupWidget}</Wrapper>;
}

LookupWidgetTemplate.displayName = 'LookupWidgetTemplate';

export default LookupWidgetTemplate;
