import { isTextSelection } from '@tiptap/core';
import type { Editor } from '@tiptap/react';

// ref: https://github.com/ueberdosis/tiptap-templates/blob/9463b7d46f2c02a3a0907af8f52667b2b1e7d953/templates/next-block-editor-app/src/lib/utils/isTextSelected.ts#L4
export const isTextSelected = ({ editor }: { editor: Editor }) => {
  const {
    state: {
      doc,
      selection,
      selection: { empty, from, to },
    },
  } = editor;

  // Sometime check for `empty` is not enough.
  // Doubleclick an empty paragraph returns a node size of 2.
  // So we check also for an empty text size.
  const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(selection);

  if (empty || isEmptyTextBlock || !editor.isEditable) {
    return false;
  }

  return true;
};

export default isTextSelected;
