import { forwardRef, useMemo } from 'react';
import { default as BaseRadio, type RadioProps as BaseRadioProps } from '@mui/joy/Radio';
import { clsx } from 'clsx';
import type { RadioProps } from './types';
import {
  radioActionVariants,
  radioIconVariants,
  radioLabelVariants,
  radioRadioVariants,
  radioRootVariants,
} from './styles';

const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const {
    className,
    disabled: disabledProp,
    readOnly: readOnlyProp,
    onChange,
    size = 'sm',
    classes,
    ...restProps
  } = props;

  const radioSlotProps = useMemo<BaseRadioProps['slotProps']>(
    () => ({
      root: ({ checked, disabled, readOnly, focusVisible }) => ({
        className: radioRootVariants({
          className,
          checked,
          disabled: disabled || readOnly,
          focusVisible,
          size,
        }),
      }),
      radio: ({ checked, disabled, readOnly, focusVisible }) => ({
        className: radioRadioVariants({
          className: readOnly ? clsx('hidden', classes?.radio) : classes?.radio,
          checked,
          disabled: disabled || readOnly,
          focusVisible,
          size,
        }),
      }),
      icon: ({ checked, disabled, readOnly, focusVisible }) => ({
        className: radioIconVariants({
          checked,
          disabled: disabled || readOnly,
          focusVisible,
          size,
        }),
      }),
      action: ({ checked, disabled, readOnly, focusVisible }) => ({
        className: radioActionVariants({
          checked,
          disabled: disabled || readOnly,
          focusVisible,
          size,
        }),
      }),
      label: ({ checked, disabled, readOnly, focusVisible }) => ({
        className: radioLabelVariants({
          checked,
          disabled: disabled || readOnly,
          focusVisible,
          size,
        }),
      }),
      input: {
        ref,
      },
    }),
    [className, classes?.radio, ref, size],
  );

  return (
    <BaseRadio
      disabled={disabledProp || readOnlyProp}
      onChange={onChange}
      readOnly={readOnlyProp}
      ref={ref}
      size={size}
      slotProps={radioSlotProps}
      {...restProps}
    />
  );
});

Radio.displayName = 'Radio';

export default Radio;
