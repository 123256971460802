import { Bold } from '@tiptap/extension-bold';
import { TextStyle } from '@tiptap/extension-text-style';
import { FontFamily } from '@tiptap/extension-font-family';
import { Placeholder } from '@tiptap/extension-placeholder';
import { BulletList } from '@tiptap/extension-bullet-list';
import { Document } from '@tiptap/extension-document';
import { HardBreak } from '@tiptap/extension-hard-break';
import { History } from '@tiptap/extension-history';
import { Italic } from '@tiptap/extension-italic';
import { ListItem } from '@tiptap/extension-list-item';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Strike } from '@tiptap/extension-strike';
import { Text } from '@tiptap/extension-text';

export const RichTextEditorPlugins = {
  Bold: Bold.name,
  TextStyle: TextStyle.name,
  FontFamily: FontFamily.name,
  Placeholder: Placeholder.name,
  BulletList: BulletList.name,
  Document: Document.name,
  HardBreak: HardBreak.name,
  History: History.name,
  Italic: Italic.name,
  ListItem: ListItem.name,
  OrderedList: OrderedList.name,
  Paragraph: Paragraph.name,
  Strike: Strike.name,
  Text: Text.name,
};
