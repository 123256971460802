import type { DatePickerProps as BaseDatePickerProps } from '@unifyapps/ui/_components/DatePicker';
import { DatePicker as BaseDatePicker } from '@unifyapps/ui/_components/DatePicker';
import { useCallback, useState, useEffect, useMemo } from 'react';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { useSlot } from '@unifyapps/ui/slots/useSlot';
import type { CreateSlotsAndSlotProps } from '@unifyapps/ui/slots/types';
import DatePickerActions from './DatePickerActions';
import { dateSelectorClassNames } from './utils';

export type DayInNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;

interface DatePickerProps {
  disabled?: BaseDatePickerProps['disabled'];
  value?: Date;
  onSubmit: (date?: Date) => void;
  initialFocus?: boolean;
  disableActions?: boolean;
  showDateTimeInput?: boolean;
  onChange?: (date: Date) => void;
  classNames?: BaseDatePickerProps['classNames'];
  viewOnlyMode?: boolean;
  fromYear?: number;
  toYear?: number;
  weekStartsOn?: DayInNumber;
  showOutsideDays?: boolean;
  captionLayout?: BaseDatePickerProps['captionLayout'];
  fixedWeeks?: boolean;
}

function DefaultAction({
  selected,
  initialValue,
  onApply,
  onClear,
  onReset,
}: {
  selected: Date | undefined;
  initialValue: Date | undefined;
  onApply: () => void;
  onClear: () => void;
  onReset: () => void;
}) {
  return (
    <DatePickerActions
      disableApply={!selected}
      disableReset={selected === initialValue}
      onApply={onApply}
      onClear={onClear}
      onReset={onReset}
    />
  );
}

export default function DatePicker(
  props: DatePickerProps &
    CreateSlotsAndSlotProps<{ actionSlot?: React.ElementType }, { actionSlot: object }>,
) {
  const {
    disabled,
    value: initialValue,
    onSubmit,
    initialFocus = true,
    weekStartsOn = 0,
    onChange,
    classNames: _classNames,
    viewOnlyMode,
    showOutsideDays = false,
    captionLayout = 'dropdown-buttons',
    // datetime picker expects fromYear and toYear for  caption (month and year) dropdown to work
    fromYear = 1970,
    toYear = new Date().getFullYear(),
    fixedWeeks,
    slots,
    slotProps,
  } = props;
  const [selected, setSelected] = useState(initialValue);

  useEffect(() => {
    setSelected(initialValue);
  }, [initialValue]);

  const onReset = useCallback(() => {
    setSelected(initialValue);
  }, [initialValue]);

  const onClear = useCallback(() => {
    setSelected(undefined);
    onSubmit(undefined);
  }, [onSubmit]);

  const onApply = useCallback(() => {
    onSubmit(selected);
  }, [onSubmit, selected]);

  const handleSelect = useEventCallback((_date: Date) => {
    if (viewOnlyMode) return;

    setSelected(_date);
    onChange?.(_date);
  });

  const classNames = useMemo(() => ({ ...dateSelectorClassNames, ..._classNames }), [_classNames]);

  const [Action, actionProps] = useSlot('actionSlot', {
    elementType: DefaultAction,
    externalForwardedProps: { slots, slotProps },
    className: '',
    additionalProps: { selected, initialValue, onApply, onClear, onReset },
    ownerState: {},
  });

  return (
    <>
      <BaseDatePicker
        captionLayout={captionLayout}
        classNames={classNames}
        defaultMonth={selected}
        disabled={disabled}
        fixedWeeks={fixedWeeks}
        fromYear={fromYear}
        initialFocus={initialFocus}
        mode="single"
        onSelect={handleSelect}
        required
        selected={selected}
        showOutsideDays={showOutsideDays}
        toYear={toYear}
        weekStartsOn={weekStartsOn}
      />
      <Action {...actionProps} />
    </>
  );
}
