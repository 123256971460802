import { isValid } from 'date-fns/isValid';
import { parse } from 'date-fns/parse';
import { parseISO } from 'date-fns/parseISO';
import { formatDate as formatDateFns } from '../../utils/date';
import type { SchemaAddModalFormFields } from '../SchemaGeneratorWidget/utils/types';

export const enum DateWidgetType {
  Date = 'date',
  DateTime = 'date-time',
}

export type DateTimeWidgetUaOptions = {
  dateTimeAddOns?: SchemaAddModalFormFields['dateTimeAddOns'];
  dateFormat?: string;
  format?: DateWidgetType;
};

export enum DateLimitOption {
  DisablePast = 'disablePast',
  DisableFuture = 'disableFuture',
  SelectRange = 'selectRange',
}

export function parseDate(value: string | number | undefined, dateFormat: string | undefined) {
  if (value === undefined) return null;
  let dateString: string;

  // if value is a number, it is a epoch time need to convert it to date string
  if (typeof value === 'number' || isValid(parseISO(value))) {
    dateString = dateFormat ? formatDateFns(value, dateFormat) : new Date(value).toJSON();
  } else {
    dateString = value;
  }

  const date = dateFormat ? parse(dateString, dateFormat, new Date()) : new Date(dateString);
  return isValid(date) ? date : null;
}

export function formatDate(date: Date | undefined, dateFormat: string | undefined) {
  if (!date) return undefined;
  return dateFormat ? formatDateFns(date, dateFormat) : date.toJSON();
}

export function getDatePickerValue(
  value: string | number | undefined,
  dateFormat: string | undefined,
) {
  return parseDate(value ?? '', dateFormat) ?? new Date();
}

const getDateWithOffset = (daysOffset: number, includeToday: boolean): Date => {
  const today = new Date();
  if (!includeToday) {
    today.setDate(today.getDate() + daysOffset);
  }
  return today;
};

export function calculateDateRange(
  dateSelectionLimits: NonNullable<
    DateTimeWidgetUaOptions['dateTimeAddOns']
  >['dateSelectionLimits'],
) {
  const { dateRangeLimit, maxDate, minDate, includeToday } = dateSelectionLimits ?? {};

  let before: Date | undefined;
  let after: Date | undefined;

  switch (dateRangeLimit) {
    case DateLimitOption.DisablePast:
      after = maxDate ? new Date(maxDate) : undefined;
      before = getDateWithOffset(1, includeToday ?? false);
      break;
    case DateLimitOption.DisableFuture:
      before = minDate ? new Date(minDate) : undefined;
      after = getDateWithOffset(-1, includeToday ?? false);
      break;
    default:
      after = maxDate ? new Date(maxDate) : undefined;
      before = minDate ? new Date(minDate) : undefined;
      break;
  }

  return { before, after };
}
