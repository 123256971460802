import { useMemo } from 'react';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import type { JSONSchema7 } from 'json-schema';
import type { FieldProps, IdSchema, ObjectFieldTemplateProps, UiSchema } from '@rjsf/utils';
import { getTemplate, getUiOptions, orderProperties, PROPERTIES_KEY } from '@rjsf/utils';
import type { UAObjectFieldTemplatePropertyType } from '../../templates/ObjectFieldTemplate/types';
import { useObjectField } from './hooks/useObjectField';

export default function ObjectField(props: FieldProps) {
  const {
    schema: rawSchema,
    uiSchema = {},
    errorSchema,
    idSchema,
    name,
    required = false,
    disabled,
    readonly,
    hideError,
    idPrefix,
    idSeparator,
    onBlur,
    onFocus,
    registry,
    title,
  } = props;

  const { fields, schemaUtils, globalUiOptions } = registry;
  const { SchemaField } = fields;
  const schema: JSONSchema7 = schemaUtils.retrieveSchema(rawSchema, props.formData);
  const uiOptions = getUiOptions(uiSchema, globalUiOptions);
  const { properties: schemaProperties = {} } = schema;

  const templateTitle = uiOptions.title ?? schema.title ?? title ?? name;
  const description = uiOptions.description ?? schema.description;
  const orderedProperties = useMemo(() => {
    let _orderedProperties: string[];
    try {
      const properties = Object.keys(schemaProperties);
      _orderedProperties = orderProperties(properties, uiOptions.order);
    } catch (err) {
      return (
        <div>
          <pre>{JSON.stringify(schema)}</pre>
        </div>
      );
    }
    return _orderedProperties;
  }, [schema, schemaProperties, uiOptions.order]);

  const { onPropertyChange } = useObjectField({
    errorSchema: props.errorSchema,
    orderedProperties,
    formData: props.formData as unknown,
    onChange: props.onChange,
  });

  const properties = useMemo(() => {
    if (!Array.isArray(orderedProperties)) return [];
    return orderedProperties.map((propertyName) => {
      const fieldUiSchema = uiSchema[propertyName] as UiSchema | undefined;
      const fieldUiOptions = getUiOptions(fieldUiSchema);
      // adding hidden `ui:hidden` to add support for headless widgets which causes extra gaps in the form
      const hidden = Boolean(fieldUiOptions.widget === 'hidden' || fieldUiOptions.hidden);
      const fieldIdSchema = {
        $id: `${props.idSchema.$id}${idSeparator ?? '.'}${propertyName}`,
      } as IdSchema;

      return {
        content: <></>,
        // eslint-disable-next-line react/no-unstable-nested-components -- this is required for the `content` prop
        getContent: () => (
          <SchemaField
            disabled={disabled}
            errorSchema={_get(errorSchema, propertyName)}
            formContext={props.formContext as unknown}
            formData={_get(props.formData, propertyName) as unknown}
            hideError={hideError}
            idPrefix={idPrefix}
            idSchema={fieldIdSchema}
            idSeparator={idSeparator}
            key={propertyName}
            name={propertyName}
            onBlur={onBlur}
            onChange={(...args) => onPropertyChange(propertyName, ...args)}
            onFocus={onFocus}
            readonly={readonly}
            registry={registry}
            required={Array.isArray(schema.required) && schema.required.includes(propertyName)}
            schema={_get(schema, [PROPERTIES_KEY, propertyName], {}) as JSONSchema7}
            uiSchema={fieldUiSchema}
          />
        ),
        name: propertyName,
        readonly,
        disabled,
        required,
        hidden,
      } as UAObjectFieldTemplatePropertyType;
    });
  }, [
    SchemaField,
    disabled,
    errorSchema,
    hideError,
    idPrefix,
    idSeparator,
    onBlur,
    onFocus,
    onPropertyChange,
    orderedProperties,
    props.formContext,
    props.formData,
    props.idSchema,
    readonly,
    registry,
    required,
    schema,
    uiSchema,
  ]);

  if (!Array.isArray(orderedProperties)) {
    return orderedProperties;
  }

  const Template = getTemplate<'ObjectFieldTemplate'>('ObjectFieldTemplate', registry, uiOptions);

  const templateProps = {
    // getDisplayLabel() always returns false for object types, so just check the `uiOptions.label`
    title: uiOptions.label === false ? '' : templateTitle,
    description: uiOptions.label === false ? undefined : description,
    readonly,
    disabled,
    required,
    idSchema,
    uiSchema,
    errorSchema,
    schema,
    formData: props.formData as unknown,
    formContext: props.formContext as unknown,
    registry,
  };

  return (
    <Template
      {...templateProps}
      onAddClick={_noop as ObjectFieldTemplateProps['onAddClick']}
      properties={properties}
    />
  );
}
