import { useCallback, type ChangeEvent, type FocusEvent, useMemo } from 'react';
import { clsx } from 'clsx';
import { Radio } from '@unifyapps/ui/components/Radio';
import RadioGroup from '@unifyapps/ui/_components/RadioGroup';
import type { WidgetProps } from '@rjsf/utils';
import {
  ariaDescribedByIds,
  enumOptionsIndexForValue,
  enumOptionsValueForIndex,
  optionId,
} from '@rjsf/utils';
import type {
  TypographyVariants,
  TypographyWeight,
  TypographyColors,
} from '@unifyapps/ui/components/Typography';
import { Typography } from '@unifyapps/ui/components/Typography';
import Stack from '@unifyapps/ui/_components/Stack';
import { UA } from '../../const/UAKeys';
import { getUiOptions } from '../../utils/getUiOptions';
import { outlineVariantStyles } from './styles';

export type RadioSlots = {
  title?: {
    variant?: TypographyVariants;
    weight?: TypographyWeight;
    color?: TypographyColors;
  };
  description?: {
    variant?: TypographyVariants;
    weight?: TypographyWeight;
    color?: TypographyColors;
  };
};

interface RadioLabelProps {
  title: string;
  description?: string;
  slots?: RadioSlots;
}

const EMPTY_OBJECT = Object.freeze({});

export function RadioLabel(props: RadioLabelProps) {
  const { title, description, slots } = props;

  const { title: titleSlot, description: descriptionSlot } = slots ?? (EMPTY_OBJECT as RadioSlots);

  if (!description) {
    return (
      <Typography
        color={titleSlot?.color ?? 'text-secondary'}
        variant={titleSlot?.variant ?? 'text-sm'}
        weight={titleSlot?.weight ?? 'medium'}
      >
        {title}
      </Typography>
    );
  }

  return (
    <Stack alignItems="start" className="gap-y-xs">
      <Typography
        color={titleSlot?.color ?? 'text-secondary'}
        variant={titleSlot?.variant ?? 'text-sm'}
        weight={titleSlot?.weight ?? 'medium'}
      >
        {title}
      </Typography>
      <Typography
        color={descriptionSlot?.color ?? 'text-tertiary'}
        variant={descriptionSlot?.variant ?? 'text-sm'}
        weight={descriptionSlot?.weight ?? 'regular'}
      >
        {description}
      </Typography>
    </Stack>
  );
}

// removing the margin applied, since we are using gap in RadioGroup
const radioStyles = { '--FormHelperText-margin': 0, '--RadioGroup-gap': 0 } as React.CSSProperties;
const radioClasses = { radio: 'mt-xxs' };

/** The `RadioWidget` is a widget for rendering a radio group.
 *  It is typically used with a string property constrained with enum options.
 *
 * @param props - The `WidgetProps` for this component
 */
export default function RadioWidget({
  id,
  options,
  value,
  disabled,
  readonly,
  onChange,
  onBlur,
  onFocus,
  uiSchema,
  registry,
}: WidgetProps) {
  const { enumOptions, enumDisabled } = options;
  const emptyValue = options.emptyValue as string;

  const { slots } = useMemo(() => getUiOptions({ uiSchema, registry }), [registry, uiSchema]) as {
    slots?: {
      title?: {
        variant?: TypographyVariants;
        weight?: TypographyWeight;
        color?: TypographyColors;
      };
      description?: {
        variant?: TypographyVariants;
        weight?: TypographyWeight;
        color?: TypographyColors;
      };
    };
  };

  const _onChange = useCallback(
    ({ target: { value: val } }: ChangeEvent<HTMLInputElement>) =>
      onChange(enumOptionsValueForIndex(val, enumOptions, emptyValue)),
    [emptyValue, enumOptions, onChange],
  );
  const _onBlur = useCallback(
    ({ target: { value: val } }: FocusEvent<HTMLInputElement>) =>
      onBlur(id, enumOptionsValueForIndex(val, enumOptions, emptyValue)),
    [emptyValue, enumOptions, id, onBlur],
  );
  const _onFocus = useCallback(
    ({ target: { value: val } }: FocusEvent<HTMLInputElement>) =>
      onFocus(id, enumOptionsValueForIndex(val, enumOptions, emptyValue)),
    [emptyValue, enumOptions, id, onFocus],
  );

  const isVertical = options[UA.Orientation] === 'vertical';
  const isOutlined = options[UA.Variant] === 'outlined';
  const selectedIndex = enumOptionsIndexForValue(value, enumOptions) ?? null;

  const uiOptions = useMemo(() => getUiOptions({ uiSchema, registry }), [registry, uiSchema]) as {
    size?: 'sm' | 'md';
  };
  const radioClassName = options['ui:radioClassName'];

  return (
    <RadioGroup
      aria-describedby={ariaDescribedByIds(id)}
      className={clsx('m-0', {
        'gap-x-xl': !isVertical,
        'gap-y-xl': isVertical,
      })}
      id={id}
      name={id}
      onBlur={_onBlur}
      onChange={_onChange}
      onFocus={_onFocus}
      orientation={isVertical ? 'vertical' : 'horizontal'}
      value={selectedIndex}
    >
      {Array.isArray(enumOptions) &&
        enumOptions.map((option, index) => {
          const isSelected = selectedIndex === String(index);
          const optionValue = option.value as string | number | boolean;
          const itemDisabled = Array.isArray(enumDisabled) && enumDisabled.includes(optionValue);

          // In readonly mode, we only want to show the selected item
          if (readonly && !isSelected) {
            return null;
          }

          return (
            <Radio
              className={clsx(
                isOutlined ? outlineVariantStyles({ isSelected, readonly, disabled }) : '',
                '!items-start',
                radioClassName ?? '',
              )}
              classes={radioClasses}
              disabled={disabled || itemDisabled}
              id={optionId(id, index)}
              key={`${optionValue.toString()}_${index}`}
              label={
                <RadioLabel
                  description={option.schema?.description}
                  slots={slots}
                  title={option.label}
                />
              }
              name={id}
              readOnly={readonly}
              size={uiOptions.size ?? 'md'}
              style={radioStyles}
              value={String(index)}
            />
          );
        })}
    </RadioGroup>
  );
}
