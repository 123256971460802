import AddButton from './AddButton';
import CopyButton from './CopyButton';
import MoveDownButton from './MoveDownButton';
import MoveUpButton from './MoveUpButton';
import RemoveButton from './RemoveButton';
import SubmitButton from './SubmitButton';

const ButtonTemplates = {
  AddButton,
  MoveUpButton,
  MoveDownButton,
  RemoveButton,
  SubmitButton,
  CopyButton,
};

export default ButtonTemplates;
