import { useCallback, useMemo } from 'react';
import type { ChangeEvent, ReactElement, SyntheticEvent } from 'react';
import { useTranslation } from '@unifyapps/i18n/client';
import { Box } from '@unifyapps/ui/components/Box';
import { Checkbox } from '@unifyapps/ui/components/Checkbox';
import { Divider } from '@unifyapps/ui/components/Divider';
import type {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteValue,
} from '@unifyapps/ui/components/Autocomplete';

type ListBoxHeaderProps = {
  autoCompleteProps: {
    value: Record<string, unknown>[];
    onChange: (
      _: SyntheticEvent,
      newValue: AutocompleteValue<Record<string, unknown>, boolean, false, false>,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<Record<string, unknown> & { inputValue?: string }>,
    ) => void;
    getOptionKey: (option: Record<string, unknown>) => string;
    options: Record<string, unknown>[];
  };
  filteredElements: ReactElement[];
};

const defaultGetOptionKey = (option: Record<string, unknown>) => option.value ?? '';

export function ListBoxHeader(props: ListBoxHeaderProps) {
  const { t } = useTranslation(['common']);
  const { value, onChange, getOptionKey = defaultGetOptionKey, options } = props.autoCompleteProps;

  const selectedOptionIds = useMemo(() => value.map(getOptionKey), [value, getOptionKey]);

  const filteredOptions = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- option is in filteredElement props
    const filteredKeys = new Set(props.filteredElements.map((el) => getOptionKey(el.props.option)));
    return options.filter((option) => filteredKeys.has(getOptionKey(option) as string));
  }, [getOptionKey, options, props.filteredElements]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange({} as SyntheticEvent, e.target.checked ? filteredOptions : [], 'selectOption');
    },
    [filteredOptions, onChange],
  );

  if (!options.length) return null;

  return (
    <>
      <Box className="px-md py-sm mx-sm my-xxs hover:bg-primary_hover rounded-md">
        <Checkbox
          checked={selectedOptionIds.length === filteredOptions.length}
          className="flex items-center"
          indeterminate={
            selectedOptionIds.length > 0 && selectedOptionIds.length < filteredOptions.length
          }
          label={t('Actions.SelectAll')}
          labelClassName="font-normal"
          onChange={handleChange}
          size="sm"
        />
      </Box>
      <Divider className="mx-sm" />
    </>
  );
}
