import * as React from 'react';
import type { SVGProps } from 'react';
const SvgH1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path strokeLinecap="square" d="M6 4.5V12m0 7.5V12m12-7.5v15m0-7.5H6" />
  </svg>
);
export default SvgH1;
