import { useCallback, useMemo, type ChangeEvent, type FocusEvent } from 'react';
import type { WidgetProps } from '@rjsf/utils';
import {
  enumOptionsDeselectValue,
  enumOptionsSelectValue,
  enumOptionsValueForIndex,
  optionsList,
} from '@rjsf/utils';
import type { JSONSchema7 } from 'json-schema';
import getUaOptions from '../../utils/getUaOptions';
import { UA } from '../../const/UAKeys';

function useCheckboxesWidget(props: WidgetProps) {
  const {
    id,
    options,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- safe
    value,
    disabled: _disabled,
    onChange,
    onBlur,
    onFocus,
    registry,
    schema,
    uiSchema,
  } = props;
  const itemsSchema = registry.schemaUtils.retrieveSchema(schema.items as JSONSchema7);
  const enumOptions = optionsList(itemsSchema);
  const { enumDisabled } = options;
  const { containerClassName } = getUaOptions<{
    containerClassName?: string;
  }>(uiSchema, registry);
  const isOutlined = options[UA.Variant] === 'outlined';

  const emptyValue = options.emptyValue as string;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- safe
  const checkboxesValues = useMemo(() => (Array.isArray(value) ? value : [value]), [value]);

  const _onChange = useCallback(
    (index: number) =>
      ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
        if (checked) {
          onChange(enumOptionsSelectValue(index, checkboxesValues, enumOptions));
        } else {
          onChange(enumOptionsDeselectValue(index, checkboxesValues, enumOptions));
        }
      },
    [checkboxesValues, enumOptions, onChange],
  );
  const _onBlur = useCallback(
    ({ target: { value: val } }: FocusEvent<HTMLInputElement>) =>
      onBlur(id, enumOptionsValueForIndex(val, enumOptions, emptyValue)),
    [emptyValue, enumOptions, id, onBlur],
  );
  const _onFocus = useCallback(
    ({ target: { value: val } }: FocusEvent<HTMLInputElement>) =>
      onFocus(id, enumOptionsValueForIndex(val, enumOptions, emptyValue)),
    [emptyValue, enumOptions, id, onFocus],
  );

  return {
    _onChange,
    _onBlur,
    _onFocus,
    checkboxesValues,
    containerClassName,
    enumDisabled,
    isOutlined,
    enumOptions,
  };
}

export default useCheckboxesWidget;
