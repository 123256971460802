import React from 'react';
import { getUiOptions, type WidgetProps } from '@rjsf/utils';
import _map from 'lodash/map';
import CopyBox from '../../components/CopyBox';

export default function CopyBoxWidget(props: WidgetProps) {
  const { uiSchema } = props;

  const uiOptions = getUiOptions(uiSchema);

  return <CopyBox text={uiOptions.text ? String(uiOptions.text) : ''} />;
}
