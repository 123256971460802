import type { FormContextType, RJSFSchema, StrictRJSFSchema, WidgetProps } from '@rjsf/utils';
import { getTemplate } from '@rjsf/utils';
import type { LookupWidgetTemplateComponentType } from './templates/LookupWidgetTemplate';
import DefaultBaseLookupWidgetTemplate from './templates/BaseLookupWidgetTemplate';

/** The `LookupWidget` component uses the `BaseSelectTemplate`
 *
 * @param props - The `WidgetProps` for this component
 */

export default function BaseLookupWidget<
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = object,
>(props: WidgetProps<T, S, F>) {
  const { options, registry } = props;
  const BaseLookupWidgetTemplate =
    // @ts-expect-error -- BaseSelectTemplate not in template type
    ((getTemplate('BaseLookupWidgetTemplate', registry, options) as
      | LookupWidgetTemplateComponentType
      | undefined) ?? DefaultBaseLookupWidgetTemplate) as (
      props: WidgetProps<T, S, F>,
    ) => JSX.Element;

  return <BaseLookupWidgetTemplate {...props} />;
}
