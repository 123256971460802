import { useCallback, useState } from 'react';
import type { Editor } from '@tiptap/core';
import { useTranslation } from '@unifyapps/i18n/client';
import SvgLink03 from '@unifyapps/icons/outline/Link03';
import { Button } from '@unifyapps/ui/components/Button';
import { Input } from '@unifyapps/ui/components/Input';
import { Popover } from '@unifyapps/ui/components/Popover';
import Stack from '@unifyapps/ui/_components/Stack';
import { Toolbar } from '../Toolbar';

const URL_REGEX =
  /^(?:http|https):\/\/[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%._\\+~#?&//=]*)/;

interface AddLinkMenuContentProps {
  initialUrl?: string;
  onSetLink: (url: string) => void;
}

export function AddLinkMenuContent({ initialUrl, onSetLink }: AddLinkMenuContentProps) {
  const { t } = useTranslation();
  const [url, setUrl] = useState(initialUrl || '');
  const disabled = !url || !URL_REGEX.test(url);

  return (
    <Stack alignItems="center" className="p-md gap-x-lg" direction="row">
      {/* eslint-disable-next-line jsx-a11y/no-autofocus -- for better ux */}
      <Input autoFocus onChangeValue={setUrl} placeholder={t('common:EnterURL')} value={url} />
      <Button
        color="brand"
        disabled={disabled}
        onClick={() => onSetLink(url)}
        size="md"
        variant="solid"
      >
        {t('common:SetLink')}
      </Button>
    </Stack>
  );
}

function AddLinkMenu({ editor }: { editor: Editor }) {
  const [open, setOpen] = useState(false);

  const onSetLink = useCallback(
    (url: string) => {
      editor.chain().focus().setLink({ href: url, target: '_blank' }).run();
      setOpen(false);
    },
    [editor],
  );

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <Popover.Button>
        <Toolbar.Button Icon={SvgLink03} />
      </Popover.Button>
      <Popover.Content placement="bottom">
        <AddLinkMenuContent onSetLink={onSetLink} />
      </Popover.Content>
    </Popover>
  );
}

export default AddLinkMenu;
