import type { Ref } from 'react';
import React, { forwardRef } from 'react';
import type { IconButtonProps } from '@rjsf/utils';
import { TranslatableString } from '@rjsf/utils';
import { Button } from '@unifyapps/ui/components/Button';

const CopyButton = forwardRef((props: IconButtonProps, ref: Ref<HTMLButtonElement> | undefined) => {
  const {
    icon,
    registry: { translateString },
    disabled,
  } = props;

  return (
    <Button color="brand" disabled={disabled} ref={ref} size="sm" variant="solid">
      {icon} {translateString(TranslatableString.CopyButton)}
    </Button>
  );
});

CopyButton.displayName = 'CopyButton';

export default CopyButton;
