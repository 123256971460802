import { useEffect, useRef } from 'react';
import type { FormType } from '../types';

interface UseFormAutoFocusProps {
  autofocus?: boolean;
}

export const useFormAutoFocus = (props: UseFormAutoFocusProps) => {
  const formRef = useRef<FormType>(null);
  const { autofocus } = props;

  useEffect(() => {
    if (!autofocus || !formRef.current) {
      return;
    }

    const form = formRef.current.formElement.current as HTMLFormElement | null;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- can be undefined
    const firstElement = form?.elements?.item(0) as HTMLElement | null;
    firstElement?.focus();
  }, [autofocus]);

  return formRef;
};
