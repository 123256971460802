import React from 'react';
import { getUiOptions, type DescriptionFieldProps } from '@rjsf/utils';
import type {
  TypographyColors,
  TypographyProps,
  TypographyVariants,
} from '@unifyapps/ui/components/Typography';
import { Typography } from '@unifyapps/ui/components/Typography';

// ALL description in the Form
function DescriptionFieldTemplate(props: DescriptionFieldProps) {
  const { id, description, uiSchema } = props;

  if (!description) {
    return null;
  }

  const uiOptions = getUiOptions(uiSchema ?? {});

  const descriptionStyles =
    (
      props.registry.globalUiOptions as
        | { descriptionStyles?: Pick<TypographyProps, 'color' | 'weight' | 'variant'> }
        | undefined
    )?.descriptionStyles || {};

  const typographyProps: Pick<TypographyProps, 'color' | 'weight' | 'variant'> = {
    color: (descriptionStyles.color ||
      uiOptions.descriptionColor ||
      'text-tertiary') as TypographyColors,
    variant: (descriptionStyles.variant ||
      uiOptions.descriptionVariant ||
      'text-xs') as TypographyVariants,
    weight: descriptionStyles.weight || 'regular',
  };

  return (
    <Typography id={id} {...typographyProps}>
      {description}
    </Typography>
  );
}

export default DescriptionFieldTemplate;
