import type { Node } from '@tiptap/pm/model';
import type { Editor } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import { useCallback, useRef } from 'react';
import { cn } from '../../../lib/utils';

interface ImageBlockViewProps {
  editor: Editor;
  getPos: () => number;
  node: Node & {
    attrs: {
      src: string;
    };
  };
  updateAttributes: (attrs: Record<string, string>) => void;
}

export function ImageBlockView(props: ImageBlockViewProps) {
  const { editor, getPos, node } = props;
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const { src } = node.attrs;

  const wrapperClassName = cn(
    node.attrs.align === 'left' ? 'ms-0' : 'ms-auto',
    node.attrs.align === 'right' ? 'me-0' : 'me-auto',
    node.attrs.align === 'center' && 'mx-auto',
  );

  const onClick = useCallback(() => {
    editor.commands.setNodeSelection(getPos());
  }, [getPos, editor.commands]);

  return (
    <NodeViewWrapper>
      <div className={wrapperClassName} style={{ width: node.attrs.width }}>
        <div contentEditable={false} ref={imageWrapperRef}>
          <img alt="" className="block" onClick={onClick} src={src} />
        </div>
      </div>
    </NodeViewWrapper>
  );
}

export default ImageBlockView;
