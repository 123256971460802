import { useState } from 'react';
import { useDebounce } from 'react-use';

function useDebounceValue<T = string>(
  { delay = 400, initialValue }: { initialValue?: T | (() => T); delay?: number } = { delay: 400 },
) {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  useDebounce(
    () => {
      setDebouncedValue(value);
    },
    delay,
    [value],
  );

  return {
    value,
    setValue,
    debouncedValue,
  };
}

export default useDebounceValue;
