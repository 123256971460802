import type { ChangeEventHandler } from 'react';
import { useCallback } from 'react';
import type { WidgetProps } from '@rjsf/utils';
import _map from 'lodash/map';
import { Toggle } from '@unifyapps/ui/components/Toggle';
import getUaOptions from '../../utils/getUaOptions';
import { getUiOptions } from '../../utils/getUiOptions';

export default function ToggleWidget(props: WidgetProps) {
  const { registry, uiSchema, onChange, readonly, disabled } = props;

  const value = props.value as boolean;

  const { size } = getUiOptions({
    uiSchema,
    registry,
  }) as {
    size?: 'sm';
  };

  const uaOptions = getUaOptions(uiSchema) as {
    orientation: 'column' | 'row';
    defaultChecked?: boolean;
  };

  const { orientation } = uaOptions;

  const handleChange = useCallback(
    (event) => {
      onChange(event.target.checked);
    },
    [onChange],
  ) as ChangeEventHandler<HTMLInputElement>;

  return (
    <Toggle
      checked={value}
      // as toggle adds align-self center so add this will ensure toggle
      //render at right place
      className={orientation === 'column' ? 'self-end' : 'self-start'}
      defaultChecked={uaOptions.defaultChecked}
      disabled={disabled}
      onChange={handleChange}
      readOnly={readonly}
      // for small variant the toggle should be xs
      size={size === 'sm' ? 'xs' : undefined}
    />
  );
}
