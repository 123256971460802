import { useCallback, useState, useMemo } from 'react';
import type { Editor } from '@tiptap/core';
import { BubbleMenu } from '@tiptap/react';
import { AddLinkMenuContent } from '../SelectionMenu/AddLinkMenu';
import { Toolbar } from '../Toolbar';
import LinkPreviewPanel from './LinkPreviewPanel';

interface LinkMenuProps {
  editor: Editor;
  editable?: boolean;
}

function LinkMenu({ editor, editable }: LinkMenuProps) {
  const [showEdit, setShowEdit] = useState(false);

  const shouldShow = useCallback(() => {
    return editor.isActive('link');
  }, [editor]);

  const { href: link } = editor.getAttributes('link');

  const handleEdit = useCallback(() => {
    setShowEdit(true);
  }, []);

  const onSetLink = useCallback(
    (url: string) => {
      editor.chain().focus().extendMarkRange('link').setLink({ href: url, target: '_blank' }).run();
      setShowEdit(false);
    },
    [editor],
  );

  const onUnsetLink = useCallback(() => {
    editor.chain().focus().extendMarkRange('link').unsetLink().run();
    setShowEdit(false);
    return null;
  }, [editor]);

  const tippyOptions = useMemo(
    () => ({
      popperOptions: {
        modifiers: [{ name: 'flip', enabled: false }],
      },
      appendTo: 'parent' as const,
      onHidden: () => {
        setShowEdit(false);
      },
    }),
    [],
  );

  return (
    <BubbleMenu
      editor={editor}
      pluginKey="LinkMenu"
      shouldShow={editable ? shouldShow : undefined}
      tippyOptions={tippyOptions}
      updateDelay={0}
    >
      <Toolbar.Wrapper className="rounded-md shadow-sm">
        {showEdit ? (
          <AddLinkMenuContent initialUrl={link} onSetLink={onSetLink} />
        ) : (
          <LinkPreviewPanel onDelete={onUnsetLink} onEdit={handleEdit} url={link} />
        )}
      </Toolbar.Wrapper>
    </BubbleMenu>
  );
}

export default LinkMenu;
