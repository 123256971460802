/* eslint-disable @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-argument,@typescript-eslint/ban-ts-comment -- required */
import { Component, useCallback, useMemo } from 'react';
import type {
  ErrorSchema,
  FieldProps,
  FieldTemplateProps,
  Registry,
  UIOptionsType,
} from '@rjsf/utils';
import {
  ADDITIONAL_PROPERTY_FLAG,
  descriptionId,
  getSchemaType,
  getTemplate,
  getUiOptions,
  ID_KEY,
  TranslatableString,
  UI_OPTIONS_KEY,
} from '@rjsf/utils';
import isEqual from 'react-fast-compare';
import _isObject from 'lodash/isObject';
import omit from 'lodash/omit';
import type { JSONSchema7 } from 'json-schema';
import { getFieldIdSchema, getShouldResolveSchema } from './utils';

/** The map of component type to FieldName */
const COMPONENT_TYPES: Record<string, string> = {
  array: 'ArrayField',
  boolean: 'BooleanField',
  integer: 'NumberField',
  number: 'NumberField',
  object: 'ObjectField',
  string: 'StringField',
  null: 'NullField',
};

/** Computes and returns which `Field` implementation to return in order to render the field represented by the
 * `schema`. The `uiOptions` are used to alter what potential `Field` implementation is actually returned. If no
 * appropriate `Field` implementation can be found then a wrapper around `UnsupportedFieldTemplate` is used.
 *
 * @param schema - The schema from which to obtain the type
 * @param uiOptions - The UI Options that may affect the component decision
 * @param registry - The registry from which fields and templates are obtained
 * @returns - The `Field` component that is used to render the actual field data
 */
function getFieldComponent(schema: JSONSchema7, uiOptions: UIOptionsType, registry: Registry) {
  const field = uiOptions.field;
  const { fields, translateString } = registry;
  if (typeof field === 'function') {
    return field;
  }
  if (typeof field === 'string' && field in fields) {
    return fields[field];
  }

  const schemaType = getSchemaType(schema);
  const type: string = Array.isArray(schemaType) ? schemaType[0] : schemaType || '';

  const schemaId = schema.$id;

  let componentName = COMPONENT_TYPES[type];
  if (schemaId && schemaId in fields) {
    componentName = schemaId;
  }

  // If the type is not defined and the schema uses 'anyOf' or 'oneOf', don't
  // render a field and let the MultiSchemaField component handle the form display
  if (!componentName && (schema.anyOf || schema.oneOf)) {
    return () => null;
  }

  return componentName in fields
    ? fields[componentName]
    : () => {
        const UnsupportedFieldTemplate = getTemplate<'UnsupportedFieldTemplate'>(
          'UnsupportedFieldTemplate',
          registry,
          uiOptions,
        );

        return (
          <UnsupportedFieldTemplate
            reason={translateString(TranslatableString.UnknownFieldType, [String(schema.type)])}
            registry={registry}
            schema={schema}
          />
        );
      };
}

/** The `SchemaFieldRender` component is the work-horse of react-jsonschema-form, determining what kind of real field to
 * render based on the `schema`, `uiSchema` and all the other props. It also deals with rendering the `anyOf` and
 * `oneOf` fields.
 *
 * @param props - The `FieldProps` for this component
 */
function SchemaFieldRender(props: FieldProps) {
  const {
    schema: _schema,
    idSchema: _idSchema,
    uiSchema,
    formData,
    errorSchema,
    idPrefix,
    idSeparator,
    name,
    onChange,
    onKeyChange,
    onDropPropertyClick,
    required,
    registry,
    wasPropertyKeyModified = false,
  } = props;
  const { formContext, schemaUtils, globalUiOptions } = registry;
  const uiOptions = getUiOptions(uiSchema, globalUiOptions);
  const FieldTemplate = getTemplate<'FieldTemplate'>('FieldTemplate', registry, uiOptions);
  const DescriptionFieldTemplate = getTemplate<'DescriptionFieldTemplate'>(
    'DescriptionFieldTemplate',
    registry,
    uiOptions,
  );
  const FieldHelpTemplate = getTemplate<'FieldHelpTemplate'>(
    'FieldHelpTemplate',
    registry,
    uiOptions,
  );
  const FieldErrorTemplate = getTemplate<'FieldErrorTemplate'>(
    'FieldErrorTemplate',
    registry,
    uiOptions,
  );

  const schema = getShouldResolveSchema(_schema)
    ? schemaUtils.retrieveSchema(_schema, formData)
    : _schema;

  const idSchema = useMemo(() => {
    return getFieldIdSchema({ idSchema: props.idSchema, idPrefix: props.idPrefix });
  }, [props.idPrefix, props.idSchema]);
  const $id = idSchema[ID_KEY];

  /** Intermediary `onChange` handler for field components that will inject the `id` of the current field into the
   * `onChange` chain if it is not already being provided from a deeper level in the hierarchy
   */
  const handleFieldComponentChange = useCallback(
    (_formData: unknown | undefined, newErrorSchema?: ErrorSchema, id?: string) => {
      const theId = id || $id;
      return onChange(_formData, newErrorSchema, theId);
    },
    [$id, onChange],
  );

  const FieldComponent = getFieldComponent(
    schema,
    uiOptions,
    registry,
  ) as unknown as React.Component;
  const disabled = Boolean(props.disabled || uiOptions.disabled);
  const readonly = Boolean(
    props.readonly || uiOptions.readonly || props.schema.readOnly || schema.readOnly,
  );
  const uiSchemaHideError = uiOptions.hideError;
  // Set hideError to the value provided in the uiSchema, otherwise stick with the prop to propagate to children
  const hideError = uiSchemaHideError === undefined ? props.hideError : Boolean(uiSchemaHideError);
  const autofocus = Boolean(props.autofocus || uiOptions.autofocus);
  if (Object.keys(schema).length === 0) {
    return null;
  }

  const displayLabel = schemaUtils.getDisplayLabel(schema, uiSchema, globalUiOptions);

  const { __errors, ...fieldErrorSchema } = errorSchema || {};
  // See #439: uiSchema: Don't pass consumed class names or style to child components
  const fieldUiSchema = omit(uiSchema, ['ui:classNames', 'classNames', 'ui:style']);
  if (UI_OPTIONS_KEY in fieldUiSchema) {
    fieldUiSchema[UI_OPTIONS_KEY] = omit(fieldUiSchema[UI_OPTIONS_KEY], ['classNames', 'style']);
  }

  const field = (
    // @ts-expect-error
    <FieldComponent
      {...props}
      autofocus={autofocus}
      disabled={disabled}
      errorSchema={fieldErrorSchema}
      formContext={formContext}
      hideError={hideError}
      onChange={handleFieldComponentChange}
      rawErrors={__errors}
      readonly={readonly}
      schema={schema}
      uiSchema={fieldUiSchema}
    />
  );

  // If this schema has a title defined, but the user has set a new key/label, retain their input.
  let label;
  if (wasPropertyKeyModified) {
    label = name;
  } else {
    label =
      ADDITIONAL_PROPERTY_FLAG in schema
        ? name
        : uiOptions.title || props.schema.title || schema.title || props.title || name;
  }

  const description = uiOptions.description || props.schema.description || schema.description || '';

  const help = uiOptions.help;
  const hidden = uiOptions.widget === 'hidden';

  const classNames = ['form-group', 'field', `field-${getSchemaType(schema) as string}`];
  if (!hideError && __errors && __errors.length > 0) {
    classNames.push('field-error has-error has-danger');
  }
  if (uiSchema?.classNames) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(
        "'uiSchema.classNames' is deprecated and may be removed in a major release; Use 'ui:classNames' instead.",
      );
    }
    classNames.push(uiSchema.classNames);
  }
  if (uiOptions.classNames) {
    classNames.push(uiOptions.classNames);
  }

  const helpComponent = (
    <FieldHelpTemplate
      hasErrors={!hideError && __errors ? __errors.length > 0 : undefined}
      help={help}
      idSchema={idSchema}
      registry={registry}
      schema={schema}
      uiSchema={uiSchema}
    />
  );
  /*
   * AnyOf/OneOf errors handled by child schema
   * unless it can be rendered as select control
   */
  const errorsComponent =
    hideError || ((schema.anyOf || schema.oneOf) && !schemaUtils.isSelect(schema)) ? undefined : (
      <FieldErrorTemplate
        errorSchema={errorSchema}
        errors={__errors}
        idSchema={idSchema}
        registry={registry}
        schema={schema}
        uiSchema={uiSchema}
      />
    );
  const fieldProps: Omit<FieldTemplateProps, 'children'> = {
    description: (
      <DescriptionFieldTemplate
        description={description}
        id={descriptionId($id)}
        registry={registry}
        schema={schema}
        uiSchema={uiSchema}
      />
    ),
    rawDescription: description,
    help: helpComponent,
    rawHelp: typeof help === 'string' ? help : undefined,
    errors: errorsComponent,
    rawErrors: hideError ? undefined : __errors,
    id: $id,
    label,
    hidden,
    onChange,
    onKeyChange,
    onDropPropertyClick,
    required,
    disabled,
    readonly,
    hideError,
    displayLabel,
    classNames: classNames.join(' ').trim(),
    style: uiOptions.style,
    formContext,
    formData,
    schema,
    uiSchema,
    registry,
  };

  const _AnyOfField = registry.fields.AnyOfField;
  const _OneOfField = registry.fields.OneOfField;
  const isReplacingAnyOrOneOf =
    uiSchema?.['ui:field'] && uiSchema['ui:fieldReplacesAnyOrOneOf'] === true;

  return (
    <FieldTemplate {...fieldProps}>
      <>
        {field}
        {/*
         If the schema `anyOf` or 'oneOf' can be rendered as a select control, don't
         render the selection and let `StringField` component handle
         rendering
         */}
        {schema.anyOf && !isReplacingAnyOrOneOf && !schemaUtils.isSelect(schema) ? (
          <_AnyOfField
            disabled={disabled}
            errorSchema={errorSchema}
            formContext={formContext}
            formData={formData}
            hideError={hideError}
            idPrefix={idPrefix}
            idSchema={idSchema}
            idSeparator={idSeparator}
            name={name}
            onBlur={props.onBlur}
            onChange={props.onChange}
            onFocus={props.onFocus}
            options={schema.anyOf.map((__schema) => {
              if (!_isObject(__schema)) return {};
              return getShouldResolveSchema(__schema)
                ? schemaUtils.retrieveSchema(__schema, formData)
                : __schema;
            })}
            readonly={readonly}
            registry={registry}
            schema={schema}
            uiSchema={uiSchema}
          />
        ) : null}
        {schema.oneOf && !isReplacingAnyOrOneOf && !schemaUtils.isSelect(schema) ? (
          <_OneOfField
            disabled={disabled}
            errorSchema={errorSchema}
            formContext={formContext}
            formData={formData}
            hideError={hideError}
            idPrefix={idPrefix}
            idSchema={idSchema}
            idSeparator={idSeparator}
            name={name}
            onBlur={props.onBlur}
            onChange={props.onChange}
            onFocus={props.onFocus}
            options={schema.oneOf.map((__schema) => {
              if (!_isObject(__schema)) return {};
              return getShouldResolveSchema(__schema)
                ? schemaUtils.retrieveSchema(__schema, formData)
                : __schema;
            })}
            readonly={readonly}
            registry={registry}
            schema={schema}
            uiSchema={uiSchema}
          />
        ) : null}
      </>
    </FieldTemplate>
  );
}

/** The `SchemaField` component determines whether it is necessary to rerender the component based on any props changes
 * and if so, calls the `SchemaFieldRender` component with the props.
 */
export default class SchemaField extends Component<FieldProps> {
  shouldComponentUpdate(nextProps: Readonly<FieldProps>) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    return <SchemaFieldRender {...this.props} />;
  }
}
