import type { IconComponentType } from '@unifyapps/ui/types/index';
import Stack from '@unifyapps/ui/_components/Stack';
import { memo } from 'react';
import { clsx } from 'clsx';

type IconWrapProps = {
  className?: string; // when passing p-xxs, p-xs repeatedly, start using cva
  iconClassName?: string;
} & (
  | {
      Icon: IconComponentType;
      iconClassName?: string;
    }
  | {
      src: string;
      alt?: string;
    }
);

function IconWrap(props: IconWrapProps) {
  return (
    <Stack
      alignItems="center"
      className={clsx('size-full flex-shrink-0', props.className)}
      justifyContent="center"
    >
      {'Icon' in props ? (
        <props.Icon className={clsx('text-fg-secondary size-5', props.iconClassName)} />
      ) : null}
      {'src' in props ? (
        <img
          className={props.iconClassName}
          loading="lazy"
          alt={props.alt}
          // className="size-5"
          src={props.src}
        />
      ) : null}
    </Stack>
  );
}

export default memo(IconWrap);
