import type { WidgetProps } from '@rjsf/utils';
import {
  ariaDescribedByIds,
  descriptionId,
  enumOptionsIsSelected,
  optionId,
  titleId,
} from '@rjsf/utils';
import { Checkbox } from '@unifyapps/ui/components/Checkbox';
import Stack from '@unifyapps/ui/_components/Stack';
import { clsx } from 'clsx';
import TitleAndDescription from '../../components/TitleAndDescription';
import { RadioLabel } from '../RadioWidget';
import { outlineVariantStyles } from '../RadioWidget/styles';
import useCheckboxesWidget from './useCheckboxesWidget';

/** The `CheckboxesWidget` is a widget for rendering checkbox groups.
 *  It is typically used to represent an array of enums.
 *
 * @param props - The `WidgetProps` for this component
 */
export default function CheckboxesWidget(props: WidgetProps) {
  const { id, disabled: _disabled, readonly, required, registry, schema, uiSchema, title } = props;

  const {
    _onBlur,
    _onChange,
    _onFocus,
    checkboxesValues,
    containerClassName,
    enumDisabled,
    isOutlined,
    enumOptions,
  } = useCheckboxesWidget(props);

  return (
    <Stack className="gap-y-sm" id={id}>
      <TitleAndDescription
        descriptionId={descriptionId(id)}
        registry={registry}
        required={required}
        schema={schema}
        title={title}
        titleId={titleId(id)}
        uiSchema={uiSchema}
      />
      <Stack className={clsx('gap-xl', containerClassName)} direction="row">
        {Array.isArray(enumOptions) &&
          enumOptions.map((option, index: number) => {
            const checked = enumOptionsIsSelected(option.value, checkboxesValues);
            const itemDisabled =
              Array.isArray(enumDisabled) && enumDisabled.includes(option.value as string);
            const disabled = _disabled || itemDisabled;

            return (
              <Checkbox
                aria-describedby={ariaDescribedByIds(id)}
                checked={checked}
                className={clsx(
                  'items-center',
                  isOutlined
                    ? outlineVariantStyles({ isSelected: checked, readonly, disabled })
                    : '',
                )}
                disabled={disabled}
                id={optionId(id, index)}
                key={index}
                label={<RadioLabel description={option.schema?.description} title={option.label} />}
                name={id}
                onBlur={_onBlur}
                onChange={_onChange(index)}
                onFocus={_onFocus}
                readOnly={readonly}
              />
            );
          })}
      </Stack>
    </Stack>
  );
}
