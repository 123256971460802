import { useCallback, useEffect, useState } from 'react';
import { Popover } from '@unifyapps/ui/components/Popover';
import { MenuList } from '@unifyapps/ui/components/Menu';
import type { MenuListProps } from '@unifyapps/ui/components/Menu/MenuList';
import { Icon } from '../../../../components/ui/Icon';
import type { MenuProps } from '../../../../components/menus/types';

const items: MenuListProps['items'] = [
  {
    title: 'Delete',
    endDecoratorNode: <Icon name="Trash" />,
    id: 'delete',
    type: 'label',
  },
];
export function TableCornerMenu({ editor }: MenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onDelete = useCallback(() => {
    editor.chain().focus().deleteTable().run();
    setIsOpen(false);
  }, [editor]);

  // Effect to update the menu state based on editor changes
  useEffect(() => {
    const updateMenu = () => {
      const gripCorner = document.querySelector('.grip-corner');
      if (gripCorner instanceof HTMLElement) setAnchorEl(gripCorner);
    };

    // Listen to editor updates
    editor.on('transaction', updateMenu);
    editor.on('selectionUpdate', updateMenu);
    editor.on('update', updateMenu);

    // Cleanup listeners on unmount
    return () => {
      editor.off('transaction', updateMenu);
      editor.off('selectionUpdate', updateMenu);
      editor.off('update', updateMenu);
    };
  }, [editor]);

  useEffect(() => {
    if (!anchorEl) return;

    const handleMouseUp = (event: MouseEvent) => {
      if (anchorEl.contains(event.target as Node) || anchorEl === event.target) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    anchorEl.addEventListener('mouseup', handleMouseUp);

    return () => {
      anchorEl.removeEventListener('mouseup', handleMouseUp);
    };
  }, [anchorEl]);

  const onAction: MenuListProps['onAction'] = useCallback(
    (id) => {
      switch (id) {
        case 'delete':
          onDelete();
          break;
        default:
          break;
      }
    },
    [onDelete],
  );

  if (!anchorEl) return null;

  return (
    <Popover anchorEl={anchorEl} onOpenChange={setIsOpen} open={isOpen}>
      <Popover.Content anchorEl={anchorEl} offset={8}>
        <MenuList items={items} onAction={onAction} />
      </Popover.Content>
    </Popover>
  );
}

TableCornerMenu.displayName = 'TableCornerMenu';
