'use client';

import Tippy from '@tippyjs/react/headless';
import React, { useCallback } from 'react';
import type { TippyProps, TooltipProps } from './types';

const isMac =
  typeof window !== 'undefined' ? navigator.platform.toUpperCase().includes('MAC') : false;

function ShortcutKey({ children }: { children: string }): JSX.Element {
  const className =
    'inline-flex items-center justify-center w-5 h-5 p-1 text-[0.625rem] rounded-xs font-semibold leading-none border border-utility-gray-100 text-utility-gray-400 border-b-2';

  if (children === 'Mod') {
    return <kbd className={className}>{isMac ? '⌘' : 'Ctrl'}</kbd>; // ⌃
  }

  if (children === 'Shift') {
    return <kbd className={className}>⇧</kbd>;
  }

  if (children === 'Alt') {
    return <kbd className={className}>{isMac ? '⌥' : 'Alt'}</kbd>;
  }

  return <kbd className={className}>{children}</kbd>;
}

export function Tooltip({
  children,
  enabled = true,
  title,
  shortcut,
  tippyOptions = {},
}: TooltipProps): JSX.Element {
  const renderTooltip = useCallback(
    (attrs: TippyProps) => (
      <span
        className="border-utility-gray-50 bg-alpha-white-100 z-[999] flex items-center gap-2 rounded-lg border px-2.5 py-1 shadow-sm"
        data-escaped={attrs['data-escaped']}
        data-placement={attrs['data-placement']}
        data-reference-hidden={attrs['data-reference-hidden']}
        tabIndex={-1}
      >
        {title ? <span className="text-utility-gray-400 text-xs font-medium">{title}</span> : null}
        {shortcut ? (
          <span className="flex items-center gap-0.5">
            {shortcut.map((shortcutKey) => (
              <ShortcutKey key={shortcutKey}>{shortcutKey}</ShortcutKey>
            ))}
          </span>
        ) : null}
      </span>
    ),
    [shortcut, title],
  );

  if (enabled) {
    return (
      <Tippy
        delay={500}
        offset={[0, 8]}
        touch={false}
        zIndex={99999}
        appendTo={document.body}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...tippyOptions}
        render={renderTooltip}
      >
        <span>{children}</span>
      </Tippy>
    );
  }

  return <>{children}</>;
}

export default Tooltip;
