import { useMemo } from 'react';
import type { ArrayFieldTemplateItemType, UiSchema } from '@rjsf/utils';
import Stack from '@unifyapps/ui/_components/Stack';
import { clsx } from 'clsx';
import getUaOptions from '../../utils/getUaOptions';
import { useFormContext } from '../../context';

type ArrayFieldItemUAOptions = {
  itemClassName?: string;
  actionItems?: {
    disableShuffling?: boolean;
    rootClassName?: string;
    removeButtonIcon?: string;
  };
};

function ArrayFieldItemTemplate(props: ArrayFieldTemplateItemType) {
  const {
    children,
    className,
    disabled,
    hasToolbar,
    hasCopy,
    hasMoveDown,
    hasMoveUp,
    hasRemove,
    index,
    onCopyIndexClick,
    onDropIndexClick,
    onReorderClick,
    readonly,
    uiSchema,
    registry,
  } = props;

  const { formContext } = useFormContext();
  const { CopyButton, MoveDownButton, MoveUpButton, RemoveButton } =
    registry.templates.ButtonTemplates;

  const uaOptions = useMemo(
    () => getUaOptions<ArrayFieldItemUAOptions>(uiSchema as UiSchema),
    [uiSchema],
  );

  const isRowLayout = formContext?.layout === 'row';

  const { itemClassName, actionItems } = uaOptions as ArrayFieldItemUAOptions;

  return (
    <Stack
      className={clsx(className, 'array-field-item-template relative flex w-full', itemClassName)}
    >
      <Stack className="gap-y-xl flex-1 flex-col">{children}</Stack>
      <Stack
        className={clsx(
          'absolute',
          actionItems?.rootClassName,
          isRowLayout ? 'end-16 top-4' : 'end-0 top-0 h-5',
        )}
      >
        {hasToolbar && !readonly ? (
          <Stack className="flex-row items-center gap-x-1">
            {!actionItems?.disableShuffling && (hasMoveUp || hasMoveDown) ? (
              <MoveUpButton
                disabled={disabled || !hasMoveUp}
                onClick={onReorderClick(index, index - 1)}
                registry={registry}
                uiSchema={uiSchema}
              />
            ) : null}
            {!actionItems?.disableShuffling && (hasMoveUp || hasMoveDown) ? (
              <MoveDownButton
                disabled={disabled || !hasMoveDown}
                onClick={onReorderClick(index, index + 1)}
                registry={registry}
                uiSchema={uiSchema}
              />
            ) : null}
            {hasCopy ? (
              <CopyButton
                disabled={disabled}
                onClick={onCopyIndexClick(index)}
                registry={registry}
                uiSchema={uiSchema}
              />
            ) : null}
            {hasRemove ? (
              <RemoveButton
                disabled={disabled}
                onClick={onDropIndexClick(index)}
                registry={registry}
                uiSchema={uiSchema}
              />
            ) : null}
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
}

export default ArrayFieldItemTemplate;
