import type { HTMLProps } from 'react';
import { forwardRef } from 'react';
import { cn } from '../../lib/utils';

export type SurfaceProps = HTMLProps<HTMLDivElement> & {
  withShadow?: boolean;
  withBorder?: boolean;
};

export const Surface = forwardRef<HTMLDivElement, SurfaceProps>(
  ({ children, className, withShadow = true, withBorder = true, ...props }, ref) => {
    const surfaceClass = cn(
      className,
      'bg-alpha-white-100 rounded-lg',
      withShadow ? 'shadow-sm' : '',
      withBorder ? 'border border-utility-gray-100' : '',
    );

    return (
      <div className={surfaceClass} {...props} ref={ref}>
        {children}
      </div>
    );
  },
);

Surface.displayName = 'Surface';
