import { cva } from 'class-variance-authority';

export const inputSizeVariants = cva('text-primary w-full rounded-b-md', {
  variants: {
    size: {
      sm: 'px-md py-sm min-h-7 text-xs bg-primary',
      md: 'px-lg py-md min-h-9 text-md md:text-sm bg-primary',
    },
  },
});
