// TODO: do we need this?
import React from 'react';
import type { ArrayFieldTitleProps } from '@rjsf/utils';
import { getUiOptions, titleId } from '@rjsf/utils';
import type { TypographyVariants } from '@unifyapps/ui/components/Typography';
import { Typography } from '@unifyapps/ui/components/Typography';
import { Box } from '@unifyapps/ui/components/Box';
import { clsx } from 'clsx';
import { getDerivedTitle } from '../TitleFieldTemplate';

function ArrayFieldTitleTemplate(props: ArrayFieldTitleProps) {
  const { title, idSchema, required, uiSchema, schema, registry } = props;
  const id = titleId(idSchema);

  if (!title) {
    return null;
  }
  const globalUiOptions = registry.globalUiOptions;
  const uiOptions = getUiOptions(uiSchema ?? {}, globalUiOptions);

  return (
    <Typography
      className={clsx(uiOptions.titleClassName as string | undefined, 'flex items-center')}
      color="text-secondary"
      id={id}
      variant={(uiOptions.titleVariant as TypographyVariants | undefined) || 'text-sm'}
      weight="medium"
    >
      {getDerivedTitle({ uiOptions, schema, title, id })}
      {required ? (
        <Box as="span" className="text-secondary">
          *
        </Box>
      ) : null}
    </Typography>
  );
}

export default ArrayFieldTitleTemplate;
