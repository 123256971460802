import { getSchemaType } from '@rjsf/utils';
import type { WidgetProps } from '@rjsf/utils/lib/types';
import TitleAndDescription from '@unifyapps/form/components/TitleAndDescription/TitleAndDescription';
import Stack from '@unifyapps/ui/_components/Stack';
import clsx from 'clsx';

function VerticalAlign(
  props: React.PropsWithChildren<
    Pick<
      WidgetProps,
      'id' | 'registry' | 'required' | 'schema' | 'title' | 'uiSchema' | 'hideLabel'
    >
  >,
) {
  const isArrayType = getSchemaType(props.schema) === 'array';

  if (!isArrayType) return props.children;

  return (
    <>
      <TitleAndDescription
        descriptionId={`${props.id}_description`}
        hideLabel={props.hideLabel}
        registry={props.registry}
        required={props.required}
        schema={props.schema}
        title={props.title}
        titleId={props.id}
        uiSchema={props.uiSchema}
      />
      {props.children}
    </>
  );
}

function LeftJustify(
  props: React.PropsWithChildren<
    Pick<
      WidgetProps,
      'id' | 'registry' | 'required' | 'schema' | 'title' | 'uiSchema' | 'hideLabel' | 'options'
    >
  >,
) {
  const isArrayType = getSchemaType(props.schema) === 'array';

  if (!isArrayType) return props.children;

  return (
    <Stack className="gap-4xl items-center" direction="row" justifyContent="space-between">
      <Stack className={clsx('w-1/2', props.options.fieldClassName)} direction="column">
        <TitleAndDescription
          className="gap-y-xxs"
          descriptionId={`${props.id}_description`}
          hideLabel={props.hideLabel}
          registry={props.registry}
          required={props.required}
          schema={props.schema}
          title={props.title}
          titleId={props.id}
          uiSchema={props.uiSchema}
        />
      </Stack>
      <Stack className="flex min-w-60 flex-1 shrink-0">{props.children}</Stack>
    </Stack>
  );
}

export { VerticalAlign, LeftJustify };
