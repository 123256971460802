import { Tooltip } from '@unifyapps/ui/components/Tooltip';
import { Box } from '@unifyapps/ui/components/Box';
import { Typography as BaseTypography } from '@unifyapps/ui/components/Typography';
import { Button as BaseButton } from '@unifyapps/ui/components/Button';
import Copy01 from '@unifyapps/icons/outline/Copy01';
import { useCopyToClipboard } from 'react-use';
import React, { useCallback } from 'react';
import { useTranslation } from '@unifyapps/i18n/client';
import useToggle from '@unifyapps/hooks/useToggle';
import CheckCircle from '@unifyapps/icons/outline/CheckCircle';
import { useTimeoutEffect } from '@react-hookz/web';
import { useSlot } from '@unifyapps/ui/slots';
import type { CreateSlotsAndSlotProps, SlotProps } from '@unifyapps/ui/slots/types';
import { clsx } from 'clsx';

/**
 * Renders input like box with copy button at the end, but not input since it's read only
 */
const DEFAULT_ROOT_CLASSNAME =
  'gap-md bg-disabled_subtle text-disabled border-disabled_subtle ps-lg py-md pe-xs flex max-h-10 w-full grow-0 flex-row items-center overflow-hidden rounded-md border text-sm transition-all';

export default function CopyBox({
  text,
  placeholder,
  slotProps,
  slots,
  disableTooltip,
}: { text?: string; placeholder?: string; disableTooltip?: boolean } & CreateSlotsAndSlotProps<
  { root?: React.ElementType; typography?: React.ElementType; button?: React.ElementType },
  {
    root: SlotProps<React.ElementType, object, object>;
    typography: SlotProps<React.ElementType, object, object>;
    button: SlotProps<React.ElementType, object, object>;
  }
>) {
  const { t } = useTranslation();
  const [, copyToClipboard] = useCopyToClipboard();
  const [showSuccess, showSuccessConfig] = useToggle(false);

  const [Root, rootProps] = useSlot('root', {
    elementType: Box,
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: DEFAULT_ROOT_CLASSNAME,
    ref: null,
  });
  const [Typography, typographyProps] = useSlot('typography', {
    elementType: BaseTypography,
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: 'me-auto flex-shrink overflow-hidden text-wrap break-all',
  });

  const [Button, buttonProps] = useSlot('button', {
    elementType: BaseButton,
    externalForwardedProps: { slots, slotProps },
    ownerState: {},
    className: '!min-w-0',
  });

  const [, clearAfterTimeout] = useTimeoutEffect(() => {
    showSuccessConfig.off();
  }, 3000);

  const onCopyToClipboard = useCallback(() => {
    copyToClipboard(text ?? '');

    showSuccessConfig.on();
    clearAfterTimeout();
  }, [clearAfterTimeout, copyToClipboard, showSuccessConfig, text]);

  if (!text && !placeholder) return null;

  let rightButton: React.ReactNode = null;
  if (text) {
    if (showSuccess) {
      rightButton = <CheckCircle className="text-fg-success-primary me-2 size-5" strokeWidth={2} />;
    } else {
      rightButton = (
        <Button
          color="neutral"
          onClick={onCopyToClipboard}
          size="sm"
          startDecoratorComponent={Copy01}
          variant="soft"
          {...buttonProps}
        >
          {t('common:Actions.Copy')}
        </Button>
      );
    }
  }

  return (
    <Root {...rootProps}>
      <Tooltip className="max-w-[300px] break-all" disabled={disableTooltip} title={text}>
        <Box
          className={clsx('flex flex-1', {
            'overflow-hidden': text,
            'select-none': !text,
          })}
        >
          <Typography
            color="text-primary"
            maxNumberOfLines={1}
            variant="text-sm"
            {...typographyProps}
            className={clsx(
              '!break-all',
              { 'blur-[3px]': !text && placeholder, 'select-none': !text },
              typographyProps.className,
            )}
          >
            {text || placeholder}
          </Typography>
        </Box>
      </Tooltip>
      {rightButton}
    </Root>
  );
}
