import { clsx } from 'clsx';
import _map from 'lodash/map';
import { Box } from '@unifyapps/ui/components/Box';
import { Typography } from '@unifyapps/ui/components/Typography';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import ChevronUp from '@unifyapps/icons/outline/ChevronUp';
import useToggle from '@unifyapps/hooks/useToggle';
import ChevronDown from '@unifyapps/icons/outline/ChevronDown';
import {
  getContainerClassName,
  getIconClassName,
  getHeaderTextClassName,
  getTextClassName,
} from './styles';

interface ErrorPanelProps {
  errors?: { title?: string; description: string; onClickTitle?: () => void }[];
  className?: string;
  color?: 'error' | 'warning' | 'success' | 'brand';
}

function ErrorMessageTitle(props: {
  title?: string;
  onClick?: () => void;
  color: ErrorPanelProps['color'];
}) {
  const textClassName = getTextClassName({ color: props.color });
  let title: JSX.Element | null = null;
  if (props.title) {
    title = (
      <Typography
        as="span"
        className={clsx(textClassName, { underline: props.onClick })}
        variant="text-sm"
        weight="medium"
      >
        {props.title}
      </Typography>
    );
    if (props.onClick) {
      title = (
        <button onClick={props.onClick} type="button">
          {title}
        </button>
      );
    }
  }

  if (title) {
    return (
      <>
        {title}
        <Typography as="span" className="pe-1" variant="text-sm">
          :
        </Typography>
      </>
    );
  }
  return null;
}

function ErrorMessage(props: {
  title?: string;
  description: string;
  onClickTitle?: () => void;
  color: ErrorPanelProps['color'];
}) {
  return (
    <Typography color="text-tertiary" variant="text-sm" weight="regular">
      <ErrorMessageTitle color={props.color} onClick={props.onClickTitle} title={props.title} />
      {props.description}
    </Typography>
  );
}

export function ErrorPanel({ errors, className, color = 'error' }: ErrorPanelProps) {
  const hasMultipleErrors = (errors?.length || 0) > 1;

  const [collapsed, { toggle }] = useToggle(false);

  const containerClass = getContainerClassName({ color, className });
  const headerTextClassName = getHeaderTextClassName({ color });
  const iconClassName = getIconClassName({ color });

  if (!errors || errors.length === 0) {
    return null;
  }

  if (!hasMultipleErrors) {
    return (
      <Box className={containerClass}>
        <ErrorMessage
          color={color}
          description={errors[0].description}
          onClickTitle={errors[0].onClickTitle}
          title={errors[0].title}
        />
      </Box>
    );
  }

  return (
    <Box className={containerClass}>
      <Box className="py-xs flex flex-row items-center justify-between">
        <Typography className={headerTextClassName} variant="text-sm" weight="semi-bold">
          {`${errors.length} errors`}
        </Typography>
        <IconButton
          Icon={collapsed ? ChevronDown : ChevronUp}
          className={iconClassName}
          color="neutral"
          onClick={toggle}
          size="sm"
          variant="soft"
        />
      </Box>
      {collapsed ? null : (
        <Box className="flex flex-col">
          {_map(errors, (error, index) => (
            <ErrorMessage
              color={color}
              description={error.description}
              key={index.toString()}
              onClickTitle={error.onClickTitle}
              title={error.title}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
