import { cva } from 'class-variance-authority';

export const getStartDecoratorIconClassName = cva('text-fg-quaternary flex-shrink-0', {
  variants: {
    size: {
      sm: 'size-[14px]',
      md: 'size-4',
    },
  },
});

export const getStartDecoratorNodeRootClassName = cva('cursor-pointer', {
  variants: {
    size: {
      sm: 'size-[14px]',
      md: 'size-4',
    },
  },
});
