import { clsx } from 'clsx';
import _isUndefined from 'lodash/isUndefined';
import type { IdSchema, Registry, UiSchema } from '@rjsf/utils';
import getUaOptions from '../../utils/getUaOptions';
import type { UAObjectFieldTemplatePropertyType } from './types';

export function getContentNode(element: UAObjectFieldTemplatePropertyType) {
  return element.getContent ? element.getContent() : element.content;
}

export function isRootNode(idSchema: IdSchema) {
  // default idPrefix of rjsf is 'root'
  return idSchema.$id === 'root';
}

export function getBackgroundClass({
  toggleValue, // boolean used to toggle between different backgrounds
  disableBackgroundToggle,
  size,
}: {
  toggleValue: boolean;
  disableBackgroundToggle: boolean;
  // size of form
  size?: 'sm' | 'md';
}) {
  return disableBackgroundToggle
    ? undefined
    : clsx(
        'border-secondary border rounded-md',
        toggleValue ? 'bg-secondary' : 'bg-secondary_subtle',
        size === 'sm' ? 'p-md' : 'p-lg',
      );
}

export function getIsCollapsible({
  isRoot,
  uiSchema,
  registry,
}: {
  isRoot: boolean;
  uiSchema?: UiSchema;
  registry: Registry;
}) {
  const uaOptions = getUaOptions(uiSchema, registry.globalUiOptions);
  if (_isUndefined(uaOptions.collapsible)) {
    return !isRoot;
  }
  return uaOptions.collapsible;
}
