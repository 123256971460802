import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNumberedList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path d="M1.667 14.167h1.754v.416h-.877v.834h.877v.416H1.667v.834h2.632v-3.334H1.667zm.877-7.5h.877V3.333H1.667v.834h.877zm-.877 2.5h1.579l-1.579 1.75v.75h2.632v-.834h-1.58l1.58-1.75v-.75H1.667zm4.386-5v1.666h12.28V4.167zm0 11.666h12.28v-1.666H6.054zm0-5h12.28V9.167H6.054z" />
  </svg>
);
export default SvgNumberedList;
