import { cva } from 'class-variance-authority';

// keeping empty strings here, so styles can be easily added without modifying any other file
export const radioRootVariants = cva('gap-md flex items-center', {
  variants: {
    checked: {
      false: '',
      true: '',
    },
    disabled: {
      false: '',
      true: '',
    },
    focusVisible: {
      false: '',
      true: '',
    },
    size: {
      sm: '',
      md: '',
    },
  },
  defaultVariants: {
    checked: false,
    disabled: false,
    focusVisible: false,
  },
});

// position relative:  https://mui.com/joy-ui/react-radio-button/#focus-outline
export const radioRadioVariants = cva(
  'relative rounded-full flex justify-center items-center border',
  {
    variants: {
      checked: {
        false: '',
        true: '',
      },
      disabled: {
        false: '',
        true: '',
      },
      focusVisible: {
        false: '',
        true: '',
      },
      size: {
        sm: 'size-[16px]',
        md: 'size-[20px]',
      },
    },
    compoundVariants: [
      // colors
      {
        // ( )
        disabled: false,
        checked: false,
        className: '!border-primary !bg-primary hover:!bg-primary_hover',
      },
      {
        // (o)
        disabled: false,
        checked: true,
        className: '!border-brand-solid !bg-brand-solid !text-fg-white hover:!bg-brand-solid_hover',
      },
      // disabled
      {
        disabled: true,
        className: '!border-disabled !bg-disabled_subtle !text-fg-disabled_subtle',
      },
      // focus ringss
      {
        focusVisible: true,
        checked: false,
        className: 'ring-gray',
      },
      {
        focusVisible: true,
        checked: true,
        className: 'ring-brand',
      },
    ],
    defaultVariants: {
      checked: false,
      disabled: false,
      focusVisible: false,
    },
  },
);

// keeping empty strings here, so styles can be easily added without modifying any other file
export const radioIconVariants = cva('', {
  variants: {
    checked: {
      false: '',
      true: '',
    },
    disabled: {
      false: '',
      true: '',
    },
    focusVisible: {
      false: '',
      true: '',
    },
    size: {
      sm: 'size-[6px]',
      md: 'size-[8px]',
    },
  },
  defaultVariants: {
    checked: false,
    disabled: false,
    focusVisible: false,
  },
});

// keeping empty strings here, so styles can be easily added without modifying any other file
export const radioActionVariants = cva('', {
  variants: {
    checked: {
      false: '',
      true: '',
    },
    disabled: {
      false: '',
      true: '',
    },
    focusVisible: {
      false: '',
      true: '',
    },
    size: {
      sm: '',
      md: '',
    },
  },
  defaultVariants: {
    checked: false,
    disabled: false,
    focusVisible: false,
  },
});

// keeping empty strings here, so styles can be easily added without modifying any other file
export const radioLabelVariants = cva('text-sm font-medium text-secondary', {
  variants: {
    checked: {
      false: '',
      true: '',
    },
    disabled: {
      false: '',
      true: '',
    },
    focusVisible: {
      false: '',
      true: '',
    },
    size: {
      sm: '',
      md: '',
    },
  },
  defaultVariants: {
    checked: false,
    disabled: false,
    focusVisible: false,
  },
});
