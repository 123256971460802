import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';
import React, { useCallback } from 'react';
import { MenuList } from '@unifyapps/ui/components/Menu';
import type { MenuListProps } from '@unifyapps/ui/components/Menu/MenuList';
import { Toolbar } from '../../../../components/ui/Toolbar';
import { Icon } from '../../../../components/ui/Icon';
import type { MenuProps, ShouldShowProps } from '../../../../components/menus/types';
import { isColumnGripSelected } from './utils';

export const TableColumnMenu = React.memo(({ editor, appendTo }: MenuProps): JSX.Element => {
  const shouldShow = useCallback(
    ({ view, state, from }: ShouldShowProps) => {
      if (!state) {
        return false;
      }

      return isColumnGripSelected({ editor, view, state, from: from || 0 });
    },
    [editor],
  );

  const onAddColumnBefore = useCallback(() => {
    editor.chain().focus().addColumnBefore().run();
  }, [editor]);

  const onAddColumnAfter = useCallback(() => {
    editor.chain().focus().addColumnAfter().run();
  }, [editor]);

  const onDeleteColumn = useCallback(() => {
    editor.chain().focus().deleteColumn().run();
  }, [editor]);

  const onAction: NonNullable<MenuListProps['onAction']> = useCallback(
    (id) => {
      switch (id) {
        case 'add-column-before':
          onAddColumnBefore();
          break;
        case 'add-column-after':
          onAddColumnAfter();
          break;
        case 'delete-column':
          onDeleteColumn();
          break;
      }
    },
    [onAddColumnAfter, onAddColumnBefore, onDeleteColumn],
  );

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="tableColumnMenu"
      shouldShow={shouldShow}
      tippyOptions={{
        appendTo: () => {
          return appendTo;
        },
        offset: [0, 15],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
      }}
      updateDelay={0}
    >
      <Toolbar.Wrapper className="p-0" isVertical>
        <MenuList
          items={[
            {
              title: 'Add column before',
              startDecoratorNode: <Icon name="ArrowLeft" />,
              id: 'add-column-before',
              type: 'label',
            },
            {
              title: 'Add column after',
              startDecoratorNode: <Icon name="ArrowRight" />,
              id: 'add-column-after',
              type: 'label',
            },
            {
              title: 'Delete column',
              startDecoratorNode: <Icon name="Trash" />,
              id: 'delete-column',
              type: 'label',
            },
          ]}
          onAction={onAction}
        />
      </Toolbar.Wrapper>
    </BaseBubbleMenu>
  );
});

TableColumnMenu.displayName = 'TableColumnMenu';

export default TableColumnMenu;
