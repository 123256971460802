import type {
  UiSchema,
  WidgetProps,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
} from '@rjsf/utils';
import { getUiOptions as defaultGetUiOptions } from '@rjsf/utils';

export const getUiOptions = <
  T,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = object,
>({
  uiSchema,
  registry,
}: {
  uiSchema?: UiSchema<T, S, F>;
  registry: WidgetProps<T, S, F>['registry'];
}) => {
  return defaultGetUiOptions<T, S, F>(uiSchema, registry.globalUiOptions);
};
