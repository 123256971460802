import { ID_KEY, mergeObjects } from '@rjsf/utils';
import type { IdSchema, FieldProps } from '@rjsf/utils';
import type { JSONSchema7 } from 'json-schema';
import { useMemo } from 'react';
import { omit } from 'lodash';
import SchemaField from '../SchemaField';

// this field was created because MultiSelectWidget does not render the overriden template, if only objectFieldTemplate and FieldTemplate are overriden
// so in this field we pick the schema of items and pass it to the SchemaField
function MultiSelectField(props: FieldProps) {
  const { schema, registry, uiSchema } = props;
  const fieldId = props.idSchema[ID_KEY];

  const adaptedValues = useMemo(() => {
    const newSchema = schema.items as JSONSchema7;
    newSchema.title = schema.title;
    const newUiSchema = omit(uiSchema, ['ui:field']);

    return {
      schema: newSchema,
      uiSchema: newUiSchema,
    };
  }, [schema.items, schema.title, uiSchema]);

  const idSchema = useMemo(() => {
    return mergeObjects(
      registry.schemaUtils.toIdSchema(
        adaptedValues.schema,
        fieldId,
        props.formData,
        props.idPrefix,
        props.idSeparator,
      ),
      props.idSchema,
    ) as IdSchema;
  }, [
    adaptedValues.schema,
    fieldId,
    props.formData,
    props.idPrefix,
    props.idSchema,
    props.idSeparator,
    registry.schemaUtils,
  ]);

  return (
    <SchemaField
      {...props}
      idSchema={idSchema}
      registry={registry}
      schema={adaptedValues.schema}
      uiSchema={adaptedValues.uiSchema}
    />
  );
}

export default MultiSelectField;
