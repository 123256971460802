import { useMemo } from 'react';
import getUaOptions from '@unifyapps/form/utils/getUaOptions';
import BaseSelectWidget from '@unifyapps/form/widgets/SelectWidget';
import type { SelectTemplateProps as SelectWidgetProps } from '@unifyapps/form/widgets/SelectWidget/templates/SelectTemplate';
import { ListBoxHeader, listBoxRenderer } from '../../components/AutocompleteListBox';

function SelectWidget(props: SelectWidgetProps) {
  const uaOptions = getUaOptions<{ multiple?: boolean; enableSelectAll?: boolean }>(props.uiSchema);

  const updatedSlots = useMemo(() => {
    if (!uaOptions.multiple || !uaOptions.enableSelectAll) {
      return props.slots;
    }

    return {
      ...props.slots,
      listbox: listBoxRenderer({
        slots: { listboxheader: ListBoxHeader },
      }),
    };
  }, [props.slots, uaOptions.enableSelectAll, uaOptions.multiple]);

  return <BaseSelectWidget {...props} slots={updatedSlots} />;
}

export default SelectWidget;
