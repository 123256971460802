import type { ArrayFieldTemplateProps, UIOptionsType } from '@rjsf/utils';
import { getTemplate } from '@rjsf/utils';
import Stack from '@unifyapps/ui/_components/Stack';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import Triangle from '@unifyapps/icons/outline/Triangle';
import { clsx } from 'clsx';
import React, { useCallback, useState } from 'react';
import { Typography } from '@unifyapps/ui/components/Typography';
import { Box } from '@unifyapps/ui/components/Box';
import _isUndefined from 'lodash/isUndefined';
import getUaOptions from '../../utils/getUaOptions';
import {
  ObjectBackgroundToggleContextProvider,
  useObjectBackgroundToggleValue,
} from '../ObjectFieldTemplate/context/ObjectBackgroundContext';
import { getBackgroundClass, getIsCollapsible, isRootNode } from '../ObjectFieldTemplate/utils';
import { getUiOptions } from '../../utils/getUiOptions';

function ArrayFieldTemplate(props: ArrayFieldTemplateProps) {
  const {
    uiSchema,
    idSchema,
    registry,
    required,
    canAdd,
    onAddClick,
    schema,
    title,
    items,
    className,
    readonly,
    disabled,
  } = props;
  const uiOptions = getUiOptions({
    registry,
    uiSchema,
  }) as UIOptionsType & {
    size?: 'sm' | 'md';
  };
  const uaOptions = getUaOptions<{
    emptyState?: { description?: string; className?: string };
    arrayFieldItemTemplateClassName?: string;
    isInitiallyCollapsed?: boolean;
  }>(uiSchema);
  const ArrayFieldDescriptionTemplate = getTemplate(
    'ArrayFieldDescriptionTemplate',
    registry,
    uiOptions,
  );
  const ArrayFieldItemTemplate = getTemplate('ArrayFieldItemTemplate', registry, uiOptions);
  const ArrayFieldTitleTemplate = getTemplate('ArrayFieldTitleTemplate', registry, uiOptions);

  // Button templates are not overridden in the uiSchema
  const {
    templates: {
      ButtonTemplates: { AddButton },
    },
  } = registry;

  const isRoot = isRootNode(idSchema);
  const toggleValue = useObjectBackgroundToggleValue();

  const isCollapsible = getIsCollapsible({ isRoot, uiSchema, registry });

  const [collapsed, setCollapsed] = useState<boolean>(
    isCollapsible &&
      (_isUndefined(uaOptions.isInitiallyCollapsed) ? false : uaOptions.isInitiallyCollapsed),
  );

  const toggleCollapsed = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement>) => {
      event?.stopPropagation();
      isCollapsible && setCollapsed((prev) => !prev);
    },
    [isCollapsible],
  );

  const AddButtonComp =
    canAdd && !readonly ? (
      <AddButton
        disabled={disabled}
        // @ts-expect-error -- need to find a way to override props
        idSchema={idSchema}
        onClick={onAddClick}
        registry={registry}
        title={uiOptions.addButtonTitle as string | undefined}
        type="button"
        uiSchema={uiSchema}
        variant="outline"
      />
    ) : null;

  const disableBackgroundToggle = isRoot || Boolean(uaOptions.disableBackground);
  const arrayBgValue = disableBackgroundToggle ? toggleValue : !toggleValue;

  const emptyView = (
    <Box
      className={clsx(
        'border-secondary pb-lg px-lg bg-primary gap-y-md flex w-full flex-col rounded-md border',
        { hidden: collapsed },
        uaOptions.emptyState?.className,
        uaOptions.emptyState?.description ? 'pt-md' : 'pt-lg',
      )}
    >
      {uaOptions.emptyState?.description ? (
        <Typography color="text-tertiary" variant="text-sm" weight="regular">
          {uaOptions.emptyState.description}
        </Typography>
      ) : null}
      {AddButtonComp}
    </Box>
  );

  const doesChildrenExist =
    isCollapsible || uiOptions.label !== false || uiOptions.description || schema.description;

  return (
    <ObjectBackgroundToggleContextProvider value={arrayBgValue}>
      <Stack className={clsx(className, 'gap-y-sm array-field-template flex')}>
        {doesChildrenExist ? (
          <Stack
            className={clsx('gap-y-sm', { 'cursor-pointer': isCollapsible })}
            direction="column"
            onClick={() => toggleCollapsed()}
          >
            {isCollapsible || uiOptions.label !== false ? (
              <Stack alignContent="center" className="gap-x-xxs" direction="row">
                {isCollapsible ? (
                  <IconButton
                    Icon={Triangle}
                    className={clsx(
                      '!p-xs !text-fg-secondary !size-5 duration-100 hover:!bg-transparent [&_path]:fill-current [&_svg]:!size-2 [&_svg]:transition',
                      {
                        '[&_svg]:rotate-90': collapsed,
                        '[&_svg]:rotate-180': !collapsed,
                      },
                    )}
                    color="neutral"
                    onClick={toggleCollapsed}
                    size="sm"
                    variant="soft"
                  />
                ) : null}
                {uiOptions.label === false ? null : (
                  <ArrayFieldTitleTemplate
                    idSchema={idSchema}
                    registry={registry}
                    required={required}
                    schema={schema}
                    title={uiOptions.title || title}
                    uiSchema={uiSchema}
                  />
                )}
              </Stack>
            ) : null}
            {uiOptions.description || schema.description ? (
              <ArrayFieldDescriptionTemplate
                description={uiOptions.description || schema.description}
                idSchema={idSchema}
                registry={registry}
                schema={schema}
                uiSchema={uiSchema}
              />
            ) : null}
          </Stack>
        ) : null}
        {/* eslint-disable-next-line no-nested-ternary -- seems fine */}
        {items.length === 0 ? (
          emptyView
        ) : collapsed ? null : (
          <Stack
            className={clsx(
              'gap-y-xl relative flex',
              { hidden: collapsed },
              getBackgroundClass({ size: uiOptions.size, disableBackgroundToggle, toggleValue }),
              uaOptions.arrayFieldItemTemplateClassName,
            )}
          >
            {items.map(({ key, ...arrayItemProps }) => (
              <ArrayFieldItemTemplate
                key={key}
                // @ts-expect-error -- need to find a way to override props
                parentArrayIdSchema={idSchema}
                {...arrayItemProps}
              />
            ))}
            {AddButtonComp}
          </Stack>
        )}
      </Stack>
    </ObjectBackgroundToggleContextProvider>
  );
}

export default ArrayFieldTemplate;
