import { forwardRef, useMemo } from 'react';
import { clsx } from 'clsx';
import type { TitleFieldProps, UIOptionsType, UiSchema } from '@rjsf/utils';
import type {
  TypographyColors,
  TypographyProps,
  TypographyVariants,
  TypographyWeight,
} from '@unifyapps/ui/components/Typography';
import { Typography } from '@unifyapps/ui/components/Typography';
import { Tooltip } from '@unifyapps/ui/components/Tooltip';
import { Box } from '@unifyapps/ui/components/Box';
import _startCase from 'lodash/startCase';
import _memoize from 'lodash/memoize';
import type { JSONSchema7 } from 'json-schema';
import { Trans } from '@unifyapps/i18n/client';
import { getUiOptions } from '../../utils/getUiOptions';

// NOTE: using memo to avoid unnecessary console.log more than once
const memoLog = _memoize(
  ({ title, id }: { title: string; id: string }) => {
    console.log(`[TitleFieldTemplate] missing label in schema/uiSchema for id: ${id} --`, title);
  },
  ({ id }) => id,
);

const components = {
  br: <br />,
};

export function getDerivedTitle({
  uiOptions,
  schema,
  title,
  id,
}: {
  uiOptions: UIOptionsType;
  schema: JSONSchema7;
  title: string;
  id: string;
}) {
  // NOTE: this is a check to see if the label is defined in the schema, if not we use _startCase to convert the fieldName to a display label
  // ping @pradeet for any queries or issues
  const hasDefinedTitle = Boolean(uiOptions.title || schema.title);
  if (!hasDefinedTitle && process.env.NODE_ENV === 'development') {
    memoLog({ title, id });
  }

  return hasDefinedTitle ? uiOptions.title || schema.title : _startCase(title);
}

function getWeight({ uiOptions, uiSchema }: { uiOptions: UIOptionsType; uiSchema?: UiSchema }) {
  let _weight: TypographyWeight = uiSchema?.bold ? 'semi-bold' : 'medium';

  if (uiOptions.weight) {
    _weight = uiOptions.weight as TypographyWeight;
  }
  return _weight;
}

function useExtractTooltipProps(props: TitleFieldProps & Record<string, unknown>) {
  const {
    className,
    component,
    onBlur,
    onFocus,
    onMouseLeave,
    onMouseOver,
    onTouchEnd,
    onTouchStart,
  } = props;

  return {
    className,
    component,
    onBlur,
    onFocus,
    onMouseLeave,
    onMouseOver,
    onTouchEnd,
    onTouchStart,
    'aria-label': props['aria-label'],
    'aria-labelledby': props['aria-labelledby'],
  };
}

const TitleFieldTemplate = forwardRef<HTMLDivElement, TitleFieldProps>((props, ref) => {
  const { id, required, title, schema, uiSchema, registry } = props;

  const uiOptions = getUiOptions({
    registry,
    uiSchema,
  }) as {
    weight?: TypographyWeight;
    titleColor?: TypographyColors;
    titleVariant?: TypographyVariants;
    titleClassName?: string;
    label?: boolean;
    hideTitle?: boolean;
    icon?: string;
  };

  const tooltipProps = useExtractTooltipProps(props);

  const weight = getWeight({ uiOptions, uiSchema });

  const titleStyles =
    (
      registry.globalUiOptions as
        | { titleStyles?: Pick<TypographyProps, 'color' | 'weight' | 'variant' | 'className'> }
        | undefined
    )?.titleStyles || {};

  const typographyProps = {
    color: titleStyles.color || uiOptions.titleColor || 'text-secondary',
    variant: titleStyles.variant || uiOptions.titleVariant || 'text-sm',
    weight: titleStyles.weight || weight,
  };

  // explicitly checking uiOptions.label to be false, since we don't want to hide when undefined
  if (!title || uiOptions.hideTitle || uiOptions.label === false) {
    return null;
  }

  // used to give fontWeight // where are such array titles are used in the form? check how to do it in bold
  // const isRootTitle = id.includes('root'); // need to discuss of a better approach to distinguish
  return (
    <Typography
      {...tooltipProps}
      className={clsx('flex items-center', titleStyles.className, uiOptions.titleClassName)}
      htmlFor={id}
      ref={ref}
      {...typographyProps}
    >
      {getDerivedTitle({ uiOptions, schema, title, id })}
      {required ? (
        <Box as="span" className="text-secondary">
          *
        </Box>
      ) : null}
    </Typography>
  );
});

TitleFieldTemplate.displayName = 'TitleFieldTemplate';

function TitleFieldTemplateWithTooltip(props: TitleFieldProps) {
  const { uiSchema, registry, title } = props;

  const uiOptions = getUiOptions({
    registry,
    uiSchema,
  }) as {
    tooltip?: {
      title?: string;
      description?: string;
    };
  };

  const tooltipTitle = useMemo(() => {
    if (!uiOptions.tooltip?.title) return null;
    return <Trans components={components}>{uiOptions.tooltip.title}</Trans>;
  }, [uiOptions.tooltip?.title]);

  if (!uiOptions.tooltip?.title || !title) {
    return <TitleFieldTemplate {...props} />;
  }

  return (
    <Tooltip description={uiOptions.tooltip.description} placement="left" title={tooltipTitle}>
      <TitleFieldTemplate {...props} />
    </Tooltip>
  );
}

export default TitleFieldTemplateWithTooltip;
