import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBold = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
    width="100%"
    height="100%"
    {...props}
  >
    <path d="M13.5 9.775c1.053-.718 1.791-1.896 1.791-2.99 0-2.42-1.9-4.285-4.341-4.285H4.167v15h7.64c2.268 0 4.027-1.821 4.027-4.06A4 4 0 0 0 13.5 9.774M7.423 5.179h3.256c.9 0 1.627.717 1.627 1.607 0 .889-.727 1.607-1.627 1.607H7.423zm3.798 9.642H7.423v-3.214h3.798c.901 0 1.628.718 1.628 1.607 0 .89-.727 1.607-1.628 1.607" />
  </svg>
);
export default SvgBold;
