import { ID_KEY, type IdSchema } from '@rjsf/utils';
import type { JSONSchema7 } from 'json-schema';

export const ROOT_ID = 'root';

export function getShouldResolveSchema(_schema: JSONSchema7) {
  return Boolean(
    _schema.allOf ||
      _schema.anyOf ||
      _schema.oneOf ||
      _schema.if ||
      _schema.$ref ||
      _schema.dependencies ||
      _schema.definitions,
  );
}

export function getFieldIdSchema(props: { idSchema: IdSchema | undefined; idPrefix?: string }) {
  const $id = props.idSchema?.$id || props.idPrefix || ROOT_ID;
  const idSchema = { [ID_KEY]: $id } as IdSchema;
  return idSchema;
}
