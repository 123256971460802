import React from 'react';
import { cn } from '../../../lib/utils';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'ghost';
export type ButtonSize = 'medium' | 'small' | 'icon' | 'iconSmall';

export type ButtonProps = {
  variant?: ButtonVariant;
  active?: boolean;
  activeClassname?: string;
  buttonSize?: ButtonSize;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      active,
      buttonSize = 'medium',
      children,
      disabled,
      variant = 'primary',
      className,
      activeClassname,
      ...rest
    },
    ref,
  ) => {
    const buttonClassName = cn(
      'flex group items-center justify-center border border-transparent gap-2 text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap',

      variant === 'primary' &&
        cn(
          'text-white bg-utility-gray-900 border-utility-gray-900',
          !disabled && !active && 'hover:bg-utility-gray-700 active:bg-utility-gray-800',
          active && cn('bg-utility-gray-800', activeClassname),
        ),

      variant === 'secondary' &&
        cn(
          'text-utility-gray-800',
          !disabled && !active && 'hover:bg-utility-gray-50 active:bg-utility-gray-100',
          active && 'bg-utility-gray-100',
        ),

      variant === 'tertiary' &&
        cn(
          'bg-utility-gray-400 text-utility-gray-800',
          !disabled &&
            !active &&
            'hover:bg-utility-gray-50 active:bg-utility-gray-100 dark:hover:bg-utility-gray-700',
          active && cn('bg-utility-gray-100', activeClassname),
        ),

      variant === 'ghost' &&
        cn(
          'bg-transparent border-transparent text-utility-gray-500',
          !disabled &&
            !active &&
            'hover:bg-utility-gray-400 hover:text-utility-gray-600 active:bg-utility-gray-900 active:text-utility-gray-700',
          active && cn('bg-utility-gray-500 text-utility-gray-100', activeClassname),
        ),

      buttonSize === 'medium' && 'py-2 px-3',
      buttonSize === 'small' && 'py-1 px-2',
      buttonSize === 'icon' && 'w-8 h-8',
      buttonSize === 'iconSmall' && 'w-6 h-6',

      className,
    );

    return (
      <button className={buttonClassName} disabled={disabled} ref={ref} {...rest}>
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';
