import type { UIOptionsType, UiSchema } from '@rjsf/utils';
import React, { useMemo } from 'react';
import { groupBy } from 'lodash';
import Stack from '@unifyapps/ui/_components/Stack';
import { clsx } from 'clsx';
import type { UAObjectFieldTemplatePropertyType, UAObjectFieldTemplateProps } from '../../types';
import { getContentNode } from '../../utils';

export default function VerticalLayout({
  properties,
  layout,
  className,
  uiSchema,
  horizontalClassName,
}: {
  properties: UAObjectFieldTemplatePropertyType[];
  layout: (string | string[])[];
  className?: string;
  horizontalClassName?: string;
  uiSchema: UAObjectFieldTemplateProps['uiSchema'];
}) {
  const elementsMap = useMemo(() => groupBy(properties, 'name'), [properties]);

  return (
    <Stack className={clsx('gap-y-xl flex', className)}>
      {layout.map((item) => {
        if (Array.isArray(item)) {
          const horizontalItems = item.reduce<JSX.Element[]>((acc, it) => {
            const elemIt = elementsMap[it] as UAObjectFieldTemplatePropertyType[] | undefined;
            const uiSchemaForField = uiSchema?.[it] as UiSchema | undefined;
            const elementClassName = (uiSchemaForField?.['ui:options'] as UIOptionsType | undefined)
              ?.className as string;

            const contentNode = elemIt?.[0] ? getContentNode(elemIt[0]) : null;
            if (contentNode) {
              acc.push(
                <Stack className={`min-w-0 flex-1 ${elementClassName}`} key={it}>
                  {contentNode}
                </Stack>,
              );
            }

            return acc;
          }, []);

          if (!horizontalItems.length) return null;

          return (
            <Stack
              className={clsx('flex flex-row gap-x-2', horizontalClassName)}
              key={item.join('-')}
            >
              {horizontalItems}
            </Stack>
          );
        }
        const elemItem = elementsMap[item] as UAObjectFieldTemplatePropertyType[] | undefined;
        return elemItem?.[0] ? getContentNode(elemItem[0]) : null;
      })}
    </Stack>
  );
}
