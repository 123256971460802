import type { PropsWithChildren, ReactElement } from 'react';
import { Children } from 'react';
import _find from 'lodash/find';
import Stack from '@unifyapps/ui/_components/Stack';

const enum OptionLayoutSlots {
  Label = 'LABEL',
  Icon = 'ICON',
  EndDecorator = 'END_DECORATOR',
}

type SlotProps = { name: OptionLayoutSlots; id?: string };

function Slot(props: PropsWithChildren<SlotProps>): null {
  return null;
}

function OptionLayout({ children }: { children: ReactElement[] }): ReactElement {
  const childrenArr = Children.toArray(children);

  const labelSlot = _find(
    childrenArr,
    (child: ReactElement<SlotProps>) => child.props.name === OptionLayoutSlots.Label,
  ) as ReactElement<{ children: ReactElement }> | undefined;

  const iconSlot = _find(
    childrenArr,
    (child: ReactElement<SlotProps>) => child.props.name === OptionLayoutSlots.Icon,
  ) as ReactElement<{ children: ReactElement }> | undefined;

  const endDecoratorSlot = _find(
    childrenArr,
    (child: ReactElement<SlotProps>) => child.props.name === OptionLayoutSlots.EndDecorator,
  ) as ReactElement<{ children: ReactElement }> | undefined;

  const isLabelPresent = Boolean(labelSlot?.props.children);
  const isIconPresent = Boolean(iconSlot?.props.children);
  const isEndDecoratorPresent = Boolean(endDecoratorSlot?.props.children);

  return (
    <Stack
      alignItems="center"
      className="gap-x-sm w-full"
      direction="row"
      justifyContent="space-between"
    >
      <Stack alignItems="center" className="gap-x-sm" direction="row">
        {isIconPresent ? iconSlot?.props.children : null}
        {isLabelPresent ? labelSlot?.props.children : null}
      </Stack>
      {isEndDecoratorPresent ? endDecoratorSlot?.props.children : null}
    </Stack>
  );
}

OptionLayout.Slot = Slot;
export { OptionLayout, OptionLayoutSlots };
