import React, { useCallback } from 'react';
import type { WrapIfAdditionalTemplateProps } from '@rjsf/utils';
import { ADDITIONAL_PROPERTIES_KEY, TranslatableString } from '@rjsf/utils';
import Stack from '@unifyapps/ui/_components/Stack';
import { slowCn } from '@unifyapps/ui/lib/utils';
import { Input } from '@unifyapps/ui/components/Input';

// todo: in progress - the UI is breaking. Need to fix
function WrapIfAdditionalTemplate(props: WrapIfAdditionalTemplateProps) {
  const {
    children,
    classNames,
    disabled,
    id,
    label,
    onDropPropertyClick,
    onKeyChange,
    readonly,
    required,
    schema,
    uiSchema,
    registry,
  } = props;
  const { translateString } = registry;

  // Button templates are not overridden in the uiSchema
  const { RemoveButton } = registry.templates.ButtonTemplates;
  const keyLabel = translateString(TranslatableString.KeyLabel, [label]);
  const additional = ADDITIONAL_PROPERTIES_KEY in schema;

  const handleBlur = useCallback(
    ({ target }: React.FocusEvent<HTMLInputElement>) => {
      onKeyChange(target.value);
    },
    [onKeyChange],
  );

  const onRemove = useCallback(() => onDropPropertyClick(label), [onDropPropertyClick, label]);

  return children;

  // need to see how this works, skipping for now since we do not have any use case

  if (!additional) {
    return <Stack>{children}</Stack>;
  }

  return (
    <Stack className={slowCn(classNames, 'flex items-center gap-x-2')}>
      <Stack>
        <Input
          defaultValue={label}
          disabled={disabled}
          fullWidth
          id={`${id}-key`}
          name={`${id}-key`}
          onBlur={!readonly ? handleBlur : undefined}
          placeholder={keyLabel}
          readOnly={readonly}
          required={required}
          type="text"
        />
      </Stack>
      <Stack>{children}</Stack>
      <Stack>
        <RemoveButton
          disabled={disabled || readonly}
          onClick={onRemove}
          registry={registry}
          uiSchema={uiSchema}
        />
      </Stack>
    </Stack>
  );
}

export default WrapIfAdditionalTemplate;
