import React, { useMemo } from 'react';
import type { FieldErrorProps } from '@rjsf/utils';
import { errorId } from '@rjsf/utils';
import { Box } from '@unifyapps/ui/components/Box';
import Stack from '@unifyapps/ui/_components/Stack';
import { nanoid } from 'nanoid';
import { Typography } from '@unifyapps/ui/components/Typography';
import './styles.css';

function FieldErrorTemplate(props: FieldErrorProps) {
  const { errors, idSchema } = props;
  const id = errorId(idSchema);

  const errorsKeys = useMemo(() => errors?.map((_) => nanoid(5)), [errors]);

  // when the a field is present in multiple `allOf` schemas, the same error message is repeated
  // and we don't want to show the same error message multiple times, obviously
  const uniqueErrors = useMemo(() => Array.from(new Set(errors)), [errors]);

  if (!errors || errors.length === 0) return null;

  return (
    <Box>
      {uniqueErrors.map((error, i) => {
        return (
          <Stack className="error flex flex-row gap-x-1" key={errorsKeys?.[i]}>
            {typeof error === 'string' ? (
              <Typography
                className="field-error-template text-error-primary m-0"
                dangerouslySetInnerHTML={{ __html: error }}
                id={id}
                variant="text-xs"
              />
            ) : (
              <Typography as="span" className="text-error-primary m-0" id={id} variant="text-xs">
                {error}
              </Typography>
            )}
          </Stack>
        );
      })}
    </Box>
  );
}

export default FieldErrorTemplate;
