import { cva } from 'class-variance-authority';

export const getContainerClassName = cva('flex flex-col rounded-md border mb-xl py-md px-lg', {
  variants: {
    color: {
      error: 'border-error bg-error-primary',
      warning: 'border-warning bg-warning-primary',
      success: 'border-success bg-success-primary',
      brand: 'border-brand bg-brand-primary',
    },
  },
});

export const getHeaderTextClassName = cva('', {
  variants: {
    color: {
      error: 'text-error-primary',
      warning: 'text-secondary',
      success: 'text-success-primary',
      brand: 'text-secondary',
    },
  },
});

export const getTextClassName = cva('', {
  variants: {
    color: {
      error: 'text-fg-error-secondary',
      warning: 'text-fg-warning-secondary',
      success: 'text-fg-success-primary',
      brand: 'text-fg-secondary',
    },
  },
});

export const getIconClassName = cva('', {
  variants: {
    color: {
      error: 'hover:!bg-error-primary',
      warning: 'hover:!bg-warning-primary',
      success: 'hover:!bg-success-primary',
      brand: 'hover:!bg-brand-primary',
    },
  },
});
