import type { FormContextType, RJSFSchema, StrictRJSFSchema, WidgetProps } from '@rjsf/utils';
import { getTemplate } from '@rjsf/utils';
import type { DividerTemplateComponentType } from './templates/DividerTemplate';
import DefaultDividerTemplate from './templates/DividerTemplate';

/** The `DividerTemplate` component uses the `BaseDividerTemplate`
 *
 * @param props - The `WidgetProps` for this component
 */

export default function DividerWidget<
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = object,
>(props: WidgetProps<T, S, F>) {
  const { options, registry } = props;
  const BaseDividerTemplate =
    // @ts-expect-error -- BaseDividerTemplate not in template type5
    ((getTemplate('DividerTemplate', registry, options) as
      | DividerTemplateComponentType
      | undefined) ?? DefaultDividerTemplate) as (props: WidgetProps<T, S, F>) => JSX.Element;

  return <BaseDividerTemplate {...props} />;
}
