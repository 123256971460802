import type { Editor } from '@tiptap/react';
import { useCallback } from 'react';

function useTextMenuCommands({ editor }: { editor: Editor }) {
  const setFontFamily = useCallback(
    (value: string) => {
      editor.chain().focus().setFontFamily(value).run();
    },
    [editor],
  );
  const setFontSize = useCallback(
    (value: string) => {
      editor.chain().focus().setFontSize(value).run();
    },
    [editor],
  );
  const toggleBold = useCallback(() => {
    editor.chain().focus().toggleBold().run();
  }, [editor]);
  const toggleItalic = useCallback(() => {
    editor.chain().focus().toggleItalic().run();
  }, [editor]);
  const toggleStrike = useCallback(() => {
    editor.chain().focus().toggleStrike().run();
  }, [editor]);
  const toggleBulletList = useCallback(() => {
    editor.chain().focus().toggleBulletList().run();
  }, [editor]);
  const toggleOrderedList = useCallback(() => {
    editor.chain().focus().toggleOrderedList().run();
  }, [editor]);

  return {
    setFontFamily,
    setFontSize,
    toggleBold,
    toggleItalic,
    toggleStrike,
    toggleBulletList,
    toggleOrderedList,
  };
}

export default useTextMenuCommands;
