import { Input } from '@unifyapps/ui/components/Input';
import { format } from 'date-fns/format';
import { isValid } from 'date-fns/isValid';
import { parse } from 'date-fns/parse';
import { memo, useCallback } from 'react';
import { preventNativePicker } from './utils';

// limit year to 4 digits
const DATE_SLOT_PROPS = {
  input: {
    max: '9999-12-31',
  },
};

interface DateInputProps {
  value?: Date;
  onChange: (value: Date) => void;
  disableNativePicker?: boolean;
}

const DATE_FORMAT = 'yyyy-MM-dd';

function DateInput(props: DateInputProps) {
  const { value, onChange, disableNativePicker } = props;

  const formattedValue = value ? format(value, DATE_FORMAT) : '';

  const onChangeValue = useCallback(
    (nextDate: string) => {
      const parsedDate = parse(nextDate, DATE_FORMAT, new Date());

      if (isValid(parsedDate)) {
        onChange(parsedDate);
      }
    },
    [onChange],
  );

  return (
    <Input
      className="flex-1"
      onChangeValue={onChangeValue}
      onKeyDown={disableNativePicker ? preventNativePicker : undefined}
      slotProps={DATE_SLOT_PROPS}
      type="date"
      value={formattedValue}
    />
  );
}

export default memo(DateInput);
