import type { FieldProps, RJSFSchema } from '@rjsf/utils';
import React, { createContext, useContext } from 'react';

type FormContextProps = FieldProps<
  unknown,
  RJSFSchema,
  {
    layout?: 'row' | 'column';
    [key: string]: unknown;
  }
>;

export const FormContext = createContext<FormContextProps | null>(null);

export const useFormContext = <T = FormContextProps,>() => {
  const context = useContext(FormContext);
  if (context === null) {
    throw new Error('FormContext not found');
  }
  return context as T;
};

export function FormContextProvider({
  value,
  children,
}: {
  value: FormContextProps;
  children: React.ReactNode;
}) {
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}
