import { forwardRef } from 'react';
import type { BoxProps } from '@unifyapps/ui/components/Box';
import { Box } from '@unifyapps/ui/components/Box';
import { Tooltip } from '@unifyapps/ui/components/Tooltip';
import { clsx } from 'clsx';
import { WysiwygButton, type WysiwygButtonProps } from '../WysiwygButton';

export type ToolbarWrapperProps = {
  shouldShowContent?: boolean;
} & BoxProps;

const ToolbarWrapper = forwardRef<HTMLDivElement, ToolbarWrapperProps>(
  ({ shouldShowContent = true, className, children, ...rest }, ref) => {
    return (
      shouldShowContent && (
        <Box
          className={clsx('p-md space-x-xs bg-primary flex w-full flex-row', className)}
          {...rest}
          ref={ref}
        >
          {children}
        </Box>
      )
    );
  },
);

ToolbarWrapper.displayName = 'Toolbar';

export type ToolbarButtonProps = WysiwygButtonProps & {
  tooltip?: string;
};

const ToolbarButton = forwardRef<HTMLButtonElement, ToolbarButtonProps>(
  ({ tooltip, ...rest }, ref) => {
    return (
      <Tooltip title={tooltip}>
        <WysiwygButton ref={ref} {...rest} />
      </Tooltip>
    );
  },
);

ToolbarButton.displayName = 'ToolbarButton';

export const Toolbar = {
  Wrapper: ToolbarWrapper,
  Button: ToolbarButton,
};
