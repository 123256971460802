'use client';

import type { Editor } from '@tiptap/react';
import { EditorContent } from '@tiptap/react';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { clsx } from 'clsx';
import type { EditorRef } from '@unifyapps/editor/types';
import { ColumnsMenu } from '../../extensions/MultiColumn/menus';
import { LinkMenu } from '../menus';
import { TextMenu } from '../menus/TextMenu';
import { useBlockEditor } from '../../hooks/useBlockEditor';
import '../../styles/index.css';
import { EditorContext } from '../../context/EditorContext';
import { ImageBlockMenu } from '../../extensions/ImageBlock/components/ImageBlockMenu';
import { TableColumnMenu, TableRowMenu, TableCornerMenu } from '../../extensions/Table/menus';
import type { BlockEditorProps } from './types';

export const BlockEditor = forwardRef<EditorRef, BlockEditorProps>((props, ref) => {
  const { className, editorContentClassName } = props;
  const [body, setBody] = useState<HTMLElement | null>(null);
  const { editor } = useBlockEditor(props);

  const providerValue = useMemo(() => {
    return {};
  }, []);

  const onUpdate = useCallback(
    ({ editor: updatedEditor }) => {
      const html = (updatedEditor as Editor).getHTML();
      props.onChange(html);
    },
    [props],
  );

  useLayoutEffect(() => {
    editor?.on('update', onUpdate);

    return () => {
      editor?.off('update', onUpdate);
    };
  }, [editor, onUpdate]);

  useLayoutEffect(() => {
    setBody(document.body);
  }, []);

  useImperativeHandle(ref, () => ({
    focus: () => {
      editor?.commands.focus();
    },
    getContent: () => {
      return editor?.getHTML() ?? '';
    },
    setContent: (content: string, emitUpdate?: boolean) => {
      editor?.commands.setContent(content, emitUpdate);
    },
    insertHtml: (html) => {
      editor?.commands.insertContent(html, { updateSelection: true });
    },
    clearContent: () => {
      editor?.commands.clearContent();
    },
    isEmpty: () => {
      return editor?.isEmpty || false;
    },
  }));

  if (!editor || !body) {
    return null;
  }

  return (
    <EditorContext.Provider value={providerValue}>
      <div className={clsx('border-primary flex rounded-md border', className)}>
        <div className="relative flex h-full flex-1 flex-col overflow-hidden">
          <EditorContent
            className={clsx('flex-1 overflow-y-auto', editorContentClassName)}
            editor={editor}
          />
          <LinkMenu appendTo={body} editor={editor} extensions={props.extensions} />
          <TextMenu editor={editor} />
          <ColumnsMenu appendTo={body} editor={editor} />
          <TableCornerMenu appendTo={body} editor={editor} />
          <TableRowMenu appendTo={body} editor={editor} />
          <TableColumnMenu appendTo={body} editor={editor} />
          <ImageBlockMenu appendTo={body} editor={editor} />
        </div>
      </div>
    </EditorContext.Provider>
  );
});

BlockEditor.displayName = 'BlockEditor';

export default BlockEditor;
