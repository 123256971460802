import { cva } from 'class-variance-authority';
import type { LinkButtonProps } from './types';

export const linkButtonVariants = cva(
  'h-fit min-h-0 w-fit min-w-0 p-0 rounded-none disabled:!text-fixme',
  {
    variants: {
      color: {
        primary: 'text-fixme hover:text-fixme',
        secondary: 'text-fixme hover:text-fixme',
        tertiary: 'text-fixme hover:text-fixme',
        error: 'text-fixme hover:text-fixme',
      },
    },
  },
);

export const getIconSize = (size: LinkButtonProps['size']): string => {
  return size === 'sm' ? '1rem' : '1.25rem';
};
