import { useCallback } from 'react';
import _get from 'lodash/get';
import { isUndefined } from 'lodash';

const useAccessors = (uiSchema: {
  'ui:options': {
    'ua:response-accessor'?: {
      id?: string;
      name?: string;
      iconUrl?: string;
      description?: string;
    };
  };
}) => {
  const responseAccessor = uiSchema['ui:options']['ua:response-accessor'];

  const idAccessor = responseAccessor?.id ?? 'id';
  const nameAccessor = responseAccessor?.name ?? 'name';
  const iconAccessor = responseAccessor?.iconUrl ?? 'iconUrl';
  const descriptionAccessor = responseAccessor?.description;

  const getOptionKey = useCallback(
    (it?: Record<string, unknown> | null) => {
      return _get(it, idAccessor) as string;
    },
    [idAccessor],
  );

  const getOptionLabel = useCallback(
    (it?: Record<string, unknown> | null) => _get(it, nameAccessor) as string,
    [nameAccessor],
  );

  const getOptionIcon = useCallback(
    (it?: Record<string, unknown> | null) => {
      return _get(it, iconAccessor) as string;
    },
    [iconAccessor],
  );

  const getOptionDescription = useCallback(
    (it?: Record<string, unknown> | null) => {
      if (isUndefined(descriptionAccessor)) {
        return undefined;
      }

      return _get(it, descriptionAccessor) as string | undefined;
    },
    [descriptionAccessor],
  );

  return {
    idAccessor,
    nameAccessor,
    iconAccessor,
    descriptionAccessor,
    getOptionKey,
    getOptionLabel,
    getOptionIcon,
    getOptionDescription,
  };
};

export default useAccessors;
