import type { ChangeEvent } from 'react';
import { useCallback } from 'react';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Spinner } from '../../../components/ui/Spinner';
import { cn } from '../../../lib/utils';
import { useDropZone, useFileUpload, useUploader } from './hooks';

export function ImageUploader({ onUpload }: { onUpload: (url: string) => void }) {
  const { loading, uploadFile } = useUploader({ onUpload });
  const { handleUploadClick, ref } = useFileUpload();
  const { draggedInside, onDrop, onDragEnter, onDragLeave } = useDropZone({ uploader: uploadFile });

  const onFileChange = useCallback(
    // @ts-ignore
    (e: ChangeEvent<HTMLInputElement>) => (e.target.files ? uploadFile(e.target.files[0]) : null),
    [uploadFile],
  );

  if (loading) {
    return (
      <div className="flex min-h-[10rem] items-center justify-center rounded-lg bg-opacity-80 p-8">
        <Spinner className="text-neutral-500" size={1.5} />
      </div>
    );
  }

  const wrapperClass = cn(
    'flex flex-col items-center justify-center px-8 py-10 rounded-lg bg-opacity-80',
    draggedInside && 'bg-neutral-100',
  );

  return (
    <div
      className={wrapperClass}
      contentEditable={false}
      onDragLeave={onDragLeave}
      onDragOver={onDragEnter}
      onDrop={onDrop}
    >
      <Icon className="mb-4 h-12 w-12 text-black opacity-20 dark:text-white" name="Image" />
      <div className="flex flex-col items-center justify-center gap-2">
        <div className="text-center text-sm font-medium text-neutral-400 dark:text-neutral-500">
          {draggedInside ? 'Drop image here' : 'Drag and drop or'}
        </div>
        <div>
          <Button
            buttonSize="small"
            disabled={draggedInside}
            onClick={handleUploadClick}
            variant="primary"
          >
            <Icon name="Upload" />
            Upload an image
          </Button>
        </div>
      </div>
      <input
        accept=".jpg,.jpeg,.png,.webp,.gif"
        className="h-0 w-0 overflow-hidden opacity-0"
        onChange={onFileChange}
        ref={ref}
        type="file"
      />
    </div>
  );
}

export default ImageUploader;
