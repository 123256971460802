import React, { useMemo } from 'react';
import { getUiOptions } from '@rjsf/utils';
import { getIconFromRegistry } from '@unifyapps/icons/utils/registry';
import { Link } from '@unifyapps/ui/components/Link';
import type { UiSchema } from '@rjsf/utils/lib/types';

export type HelpLinkType = { link?: string; text: string; Icon?: string };
export type FieldHelpLinkTemplateProps = { uiSchema?: UiSchema };
function FieldHelpLinkTemplate(props: FieldHelpLinkTemplateProps) {
  const { uiSchema } = props;

  const uiOptions = getUiOptions(uiSchema);
  const helpLink = uiOptions['ua:helpLink'] as HelpLinkType | undefined;

  const isValidHelpLink = useMemo(() => !helpLink?.link?.includes('undefined'), [helpLink]);
  const Icon = getIconFromRegistry(helpLink?.Icon);

  if (!helpLink) {
    return null;
  }

  return (
    <Link
      className="font-medium"
      color="brand"
      disabled={!isValidHelpLink}
      endDecoratorComponent={Icon}
      href={helpLink.link}
      size="xs"
      target="_blank"
    >
      {helpLink.text}
    </Link>
  );
}

export default FieldHelpLinkTemplate;
