import { Divider } from '@unifyapps/ui/components/Divider';
import type { WidgetProps } from '@rjsf/utils';
import { clsx } from 'clsx';
import getUaOptions from '../../../utils/getUaOptions';

export type DividerTemplateComponentType = (props: WidgetProps) => React.ReactNode;

export default function DividerWidget(props: WidgetProps) {
  const uaOptions = getUaOptions<{ className?: string }>(props.uiSchema);
  const className = props.className;

  return <Divider className={clsx(uaOptions.className, className)} orientation="horizontal" />;
}
