import React from 'react';
import type { UnsupportedFieldProps } from '@rjsf/utils';
import { Box } from '@unifyapps/ui/components/Box';

function UnsupportedFieldTemplate(props: UnsupportedFieldProps) {
  const { schema, reason } = props;

  return (
    <Box>
      <Box>Unsupported field schema, reason = {reason}</Box>
      <Box as="pre">{JSON.stringify(schema, null, 2)}</Box>
    </Box>
  );
}

export default UnsupportedFieldTemplate;
