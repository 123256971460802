import type { FormContextType, RJSFSchema, StrictRJSFSchema, WidgetProps } from '@rjsf/utils';
import { getTemplate } from '@rjsf/utils';
import type { TextWidgetTemplateComponentType } from './templates/TextWidgetTemplate';
import DefaultTextWidgetTemplate from './templates/TextWidgetTemplate';

/** The `TextWidget` component uses the `BaseTextWidgetTemplate`
 *
 * @param props - The `WidgetProps` for this component
 */

export default function TextWidget<
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = object,
>(props: WidgetProps<T, S, F>) {
  const { options, registry } = props;
  const BaseTextWidgetTemplate =
    // @ts-expect-error -- BaseTextWidgetTemplate not in template type
    ((getTemplate('TextWidgetTemplate', registry, options) as
      | TextWidgetTemplateComponentType
      | undefined) ?? DefaultTextWidgetTemplate) as (props: WidgetProps<T, S, F>) => JSX.Element;

  return <BaseTextWidgetTemplate {...props} />;
}
