import type { Editor } from '@tiptap/react';
import SvgStrikethrough01 from '@unifyapps/icons/outline/Strikethrough01';
import { Select } from '@unifyapps/ui/components/Select';
import SvgBold from '@unifyapps/icons/solid/wysiwyg/Bold';
import SvgItalic from '@unifyapps/icons/solid/wysiwyg/Italic';
import SvgBulletList from '@unifyapps/icons/solid/wysiwyg/BulletList';
import SvgNumberedList from '@unifyapps/icons/solid/wysiwyg/NumberedList';
import type { RichEditorProps } from '../../editors/RichTextEditor/RichTextEditor';
import { Toolbar } from '../Toolbar';
import { useTextMenuStates } from './hooks/useTextMenuStates';
import useTextMenuCommands from './hooks/useTextMenuCommands';

function EditorFooter({
  editor,
  font,
  className,
}: {
  editor: Editor;
  font: RichEditorProps['font'];
  className?: string;
}) {
  const textStates = useTextMenuStates(editor);
  const textCommands = useTextMenuCommands({ editor });

  return (
    <Toolbar.Wrapper className={className}>
      {font?.families ? (
        <Select<
          NonNullable<RichEditorProps['font']>['families'][number] | undefined,
          boolean,
          true,
          unknown
        >
          disableClearable
          disabled={!editor.isEditable}
          getOptionLabel={(option) => option?.label || ''}
          key={textStates.currentFont}
          onChange={textCommands.setFontFamily}
          options={font.families}
          value={textStates.currentFont ?? font.defaultFamily}
          valueAccessor={(option) => option?.value}
        />
      ) : null}
      {font?.sizes ? (
        <Select<
          NonNullable<RichEditorProps['font']>['sizes'][number] | undefined,
          boolean,
          true,
          unknown
        >
          disableClearable
          disabled={!editor.isEditable}
          getOptionLabel={(option) => option?.label || ''}
          key={textStates.currentSize}
          onChange={textCommands.setFontSize}
          options={font.sizes}
          value={textStates.currentSize ?? font.defaultSize}
          valueAccessor={(option) => option?.value}
        />
      ) : null}
      <Toolbar.Button
        Icon={SvgBold}
        isActive={textStates.isBold}
        isEditable={editor.isEditable}
        onClick={textCommands.toggleBold}
      />
      <Toolbar.Button
        Icon={SvgItalic}
        isActive={textStates.isItalic}
        isEditable={editor.isEditable}
        onClick={textCommands.toggleItalic}
      />
      <Toolbar.Button
        Icon={SvgStrikethrough01}
        isActive={textStates.isStrike}
        isEditable={editor.isEditable}
        onClick={textCommands.toggleStrike}
      />
      <Toolbar.Button
        Icon={SvgBulletList}
        isActive={textStates.isBulletList}
        isEditable={editor.isEditable}
        onClick={textCommands.toggleBulletList}
      />
      <Toolbar.Button
        Icon={SvgNumberedList}
        isActive={textStates.isOrderedList}
        isEditable={editor.isEditable}
        onClick={textCommands.toggleOrderedList}
      />
    </Toolbar.Wrapper>
  );
}

export default EditorFooter;
