import './styles.css';
import React, { forwardRef, memo, useImperativeHandle } from 'react';
import type { Extensions } from '@tiptap/react';
import { EditorContent, useEditor } from '@tiptap/react';
import { slowCn } from '@unifyapps/ui/lib/utils';
import { isNil, omitBy } from 'lodash';
import { clsx } from 'clsx';
import type { EditorRef } from '../../types';

export type EditorProps = {
  id?: string;
  className?: string;
  initialContent?: string;
  placeholder?: string;
  editable?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (event: FocusEvent) => void;
  onFocus?: (event: FocusEvent) => void;
  extensions: Extensions;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  size?: 'sm' | 'md';
  containerClassName?: string;
};

function sanitizeContent(context: string) {
  // we are doing this as tiptap doesn't parse plain text so \n is ignored, we have converted it to html element to add a line-break, so replacing with <br>
  return context.replaceAll('\n', '<br>');
}

const WHITESPACE_UNICODE_VALUE_REGEX = /\xA0/g;
function sanitizeOutputText(context: string) {
  return context ? context.replaceAll(WHITESPACE_UNICODE_VALUE_REGEX, ' ') : context;
}

export default memo(
  forwardRef<EditorRef, EditorProps>(function Editor(props, ref): React.JSX.Element | null {
    const { id, initialContent, className, extensions, editable, size, containerClassName } = props;

    const editor = useEditor(
      {
        immediatelyRender: false,
        content: sanitizeContent(initialContent ?? ''),
        editorProps: {
          attributes: omitBy(
            {
              id,
              class: slowCn('w-full', className),
              readOnly: !editable,
            },
            isNil,
          ) as Record<string, string>,
        },
        onUpdate: ({ editor: currentEditor }) => {
          if (props.onChange) {
            // overriding blockSeparator as default will add \n\n instead of \n
            // https://github.com/ueberdosis/tiptap/issues/3369
            props.onChange(sanitizeOutputText(currentEditor.getText({ blockSeparator: '\n' })));
          }
        },
        onBlur: ({ event }) => {
          props.onBlur?.(event);
        },
        onFocus: ({ event }) => {
          props.onFocus?.(event);
        },
        extensions,
        editable,
      },
      [extensions, props.onBlur, props.onFocus, className],
    );

    useImperativeHandle(ref, () => ({
      focus: () => {
        editor?.commands.focus();
      },
      getContent: () => {
        return sanitizeOutputText(editor?.getText({ blockSeparator: '\n' }) ?? '');
      },
      setContent: (content: string, emitUpdate?: boolean) => {
        editor?.commands.setContent(sanitizeContent(content), emitUpdate);
      },
      clearContent: () => {
        editor?.commands.clearContent();
      },
      insertHtml: (html) => {
        editor?.commands.insertContent(sanitizeContent(html));
      },
      isEmpty: () => {
        return editor?.isEmpty || false;
      },
    }));

    return editor ? (
      <EditorContent
        className={clsx('text-primary', containerClassName, size === 'sm' ? 'text-xs' : 'text-sm')}
        editor={editor}
        onKeyDown={props.onKeyDown}
      />
    ) : null;
  }),
);
