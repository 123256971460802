import type { TemplatesType } from '@rjsf/utils';
import type { ComponentType } from 'react';
import ArrayFieldDescriptionTemplate from './ArrayFieldDescriptionTemplate';
import ArrayFieldItemTemplate from './ArrayFieldItemTemplate';
import ArrayFieldTemplate from './ArrayFieldTemplate';
import ArrayFieldTitleTemplate from './ArrayFieldTitleTemplate';
import BaseInputTemplate from './BaseInputTemplate';
import ButtonTemplates from './ButtonTemplates';
import DescriptionFieldTemplate from './DescriptionFieldTemplate';
import ErrorListTemplate from './ErrorListTemplate';
import FieldErrorTemplate from './FieldErrorTemplate';
import FieldHelpTemplate from './FieldHelpTemplate';
import type { FieldHelpLinkTemplateProps } from './FieldHelpLinkTemplate';
import FieldHelpLinkTemplate from './FieldHelpLinkTemplate';
import FieldTemplate from './FieldTemplate';
import ObjectFieldTemplate from './ObjectFieldTemplate';
import TitleFieldTemplate from './TitleFieldTemplate';
import UnsupportedFieldTemplate from './UnsupportedFieldTemplate';
import WrapIfAdditionalTemplate from './WrapIfAdditionalTemplate';

const templates: TemplatesType & {
  FieldHelpLinkTemplate: ComponentType<FieldHelpLinkTemplateProps>;
} = {
  ArrayFieldTemplate,
  ArrayFieldDescriptionTemplate,
  ArrayFieldItemTemplate,
  ArrayFieldTitleTemplate,
  // eslint-disable-next-line -- need to check, only errors in carbon package
  // @ts-ignore -- need to check
  BaseInputTemplate,
  DescriptionFieldTemplate,
  ErrorListTemplate,
  FieldErrorTemplate,
  FieldHelpTemplate,
  FieldHelpLinkTemplate,
  FieldTemplate,
  ObjectFieldTemplate,
  TitleFieldTemplate,
  UnsupportedFieldTemplate,
  WrapIfAdditionalTemplate,
  // eslint-disable-next-line -- need to check, only errors in carbon package
  // @ts-ignore -- need to check
  ButtonTemplates,
};

export type DefaultTemplates = typeof templates;

export default templates;
